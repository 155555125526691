import { forwardRef } from 'react';
import { RiCheckLine } from 'react-icons/ri';

import clsx from 'clsx';

import { Container } from './styles';
import { CheckboxProps } from './types';

const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    {
      label,
      name,
      error,
      containerClassName,
      containerStyle,
      removeMarginTop,
      onClick,
      className,
      ...rest
    },
    ref
  ) => {
    return (
      <Container
        style={containerStyle}
        className={containerClassName}
        hasError={!!error}
        isReadOnly={rest.readOnly}
        isDisabled={rest.disabled}
        removeMarginTop={removeMarginTop}
      >
        <label htmlFor={name}>
          <input
            type="checkbox"
            name={name}
            id={name}
            ref={ref}
            className={clsx(className, rest.readOnly && 'is-read-only')}
            onClick={rest.readOnly ? e => e.preventDefault() : onClick}
            {...rest}
          />

          <span className="control">
            <RiCheckLine size="1rem" />
          </span>

          <span className="label">{label}</span>
        </label>

        {error && <span>{error}</span>}
      </Container>
    );
  }
);

export default Checkbox;
