import { ABOUT, HOME, NEWS } from 'routes/PATHS';

import SearchBar from '../SearchBar';
import { NavLink } from '../styles';
import { Container } from './styles';

export default function DesktopNavigation() {
  return (
    <Container>
      <ul className="main-nav">
        <li>
          <NavLink to={HOME} exact>
            Inicio
          </NavLink>
        </li>
        <li>
          <NavLink to={NEWS}>Noticias</NavLink>
        </li>
        <li>
          <NavLink to={ABOUT}>Sobre nós</NavLink>
        </li>
      </ul>

      <SearchBar />
    </Container>
  );
}
