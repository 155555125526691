import {
  TabPanel as ReactTabPanel,
  TabPanelProps as ReactTabPanelProps,
} from 'react-tabs';

import clsx from 'clsx';

type TabPanelProps = Omit<
  ReactTabPanelProps,
  'ref' | 'selectedClassName' | 'forceRender'
>;

export const TabPanel = ({ children, className, ...rest }: TabPanelProps) => (
  <ReactTabPanel className={clsx('react-tabs__tab-panel', className)} {...rest}>
    {children}
  </ReactTabPanel>
);

TabPanel.tabsRole = 'TabPanel';
