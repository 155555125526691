import { yupResolver } from '@hookform/resolvers/yup';

import { yup } from 'lib';

export const createPollValidator = yupResolver(
  yup.object().shape({
    question: yup.string().required().label('Questão'),
    alternatives: yup.array().of(
      yup.object({
        title: yup.string().required().label('Alternativa'),
      })
    ),
  })
);

const stringRequired = yup.string().required();
const arrayOfTitle = yup.array().of(
  yup.object({
    title: stringRequired.label('Alternativa'),
  })
);

export const updatePollValidator = yupResolver(
  yup.object().shape({
    question: stringRequired.label('Questão'),
    alternatives: yup.object().shape({
      added: arrayOfTitle,
      updated: arrayOfTitle,
    }),
  })
);
