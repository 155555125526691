import ReactModal from 'react-modal';

import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const StyledReactModal = styled(ReactModal)`
  position: absolute;
  top: 25%;
  left: 50%;
  width: min(100%, 37.5rem);
  max-height: calc(100vh - 4rem);
  transform: translate(-50%, -25%);
  overflow: auto;
  outline: none;

  background-color: ${color('white')};
  display: grid;
  grid-template-rows: auto auto 1fr;

  --side-padding: 1rem;

  .close-modal {
    position: absolute;
    top: 0.75rem;
    right: var(--side-padding);
    z-index: 1;
  }

  header {
    padding: 1.5rem var(--side-padding) 1rem;
    background-color: ${color('white')};

    .position {
      margin-bottom: 1rem;

      &__options {
        display: flex;
        flex-wrap: wrap;
        gap: 0.5rem;

        label {
          position: relative;
          width: 3rem;
          height: 3rem;
          cursor: pointer;
          display: grid;
          place-items: center;

          input {
            appearance: none;
            width: 100%;
            height: 100%;
            background: red;
            background-color: ${color('input-background')};
            border: 1px solid ${color('line')};
            cursor: pointer;
          }

          span {
            position: absolute;
            user-select: none;
          }

          input:checked {
            background-color: ${color('primary-default')};
            border-color: ${color('primary-default')};

            & ~ .position-number {
              color: ${color('white')};
            }
          }
        }
      }
    }

    .search-container {
      position: relative;

      input {
        padding-right: 3rem;
      }

      .spinner {
        position: absolute;
        bottom: 1rem;
        right: 0.5rem;
      }
    }
  }

  hr {
    border: none;
    height: 1px;
    background-color: ${color('line')};
  }

  .no-data {
    padding: 1.5rem var(--side-padding);
    background-color: ${color('white')};
  }

  .list-container {
    height: 100%;
    padding: 1.5rem var(--side-padding);
    overflow: auto;

    ul {
      li {
        list-style: none;

        & + li {
          margin-top: 0.5rem;
        }
      }
    }

    .load-more {
      margin-top: 1rem;
      display: grid;
      place-items: center;
    }
  }

  footer {
    padding: 0.5rem var(--side-padding);
    border-top: 1px solid ${color('line')};
    text-align: right;

    button + button {
      margin-left: 0.5rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    footer {
      padding: 1rem var(--side-padding);
      display: flex;

      button {
        flex: 1;
      }
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    --side-padding: 1.5rem;
  }
`;

export const NewsRadio = styled.div`
  position: relative;

  input {
    position: absolute;
    opacity: 0;

    &:focus ~ label {
      border-color: ${color('primary-default')};
    }

    &:checked ~ label {
      background-color: ${color('primary-lighter')};
      border-color: ${color('primary-light')};
    }

    &:not(:checked) ~ label:hover {
      background-color: ${color('primary-lightest')};
    }
  }

  label {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0.5rem;
    padding: 0.25rem;
    transition: background-color ${cvar('transition-normal')};
    cursor: pointer;
    border: 1px solid transparent;
  }

  img {
    width: 6rem;
    min-height: 4rem;
    height: 100%;
    object-fit: cover;
  }
`;
