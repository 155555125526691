import { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { RiAddLine } from 'react-icons/ri';

import {
  Button,
  DataTable,
  NewsPreview,
  PulseLoader,
  Tag,
  Text,
} from 'components';
import { NewsPreviewRef } from 'components/modal/NewsPreview/types';

import { formatDate } from 'lib';

import { useNewsAdm } from 'hooks/api';

import { ADMIN_NEWS_CREATE } from 'routes/PATHS';

import { Filters } from './Filters';
import { Container, DataTableBody, PreviewTriggerButton } from './styles';
import { ColorMap, Filter, OrderBy } from './types';

//* MAPPERS
const colorMap: ColorMap = {
  sketch: 'danger',
  hidden: 'warning',
  hiddenByPeriodical: 'warning',
  scheduled: 'secondary',
  published: 'success',
};

const statusMap = {
  sketch: 'Rascunho',
  hidden: 'Oculto',
  hiddenByPeriodical: 'Coluna oculta',
  scheduled: 'Agendado',
  published: 'Publicado',
};

//* COMPONENT
export default function ListNews() {
  const previewRef = useRef<NewsPreviewRef>(null);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [category, setCategory] = useState<Filter | null>(null);
  const [periodical, setPeriodical] = useState<Filter | null>(null);
  const [orderBy, setOrderBy] = useState<OrderBy | null>(null);
  const [search, setSearch] = useState('');

  const {
    data: news,
    isLoading,
    isSuccess,
  } = useNewsAdm({
    query: {
      page,
      limit,
      search,
      category: category?.slug,
      periodical: periodical?.slug,
      ...orderBy,
    },
    select: data => ({
      ...data,
      range: {
        start: data.page * data.limit - (data.limit - 1),
        end:
          data.page * data.limit >= data.total
            ? data.total
            : data.page * data.limit,
      },
    }),
  });

  const handleOpenPreview = (data: NonNullable<typeof news>['data'][0]) => {
    let publicationDate;
    if (data.publicationDate) publicationDate = new Date(data.publicationDate);

    previewRef.current?.open({
      id: data.id,
      title: data.title,
      description: data.description,
      author: data.author.name,
      slug: data.slug,
      isVisible: data.isVisible,
      status: data.status,
      contentHtml: data.contentHtml,
      createdAt: new Date(data.createdAt),
      updatedAt: new Date(data.updatedAt),
      publicationDate,
      categories: data.categories,
      mainImage: data.mainImage,
      gallery: data.gallery,
      isHighlight: !!data.highlights,
    });
  };

  return (
    <Container>
      <Helmet>
        <title>Notícias cadastradas</title>
      </Helmet>

      <div className="header">
        <Text as="h1" variant="heading-medium">
          Notícias cadastradas
        </Text>

        <Button to={ADMIN_NEWS_CREATE} icon={RiAddLine}>
          Nova notícia
        </Button>
      </div>

      {isLoading && (
        <div className="loading-container">
          <PulseLoader color="primary-default" isLoading={isLoading} />
        </div>
      )}

      {isSuccess && (
        <Filters
          onChangeCategory={selected => {
            setCategory(selected);
            setPage(1);
          }}
          onChangePeriodical={selected => {
            setPeriodical(selected);
            setPage(1);
          }}
          onChangeSearch={value => {
            setSearch(value);
            setPage(1);
          }}
        />
      )}

      {news?.data.length === 0 && (
        <div className="no-data-container">
          <Text variant="heading-small">
            Não há nenhuma notícia cadastrada
            {category || periodical ? ' com estes filtros' : ''}
          </Text>
        </div>
      )}

      {news && news.data.length > 0 && (
        <DataTable
          columns={[
            { name: 'Título', sortName: 'title' },
            { name: 'Categorias' },
            { name: 'Data', sortName: 'createdAt' },
            { name: 'Status' },
          ]}
          pageRange={news.range}
          page={page}
          total={news.total}
          totalPages={news.totalPages}
          limit={news.limit}
          sort={orderBy?.sort}
          order={orderBy?.order}
          onOrderChange={setOrderBy}
          onLimitChange={selected => {
            setLimit(selected);
            setPage(1);
          }}
          onPageChange={selected => {
            setPage(selected);
            window.scrollTo({
              top: 0,
              behavior: 'smooth',
            });
          }}
          tableProps={{
            className: 'data-table',
          }}
        >
          <DataTableBody>
            {news.data.map(item => (
              <tr key={item.id}>
                <td title={item.title} className="truncate">
                  <PreviewTriggerButton onClick={() => handleOpenPreview(item)}>
                    {item.title}
                  </PreviewTriggerButton>
                </td>
                <td className="categories">
                  {item.categories.map(newsCategory => (
                    <Tag key={newsCategory.id}>{newsCategory.name}</Tag>
                  ))}
                </td>
                <td data-header="Data: ">
                  {formatDate(new Date(item.createdAt), 'P')}
                </td>
                <td>
                  <Tag variant="outline" color={colorMap[item.status]}>
                    {statusMap[item.status]}
                  </Tag>
                </td>
              </tr>
            ))}
          </DataTableBody>
        </DataTable>
      )}

      <NewsPreview ref={previewRef} />
    </Container>
  );
}
