import { useCallback, useState } from 'react';

export default function useDisclosure(initialState = false) {
  const [isOpen, setIsOpen] = useState(initialState);

  const onOpen = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const onToggle = useCallback(() => {
    setIsOpen(state => !state);
  }, []);

  return { isOpen, onOpen, onClose, onToggle };
}
