import { forwardRef, useImperativeHandle } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { RiAddLine, RiSubtractLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { Button, Checkbox, ContentModal, InputText, Text } from 'components';

import { useDisclosure } from 'hooks';
import { useCreatePoll } from 'hooks/api';

import { createPollValidator } from 'shared/validators/poll';

import { Form } from './styles';
import { CreatePollModalRef, FormDefaultValues, PollFormData } from './types';

//* HELPERS
function getDefaultValues(): FormDefaultValues {
  return { alternatives: new Array(2).fill({ title: '' }) };
}

//* COMPONENT
export type { CreatePollModalRef };
export const CreatePollModal = forwardRef<CreatePollModalRef>((_, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // ------ Form configuration ------
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors },
  } = useForm<PollFormData>({
    mode: 'onBlur',
    defaultValues: getDefaultValues(),
    resolver: createPollValidator,
  });

  const { fields, remove, append } = useFieldArray({
    control,
    name: 'alternatives',
  });

  // ------ Mutations setup ------
  const createPoll = useCreatePoll({
    onSuccess: () => {
      toast.success('Enquete cadastrada com sucesso');
      reset(getDefaultValues());
      onClose();
    },
  });

  // ------ Component Ref configuration ------
  useImperativeHandle(ref, () => ({ open: onOpen }));

  // ------ Form submit action ------
  const onSubmit = handleSubmit(async data => {
    await createPoll.mutateAsync(data);
  });

  return (
    <ContentModal
      isOpen={isOpen}
      onRequestClose={onClose}
      showCancelButton
      variant="primary"
      size="medium"
      title="Cadastrar enquete"
      disabledCloseButtons={isSubmitting}
      shouldCloseOnOverlayClick={!isSubmitting}
      actionButton={{
        text: 'Salvar',
        onClick: onSubmit,
        isLoading: isSubmitting,
      }}
    >
      <Form onSubmit={onSubmit}>
        <InputText
          label="Questão"
          error={errors.question?.message}
          {...register('question')}
        />

        <Text as="h2" variant="body-normal" color="label">
          Alternativas
        </Text>

        {fields.map((field, index) => (
          <div className="alternative" key={field.id}>
            <InputText
              containerClassName="alternative__input"
              removeErrorSlotMargin
              placeholder={`Alternativa ${index + 1}`}
              defaultValue={field.title}
              error={errors.alternatives?.[index]?.title?.message}
              {...register(`alternatives.${index}.title`)}
            />
            {fields.length > 2 && (
              <Button
                color="danger"
                icon={RiSubtractLine}
                onClick={() => remove(index)}
              />
            )}
          </div>
        ))}

        <div className="secondary-actions">
          <Button
            className="secondary-actions__add-alternative"
            size="small"
            icon={RiAddLine}
            color="secondary"
            onClick={() => append({ title: '' }, { shouldFocus: false })}
          >
            Adicionar
          </Button>
        </div>

        <Checkbox label="Publicar agora" {...register('isActive')} />
      </Form>
    </ContentModal>
  );
});
