import styled, { css } from 'styled-components';

import { color, font } from 'utils/cvar';
import { cssValue } from 'utils/unitConverter';

import { StyledTextAttrsProps, StyledTextProps } from './types';

export const StyledText = styled.p.attrs<StyledTextAttrsProps>(props => ({
  isHeading: props.as?.match?.(/h[1-6]/),
  // eslint-disable-next-line prettier/prettier
})) <StyledTextProps>`
  ${p =>
    p.$variant &&
    css`
      font: ${font(p.$variant)};
    `};

  ${p =>
    p.$color &&
    css`
      color: ${color(p.$color)};
    `};

  ${p =>
    p.truncate &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `};

  white-space: ${p => p.whiteSpace};

  /*
   ? Check if we have alignment, margin or truncate

   ? If we have align or truncate then display must be block in order to work
   ? properly.

   ? If we have any margin and don't have align or truncate then display can be
   ? inline-block.

   ? If we don't have any margin, align or truncate then we can preserve default
   ? display.
   */
  //;

  ${({ m, mx, my, mt, mr, mb, ml, $align, truncate, isHeading }) => {
    let display = '';
    if (isHeading || $align || truncate) display = 'block';

    if (!m && !mx && !my && !mt && !mr && !mb && !ml)
      return css`
        display: ${display};
        text-align: ${$align};
      `;

    const marginTop = cssValue(mt || my || m || 0);
    const marginRight = cssValue(mr || mx || m || 0);
    const marginBottom = cssValue(mb || my || m || 0);
    const marginLeft = cssValue(ml || mx || m || 0);

    return css`
      display: ${display || 'inline-block'};
      text-align: ${$align};
      margin: ${marginTop} ${marginRight} ${marginBottom} ${marginLeft};
    `;
  }};
`;
