import styled from 'styled-components';

import { color } from 'utils/cvar';

import {
  sidebarBaseLinkStyle,
  sidebarLinkWithIconStyle,
} from '../SidebarLink/styles';

//* STYLES
export const Container = styled.div<{ isOpen: boolean }>`
  > button {
    ${sidebarLinkWithIconStyle}
    background: ${p => (p.isOpen ? color('primary-dark') : 'transparent')};
    width: 100%;
    border: none;
    svg:last-child {
      transform: ${p => (p.isOpen ? 'rotateX(180deg)' : 'initial')};
    }
    svg:last-of-type {
      margin-left: auto;
    }
    &:not(.active) svg:last-of-type {
      fill: ${color('background')};
    }
  }
  ul {
    background-color: ${color('primary-darker')};
    display: ${p => (p.isOpen ? 'block' : 'none')};
    overflow: hidden;
    li {
      position: relative;
    }
    a:first-of-type {
      ${sidebarBaseLinkStyle}
      span {
        margin-left: 2.75rem;
      }
    }
    a.secondary-link {
      position: absolute;
      top: 0;
      right: 0.5rem;
      width: 3rem;
      height: 3rem;
      display: grid;
      place-items: center;
      outline: 0;
      svg {
        color: ${color('placeholder')};
      }
      &:hover,
      &:focus {
        background-color: ${color('primary-dark')};
        svg {
          color: ${color('white')};
        }
      }
      &.active {
        svg {
          color: ${color('secondary-default')};
        }
      }
    }
  }
`;
