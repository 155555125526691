import { useCallback, useEffect } from 'react';
import { RiMenu3Line } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import { Button } from 'components';

import { LogoLightSVG } from 'assets';

import { useDisclosure, useMediaQuery } from 'hooks';

import { HOME } from 'routes/PATHS';
import { MQ_TABLET_LANDSCAPE_UP } from 'utils/mediaQuery';

import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';
import SearchBar from './SearchBar';
import { StyledHeader } from './styles';

//* SIMPLE FUNCTION TO IMPROVE CODE READABILITY (MY OPINION)
function lockBodyScroll({ unlock = false } = {}) {
  const action = unlock ? 'remove' : 'add';
  document.body.classList[action]('body-overflow-hidden');
}

//* COMPONENT
export default function Header() {
  const {
    isOpen: isMobileMenuOpen,
    onOpen: openMobileMenu,
    onClose: closeMobileMenu,
  } = useDisclosure();

  const {
    isOpen: isSearchBarOpen,
    onOpen: openSearchBar,
    onClose: closeSearchBar,
  } = useDisclosure();

  const isTabletLandscapeUp = useMediaQuery(MQ_TABLET_LANDSCAPE_UP);

  const onOpenMobileMenu = useCallback(() => {
    openMobileMenu();
    if (!isTabletLandscapeUp) lockBodyScroll();
  }, [isTabletLandscapeUp, openMobileMenu]);

  const onCloseMobileMenu = useCallback(() => {
    closeMobileMenu();
    if (!isTabletLandscapeUp) lockBodyScroll({ unlock: true });
  }, [isTabletLandscapeUp, closeMobileMenu]);

  useEffect(() => {
    if (isTabletLandscapeUp) {
      closeMobileMenu();
      lockBodyScroll({ unlock: true });
    }
  }, [isTabletLandscapeUp, closeMobileMenu]);

  return (
    <>
      <StyledHeader isSearchBarOpen={isSearchBarOpen}>
        <div className="content">
          <Link to={HOME} className="logo-link">
            <LogoLightSVG />
          </Link>

          {isTabletLandscapeUp ? (
            <DesktopNavigation />
          ) : (
            <div className="menu-trigger">
              <SearchBar
                isOpen={isSearchBarOpen}
                onOpen={openSearchBar}
                onClose={closeSearchBar}
              />

              <Button
                icon={RiMenu3Line}
                variant="ghost"
                color="light"
                className="mobile-menu__open-btn"
                onClick={onOpenMobileMenu}
              />
            </div>
          )}
        </div>
      </StyledHeader>

      {!isTabletLandscapeUp && (
        <MobileNavigation
          isOpen={isMobileMenuOpen}
          onCloseMenu={onCloseMobileMenu}
        />
      )}
    </>
  );
}
