import styled from 'styled-components';

import { color } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const LoadingContainer = styled.div`
  height: 80vh;
  display: grid;
  place-items: center;
  align-content: center;
  gap: 2rem;
`;

export const Container = styled.main`
  max-width: 72rem;
  margin: 0 auto 3rem;

  fieldset {
    border: 0;
  }

  .page-header {
    background-color: ${color('background')};
    padding: 0.5rem 0 1.5rem;

    position: sticky;
    top: 3rem;
    z-index: 1000;

    button {
      margin-top: 0.5rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    .page-header {
      top: 3rem;
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    .page-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0;
    }
  }

  ${mediaQuery.tabletLandscapeUp} {
    .page-header {
      top: 0;
    }
  }
`;

export const FieldsetGrid = styled.fieldset`
  ${mediaQuery.tabletLandscapeUp} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 1.5rem;

    textarea {
      height: 11rem;
    }

    .author-input-container {
      margin-top: 2rem;
    }
  }
`;
