import { useMutation, useQuery } from 'react-query';

import api from 'services/api';
import { queryClient } from 'services/queryClient';

import { buildQueryString } from 'utils/buildQueryString';
import { formatPaginationMetadata } from 'utils/formatPaginationMetadata';

import {
  CreateData,
  GetPollsQuery,
  GetPollsResponseData,
  PaginatedPollsData,
  Poll,
  UseStartPollProps,
  UseActivePollProps,
  UseCreatePollProps,
  UseFinishPollProps,
  UsePollsProps,
  UseVotePollProps,
  UseDeletePollProps,
  UpdateData,
  UseUpdatePollProps,
} from './types';

const POLLS_KEY = 'polls';
const POLLS_ACTIVE_KEY = 'polls_active';

//* ------ HELPER TO DISCOVER NON-SKETCH POLLS FROM CACHE ------
function isPollsExceptSketch(hash: string, ignoreActive = false) {
  const matchActive = ignoreActive ? `|(${POLLS_ACTIVE_KEY})` : '';
  const regexString = `^(?!.*(status":"sketch${matchActive})).*polls.*$`;

  const regex = new RegExp(regexString, 'g');
  return regex.test(hash);
}

// ********************************************************
// ***                     LIST ALL                     ***
// ********************************************************
const getPolls = async (query: GetPollsQuery = {}) => {
  const uri = buildQueryString(query, 'polls');
  const { data } = await api.get<GetPollsResponseData>(uri);

  return { ...data, ...formatPaginationMetadata(data) };
};

export function usePolls<T = PaginatedPollsData>({
  query: queryParams,
  ...options
}: UsePollsProps<T> = {}) {
  const query = { page: 1, ...queryParams };

  return useQuery([POLLS_KEY, query], () => getPolls(query), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 30, // ? 30 minutes,
    ...options,
  });
}

// ********************************************************
// ***                    GET ACTIVE                    ***
// ********************************************************
const getActivePoll = async () => {
  const { data } = await api.get<Poll | undefined>('polls/active');
  if (!data) return null;
  return data;
};

export function useActivePoll<T = Poll | null>(props?: UseActivePollProps<T>) {
  return useQuery(POLLS_ACTIVE_KEY, getActivePoll, {
    staleTime: 1000 * 60 * 30, // ? 30 minutes,
    ...props,
  });
}

// **********************************************************
// ***                       CREATE                       ***
// **********************************************************
const createPoll = async (data: CreateData) => {
  await api.post('polls', data);
};

export function useCreatePoll({
  onSuccess,
  ...options
}: UseCreatePollProps = {}) {
  return useMutation(createPoll, {
    onSuccess: (data, variables, context) => {
      if (variables.isActive) {
        // If is active we can invalidate the active poll and all polls list except the sketch list
        queryClient.invalidateQueries({
          predicate: ({ queryHash }) => isPollsExceptSketch(queryHash),
        });
      }
      // If is not active we can simply invalidate all polls list
      else queryClient.invalidateQueries(POLLS_KEY);

      onSuccess?.(data, variables, context);
    },
    ...options,
  });
}

// *********************************************************
// ***                      UPDATE                      ***
// *********************************************************
const updatePoll = async ({ id, ...data }: UpdateData) => {
  await api.patch(`polls/${id}`, data);
};

export function useUpdatePoll({
  onSuccess,
  ...options
}: UseUpdatePollProps = {}) {
  return useMutation(updatePoll, {
    onSuccess: (...params) => {
      queryClient.invalidateQueries(POLLS_KEY);
      onSuccess?.(...params);
    },
    ...options,
  });
}

// **********************************************************
// ***                       FINISH                       ***
// **********************************************************
const finishPoll = async (id: string) => {
  await api.patch(`polls/${id}/finish`);
};

export function useFinishPoll({
  onSuccess,
  ...options
}: UseFinishPollProps = {}) {
  return useMutation(finishPoll, {
    onSuccess: (...params) => {
      queryClient.setQueryData(POLLS_ACTIVE_KEY, null);
      queryClient.invalidateQueries({
        predicate: ({ queryHash }) => isPollsExceptSketch(queryHash, true),
      });
      onSuccess?.(...params);
    },
    ...options,
  });
}

// *********************************************************
// ***                       START                       ***
// *********************************************************
const startPoll = async (id: string) => {
  await api.patch(`polls/${id}/start`);
};

export function useStartPoll({
  onSuccess,
  ...options
}: UseStartPollProps = {}) {
  return useMutation(startPoll, {
    onSuccess: (...params) => {
      queryClient.invalidateQueries({
        predicate: ({ queryHash }) => /"polls(_active)?"/g.test(queryHash),
      });

      onSuccess?.(...params);
    },
    ...options,
  });
}

// ********************************************************
// ***                       VOTE                       ***
// ********************************************************
const votePoll = async (alternativeId: string) => {
  await api.patch(`polls/${alternativeId}/vote`);
};

export function useVotePoll(props?: UseVotePollProps) {
  return useMutation(votePoll, props);
}

// ********************************************************
// ***                      DELETE                      ***
// ********************************************************
const deletePoll = async (id: string) => {
  await api.delete(`polls/${id}`);
};

export function useDeletePoll({
  onSuccess,
  ...options
}: UseDeletePollProps = {}) {
  return useMutation(deletePoll, {
    onSuccess: (...params) => {
      queryClient.invalidateQueries(POLLS_KEY);
      onSuccess?.(...params);
    },
    ...options,
  });
}
