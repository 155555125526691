import {
  RiArrowDownLine,
  RiArrowDownSLine,
  RiArrowUpDownLine,
  RiArrowUpLine,
} from 'react-icons/ri';

import { Dropdown, Button, Pagination } from 'components';

import { useMediaQuery } from 'hooks';

import { MQ_PHONE_ONLY } from 'utils/mediaQuery';

import { FlexCenterAligned, Table, Footer } from './styles';
import { DataTableProps } from './types';

/**
 * Wrapper to handle the **UI behavior** of pagination, itens per page selection
 * and ordering.
 *
 * This component does **NOT** render the table body, so, you can render it as
 * needed, applying any classes and custom markup.
 *
 * Also, the styles in this component is only responsible to handle the
 * general UI style but not that which is related with the UI behavior
 */
export default function DataTable({
  pageRange,
  page,
  total,
  totalPages,
  limit,
  limitOptions = [5, 15, 30],
  sort,
  order,
  columns,
  children,
  tableContainerProps,
  tableProps,
  onLimitChange,
  onPageChange,
  onOrderChange,
}: DataTableProps) {
  const isPhoneOnly = useMediaQuery(MQ_PHONE_ONLY);

  const getOrderByIcon = (column: string) => {
    if (sort === column)
      if (order === 'ASC') return <RiArrowUpLine />;
      else return <RiArrowDownLine />;
    return <RiArrowUpDownLine />;
  };

  const handleChangeOrder = (column: string) => {
    if (!onOrderChange) return;

    if (!order) onOrderChange({ sort: column, order: 'ASC' });
    else if (order === 'ASC') onOrderChange({ sort: column, order: 'DESC' });
    else onOrderChange(null);
  };

  return (
    <>
      <FlexCenterAligned>
        <span className="page-range">
          {isPhoneOnly ? '' : 'Mostrando '}
          {pageRange.start} - {pageRange.end} de {total}
        </span>

        <div>
          <span className="limit-label">
            {isPhoneOnly ? 'P' : 'Resultados p'}or página:
          </span>
          <Dropdown
            placement="bottom-end"
            variant="condensed"
            triggerElement={
              <Button size="small" color="light" icon={RiArrowDownSLine}>
                {limit}
              </Button>
            }
          >
            {limitOptions.map(value => (
              <Dropdown.Item key={value} onClick={() => onLimitChange(value)}>
                {value}
              </Dropdown.Item>
            ))}
          </Dropdown>
        </div>
      </FlexCenterAligned>

      <br />

      <div {...tableContainerProps}>
        <Table {...tableProps}>
          <thead>
            <tr>
              {columns.map(column => (
                <th key={column.name}>
                  {column.sortName ? (
                    <button
                      type="button"
                      onClick={() =>
                        handleChangeOrder(column.sortName as string)
                      }
                    >
                      {column.name} {getOrderByIcon(column.sortName)}
                    </button>
                  ) : (
                    column.name
                  )}
                </th>
              ))}
            </tr>
          </thead>
          {children}
        </Table>
      </div>

      <br />

      <Footer>
        <span className="page-range">
          Mostrando {pageRange.start} - {pageRange.end} de {total}
        </span>

        <Pagination
          pageCount={totalPages}
          onPageChange={selectedItem => onPageChange(selectedItem.selected + 1)}
          forcePage={page - 1}
        />
      </Footer>
    </>
  );
}
