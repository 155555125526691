import { StyledTag } from './styles';
import { TagProps } from './types';

export default function Tag({
  children,
  color = 'primary',
  variant = 'inverse',
  ...rest
}: TagProps) {
  return (
    <StyledTag $color={color} variant={variant} {...rest}>
      {children}
    </StyledTag>
  );
}
