import { IconBaseProps } from 'react-icons';
import { NavLinkProps } from 'react-router-dom';

import { StyledNavLink } from './styles';

//* TYPES
type SidebarLinkProps = Omit<NavLinkProps, 'children'> & {
  to: string;
  icon: React.ComponentType<IconBaseProps>;
  label: string;
  notImplemented?: boolean;
};

//* COMPONENT
export default function SidebarLink({
  to,
  icon: Icon,
  label,
  style,
  notImplemented,
  ...rest
}: SidebarLinkProps) {
  return (
    <StyledNavLink
      to={to}
      title={label}
      style={notImplemented ? { opacity: 0.4, ...style } : style}
      {...rest}
    >
      <Icon size="1.5rem" />
      <span>{label}</span>
    </StyledNavLink>
  );
}
