import { useInfiniteQuery, useMutation, useQuery } from 'react-query';

import api from 'services/api';
import { queryClient } from 'services/queryClient';

import { buildQueryString } from 'utils/buildQueryString';
import { formatPaginationMetadata } from 'utils/formatPaginationMetadata';

import {
  CreateData,
  GetNewsAdmProps,
  UpdateData,
  UseNewsAdmProps,
  UseCreateNewsProps,
  UseSoftDeleteNewsProps,
  UseUpdateNewsProps,
  CreateNewsResponseData,
  UpdateNewsResponseData,
  News,
  GetNewsResponseData,
  PaginatedNewsData,
  UseNewsByIdOrSlugProps,
  UseToggleNewsVisibilityProps,
  GetNewsPublicProps,
  UseNewsProps,
  UseNewsInfinityProps,
} from './types';

const QUERY_KEY = 'news';
const INFINITY_QUERY_KEY = 'news_infinite';
const ADM_QUERY_KEY = 'news_adm';

// ********************************************************
// ***              LIST ALL NEWS - PUBLIC              ***
// ********************************************************
const getNews = async ({ page = 1, ...filters }: GetNewsPublicProps) => {
  const uri = buildQueryString({ page, ...filters }, 'news');

  const response = await api.get<GetNewsResponseData>(uri);
  const { data, ...metadata } = response.data;

  return { data, ...formatPaginationMetadata(metadata) };
};

export function useNews<T = PaginatedNewsData>({
  query = {},
  ...options
}: UseNewsProps<T> = {}) {
  return useQuery([QUERY_KEY, query], () => getNews(query), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}

// *********************************************************
// ***         LIST ALL NEWS INFINITELY - PUBLIC         ***
// *********************************************************
export function useInfiniteNews({
  query = {},
  ...options
}: UseNewsInfinityProps = {}) {
  return useInfiniteQuery(
    [INFINITY_QUERY_KEY, query],
    ({ pageParam = 1 }) => getNews({ ...query, page: pageParam }),
    {
      getNextPageParam: lastPage =>
        lastPage.page < lastPage.totalPages ? lastPage.page + 1 : undefined,
      refetchOnWindowFocus: false,
      ...options,
    }
  );
}

// *******************************************************
// ***              LIST ALL NEWS - ADMIN              ***
// *******************************************************
const getNewsAdm = async ({ page = 1, ...filters }: GetNewsAdmProps) => {
  const uri = buildQueryString({ page, ...filters }, 'news/adm');

  const response = await api.get<GetNewsResponseData>(uri);
  const { data, ...metadata } = response.data;

  return { data, ...formatPaginationMetadata(metadata) };
};

export function useNewsAdm<T = PaginatedNewsData>({
  query = {},
  ...options
}: UseNewsAdmProps<T> = {}) {
  return useQuery([ADM_QUERY_KEY, query], () => getNewsAdm(query), {
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...options,
  });
}

// ********************************************************
// ***              GET NEWS BY ID OR SLUG              ***
// ********************************************************
const getNewsByIdOrSlug = async (slug: string) => {
  const { data } = await api.get<News>(`news/${slug}`);
  return data;
};

export function useNewsByIdOrSlug<T = News>(
  idOrSlug: string,
  props?: UseNewsByIdOrSlugProps<T>
) {
  return useQuery([QUERY_KEY, idOrSlug], () => getNewsByIdOrSlug(idOrSlug), {
    refetchOnWindowFocus: false,
    ...props,
  });
}

// ********************************************************
// ***                      CREATE                      ***
// ********************************************************
const createNews = async (data: CreateData) => {
  const formData = new FormData();

  Object.entries(data).forEach(([field, value]) => {
    if (value != null && value !== '') {
      if (Array.isArray(value))
        value.forEach(item => formData.append(`${field}[]`, item));
      else if (value instanceof File) formData.append(field, value);
      else if (value instanceof Date)
        formData.append(field, value.toISOString());
      else formData.append(field, value.toString());
    }
  });

  const response = await api.post<CreateNewsResponseData>('news', formData);
  return response.data;
};

export function useCreateNews(props?: UseCreateNewsProps) {
  return useMutation(createNews, props);
}

// ********************************************************
// ***                      UPDATE                      ***
// ********************************************************
const updateNews = async ({ id, ...data }: UpdateData) => {
  const resource = `news/${id}`;
  const response = await api.put<UpdateNewsResponseData>(resource, data);
  return response.data;
};

export function useUpdateNews({
  onSuccess,
  ...options
}: UseUpdateNewsProps = {}) {
  return useMutation(updateNews, {
    onSuccess: (data, variables, context) => {
      const oldData = queryClient.getQueryData<News>([QUERY_KEY, variables.id]);

      if (oldData) {
        queryClient.setQueryData<News>([QUERY_KEY, variables.id], {
          ...oldData,
          ...data,
        });
      }

      onSuccess?.(data, variables, context);
    },
    ...options,
  });
}

// *******************************************************
// ***                   SOFT DELETE                   ***
// *******************************************************
const softDeleteNews = async (id: string) => {
  await api.patch(`news/${id}/soft_delete`);
};

export function useSoftDeleteNews({
  onSuccess,
  ...options
}: UseSoftDeleteNewsProps = {}) {
  return useMutation(softDeleteNews, {
    onSuccess: (data, id, context) => {
      queryClient.removeQueries([QUERY_KEY, id]);
      queryClient.invalidateQueries({
        predicate: query => {
          // Invalidate any list of news which contains filters
          const regex = new RegExp(`(${ADM_QUERY_KEY}|${QUERY_KEY})",{.+`, 'g');
          return !!query.queryHash.match(regex);
        },
      });
      onSuccess?.(data, id, context);
    },
    ...options,
  });
}

// *******************************************************
// ***                TOGGLE VISIBILITY                ***
// *******************************************************
const toggleNewsVisibility = async (id: string) => {
  await api.patch(`news/${id}/visibility`);
};

export function useToggleNewsVisibility({
  onSuccess,
  ...options
}: UseToggleNewsVisibilityProps = {}) {
  return useMutation(toggleNewsVisibility, {
    onSuccess: (data, id, context) => {
      queryClient.removeQueries([QUERY_KEY, id]);
      queryClient.invalidateQueries(ADM_QUERY_KEY);
      onSuccess?.(data, id, context);
    },
    ...options,
  });
}
