import { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import {
  RiAddLine,
  RiDeleteBinLine,
  RiEdit2Line,
  RiEyeLine,
  RiEyeOffLine,
} from 'react-icons/ri';
import { toast } from 'react-toastify';

import { AlertModal, Button, PulseLoader, Tag, Text } from 'components';

import { useToggle } from 'hooks';
import {
  usePeriodicals,
  useSoftDeletePeriodical,
  useTogglePeriodicalVisibility,
} from 'hooks/api';

import {
  CreatePeriodicalModal,
  CreatePeriodicalModalRef as CreateModalRef,
} from './CreatePeriodicalModal';
import { Container } from './styles';

//* HELPERS
function notifyUnknownError() {
  toast.error('Um erro inesperado ocorreu. Por favor, tente novamente');
}

//* REACT COMPONENT
export default function ListPeriodicals() {
  const createPeriodicalModalRef = useRef<CreateModalRef | null>(null);

  const [selectedPeriodical, setSelectedPeriodical] = useState(-1);

  const [isDeleteModalOpen, toggleDeleteModal] = useToggle();
  const [isVisibilityModalOpen, toggleVisibilityModal] = useToggle();

  function onOpenActionModal(type: 'delete' | 'visibility', index: number) {
    setSelectedPeriodical(index);
    if (type === 'delete') toggleDeleteModal();
    else toggleVisibilityModal();
  }

  // ? Queries
  const { data: periodicals, isLoading } = usePeriodicals({
    query: {
      visibility: 'all',
      withNewsCount: true,
      sort: 'created_at',
      order: 'DESC',
    },
  });

  // ? Periodical Soft Delete Mutation
  const softDeleteMutation = useSoftDeletePeriodical({
    onError: notifyUnknownError,
    onSuccess: () => {
      toast.success('Coluna excluída com sucesso');
      toggleDeleteModal();
    },
  });

  // ? Periodical Visibility Mutation
  const toggleVisibilityMutation = useTogglePeriodicalVisibility({
    onError: error => {
      if (!error.response?.data) notifyUnknownError();
    },
    onSuccess: () => {
      toast.success('Visibilidade da coluna atualizada com sucesso');
      toggleVisibilityModal();
    },
  });

  return (
    <Container>
      <Helmet>
        <title>Colunas cadastradas</title>
      </Helmet>

      <div className="header">
        <Text as="h1" variant="heading-medium">
          Colunas cadastradas
        </Text>

        <Button
          icon={RiAddLine}
          onClick={() => createPeriodicalModalRef.current?.open()}
        >
          Nova coluna
        </Button>
      </div>

      {isLoading && (
        <div className="loading-container">
          <PulseLoader color="primary-default" isLoading={isLoading} />
        </div>
      )}

      {periodicals?.length === 0 && (
        <div className="no-data-container">
          <Text variant="heading-small">Não há nenhuma coluna cadastrada</Text>
        </div>
      )}

      {/* Load data well succeeded and has at least one category registered */}
      {periodicals && periodicals?.length > 0 && (
        <>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th scope="col" className="actions">
                    Ações
                  </th>
                  <th scope="col">Nome</th>
                  <th scope="col">Notícias</th>
                  <th scope="col">Visibilidade</th>
                </tr>
              </thead>
              <tbody>
                {periodicals.map((periodical, index) => (
                  <tr key={periodical.id}>
                    <td className="actions">
                      <Button
                        icon={RiEdit2Line}
                        variant="ghost"
                        size="small"
                        title="Editar"
                        onClick={() =>
                          createPeriodicalModalRef.current?.open({
                            id: periodical.id,
                            name: periodical.name,
                          })
                        }
                      />
                      <Button
                        icon={RiDeleteBinLine}
                        color="danger"
                        variant="ghost"
                        size="small"
                        title="Excluir"
                        onClick={() => onOpenActionModal('delete', index)}
                      />
                      <Button
                        icon={periodical.isVisible ? RiEyeLine : RiEyeOffLine}
                        color="neutral"
                        variant="ghost"
                        size="small"
                        title="Alterar visibilidade"
                        onClick={() => onOpenActionModal('visibility', index)}
                      />
                    </td>
                    <td title={periodical.name}>{periodical.name}</td>
                    <td>{periodical.news}</td>
                    <td>
                      <Tag
                        variant="outline"
                        color={periodical.isVisible ? 'success' : 'warning'}
                      >
                        {periodical.isVisible ? 'Visível' : 'Oculto'}
                      </Tag>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Modal to soft delete a periodical */}
          <AlertModal
            isOpen={isDeleteModalOpen}
            onRequestClose={toggleDeleteModal}
            variant="danger"
            title="Excluir coluna"
            disabledCloseButtons={softDeleteMutation.isLoading}
            shouldCloseOnOverlayClick={!softDeleteMutation.isLoading}
            showCancelButton
            actionButton={{
              text: 'Excluir',
              onClick: () =>
                softDeleteMutation.mutate(periodicals[selectedPeriodical]?.id),
              isLoading: softDeleteMutation.isLoading,
            }}
          >
            <Text>
              Deseja excluir a coluna intitulada{' '}
              <strong>{periodicals[selectedPeriodical]?.name}</strong>?
            </Text>

            <Text as="small" variant="body-small" color="label" mt="1rem">
              Ao excluir uma coluna, todas as notícias associadas a esta serão
              desassociadas.
            </Text>
          </AlertModal>

          {/* Modal to change visibility of a periodical */}
          <AlertModal
            isOpen={isVisibilityModalOpen}
            onRequestClose={toggleVisibilityModal}
            variant="warning"
            title="Alterar visibilidade"
            disabledCloseButtons={toggleVisibilityMutation.isLoading}
            shouldCloseOnOverlayClick={!toggleVisibilityMutation.isLoading}
            showCancelButton
            actionButton={{
              text: 'Alterar',
              onClick: () =>
                toggleVisibilityMutation.mutate(
                  periodicals[selectedPeriodical]?.id
                ),
              isLoading: toggleVisibilityMutation.isLoading,
            }}
          >
            <Text>
              Deseja tornar a coluna intitulada{' '}
              <strong>{periodicals[selectedPeriodical]?.name}</strong>{' '}
              {periodicals[selectedPeriodical]?.isVisible
                ? 'oculta'
                : 'visível'}
              ?
            </Text>

            <Text as="small" variant="body-small" color="label" mt="1rem">
              Ao tornar uma coluna{' '}
              {periodicals[selectedPeriodical]?.isVisible
                ? 'oculta, todas as notícias associadas a esta se tornaram ocultas de igual modo.'
                : 'visível, todas a notícias associadas a esta se tornaram visíveis de igual modo.'}
            </Text>

            {toggleVisibilityMutation.error?.response?.data?.message && (
              <Text variant="body-small-heavy" color="danger-default" mt="1rem">
                {toggleVisibilityMutation.error?.response?.data?.message}
              </Text>
            )}
          </AlertModal>
        </>
      )}

      {/* Modal to create a new periodical */}
      <CreatePeriodicalModal ref={createPeriodicalModalRef} />
    </Container>
  );
}
