import { Helmet } from 'react-helmet';

import { LastNewsList, SideContentWithWidgets, Text } from 'components';

import { Container, Content } from './styles';

export default function About() {
  return (
    <Container>
      <Helmet>
        <title>Sobre nós</title>
      </Helmet>

      <Content>
        <Text as="h1" variant="display-medium-heavy" mb="2rem">
          Sobre nós
        </Text>

        <div>
          <Text as="h2" variant="heading-small" mb="0.75rem">
            O Jornal Local é uma publicação da Gustavo Abruzzini de Barros ME.
          </Text>

          <p>
            <strong>CNPJ:</strong> 01.924.270/0001-51
          </p>
          <p>
            <strong>Registro:</strong> Cartório 1º Ofício Livro B1 - nº 26
          </p>
        </div>

        <div>
          <h3>Editor jornalista responsável</h3>

          <p>Gustavo A. Barros (Reg. 16709/MTb)</p>
          <p>
            <strong>E-mail:</strong>{' '}
            <a href="mailto:editor@local.jor.br">editor@local.jor.br</a>
          </p>
        </div>

        <div>
          <h3>Reportagem</h3>

          <p>Paulo Henrique Nobre (Reg. 29547/MTb)</p>
          <p>
            <strong>E-mail:</strong>{' '}
            <a href="mailto:jornal@local.jor.br">jornal@local.jor.br</a>
          </p>
        </div>

        <div>
          <h3>Projeto gráfico</h3>

          <p>Camila Araujo Alves</p>
          <p>
            <strong>E-mail:</strong>{' '}
            <a href="mailto:camila@local.jor.br">camila@local.jor.br</a>
          </p>
        </div>

        <div>
          <h3>Contato comercial</h3>

          <p>Vicente Ramos Jr.</p>
          <p>
            <strong>E-mail:</strong>{' '}
            <a href="mailto:contato@local.jor.br">contato@local.jor.br</a>
          </p>
        </div>

        <div>
          <h3>Estagiários de jornalismo</h3>

          <p>Vaga em aberto</p>
          <p>Gostaria de trabalhar conosco? Entre em contato com o editor.</p>
        </div>

        <div>
          <h3>Redação</h3>

          <p>
            Rua Cel. Benjamin Guimarães, 95-B, Centro, Valença, RJ - CEP
            27600-000
          </p>
          <p>
            <strong>Telefone:</strong>{' '}
            <a href="tel:24992276230">(24) 98803-8297</a>
          </p>
          <p>
            <strong>Whatsapp:</strong>{' '}
            <a
              target="_blank"
              href="https://wa.me/5524992276230"
              rel="noopener noreferrer"
            >
              (24) 99227-6230
            </a>
          </p>
        </div>

        <div>
          <h3>Circulação e periodicidade</h3>

          <p>Valença e Região.</p>
          <p>
            Publicação semanal - toda quinta-feira nas bancas e pontos
            alternativos.
          </p>
        </div>

        <div className="notes" aria-label="Observações">
          <p>
            Os colaboradores participam de forma espontânea, sem nenhum vínculo
            empregatício com este veículo. Artigos e conceitos assinados não
            refletem necessariamente a opinião deste periódico, sendo de
            responsabilidade de seus autores.
          </p>
          <p>
            É proibida a reprodução, por outros órgãos de comunicação, de
            conteúdo aqui publicado, sem autorização da direção do Jornal Local,
            à exceção dos artigos assinados por colaboradores.
          </p>
          <p>
            O noticiário policial é extraído dos Boletins de Ocorrência das
            polícias Civil e Militar.
          </p>
          <p>
            O noticiário de fatos com atuação do Corpo de Bombeiros é extraído
            dos Boletins de Ocorrência desta instituição.
          </p>
        </div>
      </Content>

      <SideContentWithWidgets>
        <LastNewsList />
      </SideContentWithWidgets>
    </Container>
  );
}
