import {
  forwardRef,
  useState,
  useImperativeHandle,
  useCallback,
  useRef,
} from 'react';
import { RiCloseLine } from 'react-icons/ri';

import clsx from 'clsx';
import { isAfter, isFuture } from 'date-fns';
import { sanitize } from 'dompurify';

import { Button, Tag, Text, GalleryCarousel } from 'components';

import { formatDate } from 'lib';

import { useDisclosure } from 'hooks';

import { ADMIN_NEWS_UPDATE } from 'routes/PATHS';

import {
  ConfirmDeleteModal,
  ConfirmDeleteModalRef,
} from './ConfirmDeleteModal';
import {
  ConfirmVisibilityModal,
  ConfirmVisibilityModalRef,
} from './ConfirmVisibilityModal';
import {
  PreviewModal,
  Actions,
  AboutSection,
  MainImage,
  ContentSection,
  Gallery,
} from './styles';
import { NewsPreviewData, NewsPreviewProps, NewsPreviewRef } from './types';

PreviewModal.setAppElement('#root');

//* HELPER TO FORMAT THE UPDATED DATE
function getUpdatedAt(publishedAt?: Date, updatedAt?: Date) {
  return publishedAt && updatedAt && isAfter(updatedAt, publishedAt)
    ? ` - última atualização em ${formatDate(updatedAt)}`
    : '';
}

//* COMPONENT
const NewsPreview = forwardRef<NewsPreviewRef, NewsPreviewProps>(
  ({ isCreateMode }, ref) => {
    const confirmDeleteModalRef = useRef<ConfirmDeleteModalRef>(null);
    const confirmVisibilityModalRef = useRef<ConfirmVisibilityModalRef>(null);

    const { isOpen, onClose, onOpen } = useDisclosure();
    const [news, setNews] = useState<NewsPreviewData>();

    const open = useCallback(
      (data: NewsPreviewData) => {
        onOpen();
        setNews(data);
      },
      [onOpen]
    );

    useImperativeHandle(ref, () => ({ open }));

    // ****** RENDER CONTENT ******
    if (!news) return null;

    return (
      <PreviewModal isOpen={isOpen} onRequestClose={onClose}>
        <Button
          icon={RiCloseLine}
          color="neutral"
          size="small"
          variant="ghost"
          className="close-modal"
          onClick={onClose}
        />

        {!isCreateMode && (
          <Actions>
            <Button
              color="secondary"
              size="small"
              to={`${ADMIN_NEWS_UPDATE}/${news.id}`}
            >
              Editar
            </Button>

            <Button
              color="danger"
              size="small"
              onClick={() => confirmDeleteModalRef.current?.open()}
            >
              Excluir
            </Button>

            {news.status !== 'sketch' && (
              <Button
                color="tertiary"
                size="small"
                onClick={() => confirmVisibilityModalRef.current?.open()}
              >
                Tornar {news.isVisible ? 'oculto' : 'visível'}
              </Button>
            )}

            <Button
              icon={RiCloseLine}
              color="neutral"
              size="small"
              variant="ghost"
              className="close-modal"
              onClick={onClose}
            />
          </Actions>
        )}

        <main className={clsx(isCreateMode && 'create-mode')}>
          <AboutSection>
            <div className="categories">
              {news.categories.map(item => (
                <Tag key={item.id}>{item.name}</Tag>
              ))}
            </div>
            <Text
              as="h1"
              variant="display-medium-heavy"
              color="primary-default"
            >
              {news.title}
            </Text>

            <div className="author-date">
              <Text color="label">
                Por{' '}
                <Text as="strong" variant="body-normal-heavy">
                  {news.author}
                </Text>
              </Text>

              {news.publicationDate ? (
                <Text color="label">
                  Publicado em {formatDate(news.publicationDate)}
                  {getUpdatedAt(news.publicationDate, news.updatedAt)}{' '}
                  {isFuture(news.publicationDate) && (
                    <Text
                      as="strong"
                      color="secondary-default"
                      variant="body-normal-heavy"
                    >
                      (Agendado)
                    </Text>
                  )}
                </Text>
              ) : (
                <Text color="danger-default" variant="body-normal-heavy">
                  Esta notícia é um rascunho
                </Text>
              )}
            </div>
          </AboutSection>

          <MainImage>
            <div>
              <img
                src={news.mainImage.url}
                alt={news.mainImage.description}
                onLoad={event => {
                  event.currentTarget.parentElement?.classList.add('ratio-box');
                }}
              />
            </div>
            <Text
              as="figcaption"
              variant="body-small"
              color="label"
              mt="0.5rem"
            >
              {`${news.mainImage.description} - Foto: ${news.mainImage.author}`}
            </Text>
          </MainImage>

          <ContentSection
            dangerouslySetInnerHTML={{
              __html: sanitize(news.contentHtml, {
                ADD_TAGS: ['iframe'],
                ADD_ATTR: [
                  'allow',
                  'allowfullscreen',
                  'frameborder',
                  'scrolling',
                ],
              }),
            }}
          />

          {news.gallery.length > 0 && (
            <Gallery>
              <Text variant="heading-small" color="title-active" mb="1.5rem">
                Galeria de imagens
              </Text>

              <GalleryCarousel images={news.gallery} />
            </Gallery>
          )}
        </main>

        {!isCreateMode && news.id && typeof news.isVisible === 'boolean' && (
          <>
            <ConfirmDeleteModal
              ref={confirmDeleteModalRef}
              title={news.title}
              id={news.id}
              onConfirmDelete={onClose}
            />

            <ConfirmVisibilityModal
              ref={confirmVisibilityModalRef}
              id={news.id}
              title={news.title}
              isVisible={news.isVisible}
              isHighlight={news.isHighlight}
              onConfirm={() =>
                setNews({
                  ...news,
                  isVisible: !news.isVisible,
                })
              }
            />
          </>
        )}
      </PreviewModal>
    );
  }
);

export default NewsPreview;
