/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { MutableRefObject, RefObject, useEffect } from 'react';

import { useLatest } from '.';

/**
 ** Inspired by:
 ** https://usehooks.com/useEventListener/
 ** https://github.com/pocesar/react-use-event-listener
 */

//* TYPES
type EventName = keyof WindowEventMap;
type EventType<E extends EventName> = WindowEventMap[E];
type Target =
  | EventTarget
  | HTMLElement
  | Window
  | Document
  | typeof globalThis
  | RefObject<any>
  | MutableRefObject<any>;

//* HOOK
export default function useEventListener<
  E extends EventName,
  Handler extends (event: EventType<E>) => void
>(
  eventName: E,
  handler: Handler,
  target: Target = window,
  options?: AddEventListenerOptions | boolean
) {
  // Remember the latest handler and options
  const savedHandler = useLatest(handler);
  const savedOptions = useLatest(options);

  const isRef = 'current' in target;

  // Setup the event listener
  useEffect(() => {
    let element: EventTarget | null = null;

    if (isRef) element = (target as RefObject<any>).current;
    else if (target && 'addEventListener' in target) element = target;

    if (!element) return;

    const eventListener = (event: any) => savedHandler(event);

    element.addEventListener(eventName, eventListener, savedOptions);
    return () =>
      element!.removeEventListener(eventName, eventListener, savedOptions);
  }, [eventName, isRef, savedHandler, savedOptions, target]);
}
