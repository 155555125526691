import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  position: relative;

  > img {
    position: absolute;
    top: 2.25rem;
    left: 0.25rem;
    width: calc(100% - 0.5rem);
    height: calc(12rem - 0.5rem);
    object-fit: cover;
    pointer-events: none;
  }

  ${mediaQuery.tabletLandscapeUp} {
    > img {
      top: 3.25rem;
    }
  }
`;
