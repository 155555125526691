import { useEffect } from 'react';

type TwitterButtonProps = {
  text?: string;
  url?: string;
};

const SCRIPT_ID = 'twitter-share-script-id';

export default function TwitterButton({
  text,
  url = window.location.href,
}: TwitterButtonProps) {
  useEffect(() => {
    if (document.getElementById(SCRIPT_ID)) return;

    const script = document.createElement('script');
    script.id = SCRIPT_ID;
    script.async = true;
    script.src = 'https://platform.twitter.com/widgets.js';
    script.charset = 'utf-8';

    document.body.appendChild(script);

    // eslint-disable-next-line consistent-return
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <a
      href="https://twitter.com/share?ref_src=twsrc%5Etfw"
      className="twitter-share-button"
      data-size="large"
      data-text={text}
      data-url={url}
      data-show-count="true"
    >
      Tweet
    </a>
  );
}
