import {
  Control,
  Controller,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';

import { addDays } from 'date-fns';

import { ContentModal, DatePicker, Radio } from 'components';

import { UpdateNewsGeneralFormData } from '../types';

type StatusModalProps = {
  isOpen: boolean;
  isValid: boolean;
  isSubmitting: boolean;
  isDirty: boolean;
  control: Control<UpdateNewsGeneralFormData>;
  register: UseFormRegister<UpdateNewsGeneralFormData>;
  onRequestClose: () => void;
  onSubmit: (e?: React.BaseSyntheticEvent) => Promise<void>;
};

export function StatusModal({
  isOpen,
  isSubmitting,
  isValid,
  isDirty,
  control,
  register,
  onRequestClose,
  onSubmit,
}: StatusModalProps) {
  const status = useWatch({ control, name: 'status' });

  return (
    <ContentModal
      style={{
        overlay: { overflow: 'auto' },
        content: { overflow: 'visible' },
      }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      size="small"
      variant="primary"
      title="Status da notícia"
      showCancelButton
      shouldReturnFocusAfterClose={false}
      actionButton={{
        text: 'Salvar',
        onClick: () => {
          onSubmit();
          if (!isValid) onRequestClose();
        },
        disabled: !isDirty,
        isLoading: isSubmitting,
      }}
    >
      <Radio
        options={[
          { label: 'Rascunho', value: 'sketch' },
          { label: 'Publicar', value: 'publish' },
          { label: 'Agendar', value: 'schedule' },
        ]}
        {...register('status')}
      />

      {status === 'schedule' && (
        <Controller
          control={control}
          name="publicationDate"
          render={({ field: { value, ...rest }, formState: { errors } }) => (
            <DatePicker
              removeMarginTop
              label="Publicar em"
              dateFormat="Pp"
              placeholderText="dd/mm/aaaa hh:mm"
              showTimeSelect
              selected={value}
              error={errors.publicationDate?.message}
              minDate={addDays(new Date(), 1)}
              {...rest}
            />
          )}
        />
      )}
    </ContentModal>
  );
}
