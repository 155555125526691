import { ReactNode } from 'react';

import { Container } from './styles';

type AdminAuthLayoutProps = {
  children: ReactNode;
};

export default function AdminAuthLayout({ children }: AdminAuthLayoutProps) {
  return <Container>{children}</Container>;
}
