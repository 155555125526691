import { Helmet } from 'react-helmet';

import {
  Card,
  ContentLoading,
  GoogleAdSense,
  LastNewsList,
  SideContentWithWidgets,
} from 'components';
import { PollVoting } from 'components/common/PollVoting';

import { useNews, useNewsHighlights } from 'hooks/api';

import { NEWS } from 'routes/PATHS';
import { formatLastNewsDate } from 'utils/formatLastNewsDate';

import Carousel from './Carousel';
import { Container, NewsSection } from './styles';

export default function Home() {
  const { data: highlights, isLoading: isLoadingHighlights } =
    useNewsHighlights();

  const { data: news, isLoading: isLoadingNews } = useNews({
    query: { homePage: true },
    select: data => data.data,
  });

  if (isLoadingHighlights || isLoadingNews)
    return <ContentLoading label="Estamos carregando as notícias" />;

  return (
    <Container>
      <Helmet>
        <title>Inicio</title>
      </Helmet>

      {highlights && (
        <section className="container__carousel">
          <Carousel
            items={highlights.carousel.map(item => ({
              id: item.id,
              slug: item.slug,
              image: {
                url: item.mainImage.url,
                alt: item.mainImage.description,
              },
              title: item.title,
              description: item.description,
            }))}
          />
        </section>
      )}

      <NewsSection className="container__news">
        {news && news.length > 0 && (
          <div className="news__grid">
            <h2 className="section-title">Mais recentes</h2>

            {news?.slice(0, 6).map(item => (
              <Card
                key={item.id}
                variant="small"
                image={{
                  url: item.mainImage.url,
                  alt: item.mainImage.description,
                }}
                title={item.title}
                linkTo={`${NEWS}/${item.slug}`}
                bottomText={formatLastNewsDate(
                  item.publicationDate as string,
                  item.editedAt
                )}
              />
            ))}
          </div>
        )}

        <GoogleAdSense
          style={{ display: 'block', textAlign: 'center' }}
          slot="4828338627" // In-article middle
          layout="in-article"
          format="fluid"
        />

        {!!highlights?.mainNews.length && (
          <div className="news__highlight--single">
            <h2 className="section-title">Noticia destaque</h2>

            <Card
              key={highlights.mainNews[0].id}
              image={{
                url: highlights.mainNews[0].mainImage.url,
                alt: highlights.mainNews[0].mainImage.description,
              }}
              title={highlights.mainNews[0].title}
              description={highlights.mainNews[0].description}
              linkTo={`${NEWS}/${highlights.mainNews[0].slug}`}
              bottomText={formatLastNewsDate(
                highlights.mainNews[0].publicationDate,
                highlights.mainNews[0].editedAt
              )}
            />
          </div>
        )}

        {news && news.length > 6 && (
          <div className="news__text-cards">
            {news.slice(6, 8).map(item => (
              <Card
                key={item.id}
                title={item.title}
                linkTo={`${NEWS}/${item.slug}`}
                description={item.description}
                bottomText={formatLastNewsDate(
                  item.publicationDate as string,
                  item.editedAt
                )}
              />
            ))}
          </div>
        )}

        <GoogleAdSense
          style={{ display: 'block' }}
          slot="4340704720" // teste-in-feed
          format="fluid"
          layoutKey="-h2+d+5c-9-3e"
        />

        {news && news.length > 8 && (
          <div className="news__grid">
            {news.slice(8, 14).map(item => (
              <Card
                key={item.id}
                variant="small"
                image={{
                  url: item.mainImage.url,
                  alt: item.mainImage.description,
                }}
                title={item.title}
                linkTo={`${NEWS}/${item.slug}`}
                bottomText={formatLastNewsDate(
                  item.publicationDate as string,
                  item.editedAt
                )}
              />
            ))}
          </div>
        )}

        {highlights && highlights.news.length > 0 && (
          <div>
            <h2 className="section-title">Plantão de notícias</h2>

            <div className="news__highlight--multi">
              {highlights.news.map(item => (
                <Card
                  key={item.id}
                  image={{
                    url: item.mainImage.url,
                    alt: item.mainImage.description,
                  }}
                  title={item.title}
                  description={item.description}
                  linkTo={`${NEWS}/${item.slug}`}
                  bottomText={formatLastNewsDate(
                    item.publicationDate,
                    item.editedAt
                  )}
                />
              ))}
            </div>
          </div>
        )}

        <GoogleAdSense
          style={{ display: 'block', textAlign: 'center' }}
          slot="8634145284" // teste-in-article
          format="fluid"
          layout="in-article"
        />
      </NewsSection>

      <SideContentWithWidgets className="container__side-content">
        <LastNewsList />
        <PollVoting />
      </SideContentWithWidgets>
    </Container>
  );
}
