import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation, useParams } from 'react-router-dom';

import {
  Card,
  ContentLoading,
  Pagination,
  SideContentWithWidgets,
  Text,
} from 'components';

import { useQueryParams } from 'hooks';
import { useCategories, useNews } from 'hooks/api';

import { NEWS } from 'routes/PATHS';
import { formatLastNewsDate } from 'utils/formatLastNewsDate';

import FiltersComponent from './Filters';
import { Container, NewsSection } from './styles';
import { Filters, Params, QueryParams } from './types';

const loadingMessage = 'Estamos carregando as notícias';

export default function SearchNews() {
  const { search: queryParams } = useLocation();
  const { search } = useParams<Params>();
  const query = useQueryParams<QueryParams>(queryParams);

  const [filters, setFilters] = useState<Filters>({
    page: 1,
    search,
    category: query?.categoria,
  });

  // ****** Queries ******
  const { data: news, isLoading, isFetched } = useNews({ query: filters });
  const { isLoading: isLoadingCategories } = useCategories({
    query: { withNews: true },
  });

  if (isLoading || isLoadingCategories)
    return <ContentLoading label={loadingMessage} />;

  return (
    <Container>
      <Helmet>
        <title>Pesquisa - {search}</title>
      </Helmet>

      <NewsSection>
        <Text as="h1" variant="display-medium-heavy" mb="2rem">
          Pesquisa
        </Text>

        <FiltersComponent filters={filters} onChangeFilters={setFilters} />

        {!isFetched && <ContentLoading label={loadingMessage} />}

        {isFetched &&
          (news && news.total > 0 ? (
            <div className="list">
              {news?.data.map(item => (
                <Card
                  key={item.id}
                  linkTo={`${NEWS}/${item.slug}`}
                  image={{
                    url: item.mainImage.url,
                    alt: item.mainImage.description,
                  }}
                  title={item.title}
                  description={item.description}
                  tags={item.categories.map(category => ({
                    id: category.id,
                    title: category.name,
                  }))}
                  bottomText={formatLastNewsDate(
                    item.publicationDate as string,
                    item.editedAt
                  )}
                />
              ))}

              <Pagination
                pageCount={news.totalPages}
                onPageChange={({ selected }) => {
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                  setFilters(values => ({ ...values, page: selected + 1 }));
                }}
                forcePage={filters.page - 1}
              />
            </div>
          ) : (
            <Text variant="heading-small">
              Nenhuma notícia encontrada com estes filtros
            </Text>
          ))}
      </NewsSection>

      <SideContentWithWidgets />
    </Container>
  );
}
