import styled, { keyframes } from 'styled-components';

import { color } from 'utils/cvar';
import { cssValue } from 'utils/unitConverter';

import { PulseLoaderContainerProps } from './types';

const pulse = keyframes`
  0% {transform: scale(1); opacity: 1}
  45% {transform: scale(0.1); opacity: 0.6}
  80% {transform: scale(1); opacity: 1}
`;

export const PulseLoaderContainer = styled.span<PulseLoaderContainerProps>`
  display: inline-flex;

  span {
    &:nth-child(1) {
      --pulse-delay: 1;
    }

    &:nth-child(2) {
      --pulse-delay: 2;
    }

    &:nth-child(3) {
      --pulse-delay: 3;
    }

    background-color: ${p => color(p.$color)};
    width: ${p => cssValue(p.size)};
    height: ${p => cssValue(p.size)};
    margin: ${p => cssValue(p.margin)};
    border-radius: 100%;
    display: inline-block;
    animation: ${pulse} 750ms calc(var(--pulse-delay) * 0.12s) infinite
      cubic-bezier(0.2, 0.68, 0.18, 1.08);
    animation-fill-mode: both;
  }
`;
