import { useEffect, useState } from 'react';

import qs from 'qs';

type DeepPartial<T> = {
  [P in keyof T]?: DeepPartial<T[P]>;
};

export default function useQueryParams<
  T extends Record<string, unknown> = qs.ParsedQs
>(query: string) {
  const [queryParams, setQueryParams] = useState<DeepPartial<T>>();

  useEffect(() => {
    const parsedQuery = qs.parse(query, { ignoreQueryPrefix: true });
    setQueryParams(parsedQuery as DeepPartial<T>);
  }, [query]);

  return (
    queryParams ||
    (qs.parse(query, { ignoreQueryPrefix: true }) as DeepPartial<T>)
  );
}
