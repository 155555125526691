import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useQueryClient } from 'react-query';
import { useLocation } from 'react-router-dom';

import { PulseLoader, Radio, Text } from 'components';

import { useActivePoll, useVotePoll } from 'hooks/api';

import { POLLS } from 'routes/PATHS';

import Button from '../Button';
import { Container } from './styles';

type VotingFormData = { alternativeId: string };

const POLL_VOTED_KEY = 'poll_voted';

export function PollVoting() {
  const containerRef = useRef<HTMLDivElement>(null);
  const { pathname } = useLocation();
  const queryClient = useQueryClient();

  const [hasAlreadyAnswered, setHasAlreadyAnswered] = useState(() => {
    return sessionStorage.getItem(POLL_VOTED_KEY) === 'true';
  });

  const { data: poll, isLoading, isError, isSuccess } = useActivePoll();
  const votePoll = useVotePoll({
    onSuccess: () => queryClient.invalidateQueries('polls_active'),
  });

  const {
    register,
    handleSubmit,
    formState: { isDirty, isSubmitting },
  } = useForm<VotingFormData>();

  const onSubmit = handleSubmit(async ({ alternativeId }) => {
    await votePoll.mutateAsync(alternativeId, {
      onSuccess: () => {
        sessionStorage.setItem(POLL_VOTED_KEY, 'true');
        setHasAlreadyAnswered(true);

        if (!containerRef.current) return;
        const { offsetTop } = containerRef.current;
        window.scrollTo({ behavior: 'smooth', top: offsetTop - 128 });
      },
    });
  });

  if (hasAlreadyAnswered && pathname === '/enquetes') return null;

  return (
    <Container ref={containerRef}>
      <header>
        <h2>Enquete</h2>
      </header>

      {isLoading && (
        <div className="status-container">
          <PulseLoader isLoading />
        </div>
      )}

      {isError && (
        <div className="status-container">
          <Text variant="body-small">
            Um erro ocorreu. Não foi possível carregar a enquete
          </Text>
        </div>
      )}

      {isSuccess && (
        <main>
          {!poll && (
            <p className="notification">Nenhuma enquete ativa no momento.</p>
          )}

          {poll && hasAlreadyAnswered && (
            <p className="notification">Obrigado por responder a enquete</p>
          )}

          {poll && !hasAlreadyAnswered && (
            <form onSubmit={onSubmit}>
              <Radio
                isVertical
                removeMarginTop
                label={poll.question}
                options={poll.alternatives.map(({ id, title }) => ({
                  label: title,
                  value: id,
                }))}
                {...register('alternativeId')}
              />

              <Button
                type="submit"
                isBlock
                size="small"
                color="neutral"
                disabled={!isDirty}
                isLoading={isSubmitting}
              >
                Votar
              </Button>
            </form>
          )}

          {pathname === '/enquetes' || (
            <Button
              isBlock
              size="small"
              variant="ghost"
              disabled={isSubmitting}
              to={POLLS}
            >
              Resultados
            </Button>
          )}
        </main>
      )}
    </Container>
  );
}
