/* eslint-disable @typescript-eslint/no-explicit-any */
import { CSSProperties, useEffect } from 'react';

import { Container } from './styles';

type GoogleAdSenseProps = {
  slot: string;
  style?: CSSProperties;
  format?: string;
  fullWidthResponsive?: boolean;
  layout?: string;
  layoutKey?: string;
};

export default function GoogleAdSense({
  slot,
  format,
  fullWidthResponsive = false,
  layout,
  layoutKey,
  ...rest
}: GoogleAdSenseProps) {
  useEffect(() => {
    ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
  }, []);

  const options = {
    'data-ad-format': format,
    'data-full-width-responsive': `${fullWidthResponsive}`,
    'data-ad-layout': layout,
    'data-ad-layout-key': layoutKey,
  };

  return (
    <Container>
      <p>Publicidade</p>

      <ins
        className="adsbygoogle"
        data-ad-client="ca-pub-3581264865666441"
        data-ad-slot={slot}
        {...options}
        {...rest}
      />
    </Container>
  );
}
