import Tippy from '@tippyjs/react';
import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';

export const MenuContainer = styled(Tippy)`
  background-color: ${color('white')};
  border: 1px solid ${color('line')};
  box-shadow: ${cvar('elevation-large')};
  padding: 0.25rem 0;
`;
