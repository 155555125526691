import { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';

import {
  Card,
  ContentLoading,
  Pagination,
  SideContentWithWidgets,
  Text,
} from 'components';

import { useCategories, useNews, usePeriodicals } from 'hooks/api';

import { NEWS } from 'routes/PATHS';
import { formatLastNewsDate } from 'utils/formatLastNewsDate';

import { Container, NewsSection } from './styles';

//* Function to get the filter and the slug values inside the page pathname
const getFilterFromPath = (pathname: string) => {
  const match = /\/noticias\/?(categoria|coluna)\/(.+)/gi.exec(pathname);
  if (!match) return null;

  return {
    by: (match[1] === 'categoria' ? 'category' : 'periodical') as FilterBy,
    value: match[2],
  };
};

const loadingMessage = 'Estamos carregando as notícias';

type FilterBy = 'category' | 'periodical';
type Filter = { by: FilterBy; value: string };

export default function ListNews() {
  const { pathname } = useLocation();

  const [page, setPage] = useState(1);
  const [filter, setFilter] = useState<Filter | null>(() =>
    getFilterFromPath(pathname)
  );

  // ****** Fetch news ******
  const query = { page, ...(filter && { [filter.by]: filter.value }) };
  const { data: news, isLoading, isFetched } = useNews({ query });

  // ****** Get category we're filtering ******
  const { data: activeCategory } = useCategories({
    query: { withNews: true },
    select: data => data.filter(c => c.slug === filter?.value)[0],
  });

  // ****** Get periodical we're filtering ******
  const { data: activePeriodical } = usePeriodicals({
    query: { withNews: true },
    select: data => data.filter(p => p.slug === filter?.value)[0],
  });

  // ****** Update filters on change pathname ******
  useEffect(() => {
    setPage(1);
    setFilter(getFilterFromPath(pathname));
  }, [pathname]);

  const title = filter?.by === 'periodical' ? 'Colunas' : 'Notícias';

  if (isLoading) return <ContentLoading label={loadingMessage} />;

  return (
    <Container>
      <Helmet>
        <title>{title}</title>
      </Helmet>

      <NewsSection>
        <Text as="h1" variant="display-medium-heavy" mb="2rem">
          {!filter && 'Todas as notícias'}
          {filter &&
            (filter?.by === 'category'
              ? activeCategory?.name
              : `Coluna: ${activePeriodical?.name}`)}
        </Text>

        {!isFetched && <ContentLoading label={loadingMessage} />}

        {isFetched &&
          (news && news.total > 0 ? (
            <div className="list">
              {news?.data.map(item => (
                <Card
                  key={item.id}
                  linkTo={`${NEWS}/${item.slug}`}
                  image={{
                    url: item.mainImage.url,
                    alt: item.mainImage.description,
                  }}
                  title={item.title}
                  description={item.description}
                  tags={item.categories.map(category => ({
                    id: category.id,
                    title: category.name,
                  }))}
                  bottomText={formatLastNewsDate(
                    item.publicationDate as string,
                    item.editedAt
                  )}
                />
              ))}

              <Pagination
                pageCount={news.totalPages}
                onPageChange={({ selected }) => {
                  setPage(selected + 1);
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
                forcePage={page - 1}
              />
            </div>
          ) : (
            <Text variant="heading-small">Nenhuma notícia encontrada</Text>
          ))}
      </NewsSection>

      <SideContentWithWidgets />
    </Container>
  );
}
