import { useMutation } from 'react-query';

import api from 'services/api';

import {
  CreateNewsImageData,
  CreateNewsImageResponseData,
  UpdateNewsImageFileData,
  UpdateNewsImageInfoData,
  UseCreateNewsImageProps,
  UseDeleteNewsImageProps,
  UseUpdateNewsImageFileProps,
  UseUpdateNewsImageInfoProps,
} from './types';

// *********************************************************
// ***                 CREATE NEWS IMAGE                 ***
// *********************************************************
const createNewsImage = async ({ newsId, ...data }: CreateNewsImageData) => {
  const formData = new FormData();
  Object.entries(data).forEach(([key, value]) => formData.append(key, value));

  const response = await api.post<CreateNewsImageResponseData>(
    `news/${newsId}/images`,
    formData
  );

  return response.data;
};

export function useCreateNewsImage(props?: UseCreateNewsImageProps) {
  return useMutation(createNewsImage, props);
}

// ********************************************************
// ***             UPDATE NEWS IMAGE - INFO             ***
// ********************************************************
const updateNewsImageInfo = async (props: UpdateNewsImageInfoData) => {
  const { id, ...data } = props;
  await api.patch(`/news/images/${id}`, data);
};

export function useUpdateNewsImageInfo(props?: UseUpdateNewsImageInfoProps) {
  return useMutation(updateNewsImageInfo, props);
}

// ********************************************************
// ***             UPDATE NEWS IMAGE - FILE             ***
// ********************************************************
const updateNewsImageFile = async ({ id, image }: UpdateNewsImageFileData) => {
  const formData = new FormData();
  formData.append('image', image);

  await api.patch(`news/images/${id}/file`, formData);
};

export function useUpdateNewsImageFile(props?: UseUpdateNewsImageFileProps) {
  return useMutation(updateNewsImageFile, props);
}

// *********************************************************
// ***                 DELETE NEWS IMAGE                 ***
// *********************************************************
const deleteNewsImage = async (id: string) => {
  await api.delete(`news/images/${id}`);
};

export function useDeleteNewsImage(props?: UseDeleteNewsImageProps) {
  return useMutation(deleteNewsImage, props);
}
