import { useEffect } from 'react';
import { RiCloseLine } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';

import { Button } from 'components';

import { ABOUT, HOME, NEWS } from 'routes/PATHS';

import { NavLink } from '../styles';
import { Container, Backdrop } from './styles';

type MobileNavigationProps = {
  isOpen: boolean;
  onCloseMenu: () => void;
};

export default function MobileNavigation({
  isOpen,
  onCloseMenu,
}: MobileNavigationProps) {
  const { pathname } = useLocation();
  useEffect(onCloseMenu, [onCloseMenu, pathname]);

  return (
    <>
      {isOpen && <Backdrop onClick={onCloseMenu} />}

      <Container isOpen={isOpen}>
        <Button
          icon={RiCloseLine}
          variant="ghost"
          color="light"
          className="mobile-menu__close-btn"
          onClick={onCloseMenu}
        />

        <div className="submenu__container">
          <ul className="mobile-menu__list scrollable">
            <li>
              <NavLink to={HOME} exact>
                Inicio
              </NavLink>
            </li>
            <li>
              <NavLink to={NEWS}>Noticias</NavLink>
            </li>
            <li>
              <NavLink to={ABOUT}>Sobre nós</NavLink>
            </li>
          </ul>
        </div>
      </Container>
    </>
  );
}
