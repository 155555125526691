import retry from 'retry';

import { ImageUploadHandler } from 'components/form/Editor/types';

import api from 'services/api';

export type ResponseData = {
  id: string;
  location: string;
};

/**
 * This function upload the blob image received from the **TinyMCE Editor** and
 * then set an array in the local storage with the with the ids of the uploaded
 * images (the item in local storage is called **_@jl:bodyImagesId_**).
 *
 * If an error occurs then the `failure` callback is executed with a message
 * and remove the image from the document.
 */
export const uploadEditorImage: ImageUploadHandler = (
  blob,
  success,
  failure
) => {
  const operation = retry.operation({
    retries: 2,
  });

  const formData = new FormData();
  formData.append('file', blob.blob(), blob.filename());

  operation.attempt(async () => {
    try {
      const { data } = await api.post<ResponseData>('news/images', formData);

      const bodyImagesString = localStorage.getItem('@jl:bodyImagesId') ?? '[]';
      const bodyImagesId = JSON.parse(bodyImagesString) as string[];

      bodyImagesId.push(data.id);
      localStorage.setItem('@jl:bodyImagesId', JSON.stringify(bodyImagesId));

      success(data.location);
    } catch (error) {
      if (operation.retry(error)) return;

      let message = 'Um erro desconhecido ocorreu';
      if (error?.response.data) message = error.response.data.message;

      failure(message, { remove: true });
    }
  });
};
