import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';

export const Container = styled.section`
  max-width: 72rem;
  margin: 0 auto 3rem;

  > * + * {
    margin-top: 4rem;
  }

  .add-new-highlight {
    position: relative;

    .card {
      height: 100%;
      opacity: 0.6;
      filter: saturate(0);
    }

    button {
      background: transparent;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      outline: 0;

      color: ${color('primary-default')};
      border: 0;

      display: grid;
      place-items: center;

      &:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0);
        transition: ${cvar('transition-normal')};
      }

      svg {
        position: absolute;
        transition: ${cvar('transition-normal')};
      }

      &:hover,
      &:focus {
        &:before {
          background-color: rgba(0, 0, 0, 0.05);
          backdrop-filter: blur(2px);
        }

        svg {
          transform: scale(1.5);
        }
      }

      &:focus {
        outline: 2px solid ${color('secondary-default')};
      }
    }
  }
`;
