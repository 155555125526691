import { css } from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

/**
 *! THIS FILE MUST ONLY CONTAIN SHARED STYLED RELATED WITH LAYOUTS
 *! (BUT NOT THAT ONE INSIDE `pages/layout` FOLDER)
 *
 ** The layouts here is not a whole component which handle all pages but a style
 ** used on similar pages layouts and which can or can not be used in all pages
 ** inside a group of pages (think about routes inside the `routes.tsx` file)
 */

/**
 * Create a layout grid with:
 * - Single column on mobile and side content at the end;
 * - Two columns on tablet (min: 44em) where the main column is twice the side column size;
 * - Two columns on tablet landscape up where the main column is three times greater
 *   that the side column size;
 *
 * You can customize the gap of the columns by creating a CSS var called `--items-gap`,
 * which defaults to **1rem** on mobile and **1.5rem** on tablet (min: 44em);
 *
 * You can also customize the grid padding by creating the var `--layout-padding`
 * which defaults to **0 var(--items-gap)**.
 */
export const sideContentLayout = css`
  --items-gap: 1rem;

  display: grid;
  grid-template-columns: 1fr;
  gap: var(--items-gap);
  padding: var(--layout-padding, 0 var(--items-gap));

  ${mediaQuery.min('44em')} {
    --items-gap: 1.5rem;
    grid-template-columns: 2fr 1fr;
  }

  ${mediaQuery.tabletLandscapeUp} {
    grid-template-columns: 3fr 1fr;
  }
`;
