import { useMutation, useQuery } from 'react-query';

import api from 'services/api';
import { queryClient } from 'services/queryClient';

import { buildQueryString } from 'utils/buildQueryString';

import {
  Periodical,
  CreateData,
  MutateResponseData,
  UpdateData,
  UsePeriodicalsProps,
  UseCreatePeriodicalProps,
  UseSoftDeletePeriodicalProps,
  UseUpdatePeriodicalProps,
  UseTogglePeriodicalVisibilityProps,
  GetPeriodicalsQuery,
} from './types';

const QUERY_KEY = 'periodicals';

// ********************************************************
// ***                     LIST ALL                     ***
// ********************************************************
const getPeriodicals = async (query: GetPeriodicalsQuery = {}) => {
  const uri = buildQueryString(query, 'periodicals');

  const { data } = await api.get<Periodical[]>(uri);
  return data;
};

export function usePeriodicals<T = Periodical[]>({
  query,
  ...options
}: UsePeriodicalsProps<T> = {}) {
  const key = query ? [QUERY_KEY, query] : QUERY_KEY;

  return useQuery(key, () => getPeriodicals(query), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30, // ? 30 minutes,
    ...options,
  });
}

// ********************************************************
// ***                      CREATE                      ***
// ********************************************************
const createPeriodical = async ({ name }: CreateData) => {
  const { data } = await api.post<MutateResponseData>('periodicals', { name });
  return data;
};

export function useCreatePeriodical({
  onSuccess,
  ...options
}: UseCreatePeriodicalProps = {}) {
  return useMutation(createPeriodical, {
    onSuccess: (data, ...rest) => {
      queryClient.setQueriesData<Periodical[]>(
        {
          queryKey: QUERY_KEY,
          predicate: ({ queryHash }) => !queryHash.includes('withDeleted'),
        },
        oldData => {
          const newPeriodical = { ...data, news: 0 };
          return oldData ? [newPeriodical, ...oldData] : [newPeriodical];
        }
      );
      onSuccess?.(data, ...rest);
    },
    ...options,
  });
}

// ********************************************************
// ***                      UPDATE                      ***
// ********************************************************
const updatePeriodical = async ({ id, name }: UpdateData) => {
  const resource = `periodicals/${id}`;
  const { data } = await api.put<MutateResponseData>(resource, { name });
  return data;
};

export function useUpdatePeriodical({
  onSuccess,
  ...options
}: UseUpdatePeriodicalProps = {}) {
  return useMutation(updatePeriodical, {
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData<Periodical[]>(QUERY_KEY, oldData => {
        const newData = oldData?.map(periodical => {
          if (periodical.id === variables.id) return { ...periodical, ...data };
          return periodical;
        });
        return newData ?? [];
      });

      onSuccess?.(data, variables, context);
    },
    ...options,
  });
}

// *******************************************************
// ***                   SOFT DELETE                   ***
// *******************************************************
const softDeletePeriodical = async (id: string) => {
  await api.patch(`periodicals/${id}/soft_delete`);
};

export function useSoftDeletePeriodical({
  onSuccess,
  ...options
}: UseSoftDeletePeriodicalProps = {}) {
  return useMutation(softDeletePeriodical, {
    onSuccess: (data, periodicalId, context) => {
      queryClient.setQueriesData<Periodical[]>(QUERY_KEY, oldData => {
        const filteredPeriodicals = oldData?.filter(
          periodical => periodical.id !== periodicalId
        );
        return filteredPeriodicals ?? [];
      });

      onSuccess?.(data, periodicalId, context);
    },
    ...options,
  });
}

// *******************************************************
// ***                TOGGLE VISIBILITY                ***
// *******************************************************
const togglePeriodicalVisibility = async (id: string) => {
  await api.patch(`periodicals/${id}/visibility`);
};

export function useTogglePeriodicalVisibility({
  onSuccess,
  ...options
}: UseTogglePeriodicalVisibilityProps = {}) {
  return useMutation(togglePeriodicalVisibility, {
    onSuccess: (data, periodicalId, context) => {
      queryClient.setQueriesData<Periodical[]>(QUERY_KEY, oldData => {
        const newData = oldData?.map(periodical => {
          if (periodical.id === periodicalId)
            return { ...periodical, isVisible: !periodical.isVisible };
          return periodical;
        });
        return newData ?? [];
      });

      onSuccess?.(data, periodicalId, context);
    },
    ...options,
  });
}
