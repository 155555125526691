import ReactModal from 'react-modal';

import styled from 'styled-components';

import { color, cvar, font } from 'utils/cvar';

export const Container = styled.div`
  header {
    background-color: ${color('secondary-default')};
    padding: 0.5rem 0.75rem;

    h2 {
      font: ${font('body-large-heavy')};
      text-align: center;
    }
  }

  main {
    background-color: ${color('white')};
    border: 1px solid ${color('line')};
    border-top: 0;

    button {
      background-color: transparent;
      border: none;

      img {
        transition: transform ${cvar('transition-quick')};
      }

      &:hover img {
        transform: scale(1.05);
      }
    }
  }

  .info {
    padding: 0.75rem;

    p {
      font: ${font('body-small-heavy')};
      text-align: center;
      color: ${color('title-active')};
    }
  }
`;

export const AspectRatio = styled.div`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc(100% / (5 / 6));
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

export const ZoomModal = styled(ReactModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /*
    Since this modal is intentioned to be temporary the aspect ratio
    width percentage (0.8333) is hard coded once I also could not found a
    better solution
  */
  width: min(calc(100% - 2rem), calc((100vh - 4rem) * 0.8333));
  max-height: calc(100vh - 4rem);

  outline: none;
  background-color: ${color('white')};
  padding: 0.25rem;

  .close-modal {
    position: absolute;
    top: -0.5rem;
    right: -0.5rem;
    z-index: 1;
  }
`;
