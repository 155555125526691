type Metadata = {
  page: number | string;
  total: number;
  limit: number | string;
};

export function formatPaginationMetadata(metadata: Metadata) {
  const { total } = metadata;

  const page = Number(metadata.page);
  const limit = Number(metadata.limit);
  const totalPages = Math.ceil(total / limit);

  const pageGroup = page * limit;
  const range = {
    start: pageGroup - (limit - 1),
    end: pageGroup >= total ? total : pageGroup,
  };

  return { page, limit, total, totalPages, range };
}
