import { forwardRef } from 'react';
import { Link } from 'react-router-dom';

import PulseLoader from '../PulseLoader';
import Spinner from '../Spinner';
import { StyledButton } from './styles';
import {
  AnchorForwardedRef,
  ButtonForwardedRef,
  PolymorphicButton,
  PolymorphicButtonProps,
  PolymorphicButtonRef,
} from './types';

const Button: PolymorphicButton = forwardRef<
  PolymorphicButtonRef,
  PolymorphicButtonProps
>(
  (
    {
      color = 'primary',
      variant = 'fill',
      size = 'medium',
      isLoading,
      isBlock,
      icon: Icon,
      children,
      ...rest
    },
    ref
  ) => {
    // ? If href is passed we're going to consider this as external link
    // eslint-disable-next-line no-nested-ternary
    const linkComponent = 'to' in rest ? Link : rest.href ? 'a' : 'button';

    // ? Allow setting some default properties conditionally
    const isDisabledOrLoading = rest.disabled || isLoading;

    let props = {
      ...rest,
      tabIndex: isDisabledOrLoading ? -1 : rest.tabIndex,
      'aria-disabled': rest['aria-disabled'] || isDisabledOrLoading,
    };

    if (!('to' in rest) && !rest.href)
      props = {
        type: 'button',
        ...props,
      };

    // ? Check if button has only the icon to render a square button
    const isIconOnly = !!(Icon && !children);

    return (
      <StyledButton
        as={linkComponent}
        ref={ref as ButtonForwardedRef | AnchorForwardedRef}
        $color={color}
        $variant={variant}
        $size={size}
        $iconOnly={isIconOnly}
        $isLoading={isLoading}
        $isBlock={isBlock}
        {...props}
      >
        {children}
        {Icon && <Icon />}
        {isIconOnly ? (
          <Spinner
            isLoading={isLoading}
            size={size === 'small' ? '1.5rem' : '2rem'}
            color="overlay-dark-50"
          />
        ) : (
          <PulseLoader
            isLoading={isLoading}
            size={size === 'small' ? '0.5rem' : '0.625rem'}
            color="overlay-dark-50"
          />
        )}
      </StyledButton>
    );
  }
);

export default Button;
