import { forwardRef, useEffect, useRef, useState } from 'react';

import clsx from 'clsx';

import { Container } from './styles';
import { RadioProps } from './types';

const Radio = forwardRef<HTMLInputElement, RadioProps>(
  (
    {
      label: groupLabel,
      options,
      name,
      error,
      containerClassName,
      containerStyle,
      removeMarginTop,
      isVertical,
      onClick,
      className,
      ...rest
    },
    forwardedRef
  ) => {
    const labelsRef = useRef<HTMLLabelElement[]>([]);
    const [widerLabel, setWiderLabel] = useState(0);

    // ? Get wider label to use as min width inside input group wrapper.
    // ? This way we can automatically organize the layout based on the
    // ? options displayed.
    useEffect(() => {
      if (!isVertical) {
        const labels = labelsRef.current;
        const fontSize = window
          .getComputedStyle(document.body)
          .getPropertyValue('font-size')
          .replace(/px/g, '');

        let maxContent = 0;

        labels?.forEach(label => {
          if (label.clientWidth > maxContent) maxContent = label.clientWidth;
        });

        setWiderLabel(maxContent / Number(fontSize));
      }
    }, [isVertical]);

    return (
      <Container
        style={containerStyle}
        className={containerClassName}
        hasError={!!error}
        isReadOnly={rest.readOnly}
        isDisabled={rest.disabled}
        removeMarginTop={removeMarginTop}
        widerLabel={widerLabel}
        isVertical={isVertical}
      >
        {groupLabel && <p>{groupLabel}</p>}

        <div>
          {options.map(({ id, value, label, ...optionsRest }) => (
            <label
              ref={ref => ref && labelsRef.current.push(ref)}
              key={id || value}
              htmlFor={id || value.toString()}
            >
              <input
                type="radio"
                name={name}
                id={id || value.toString()}
                value={value}
                ref={forwardedRef}
                className={clsx(className, rest.readOnly && 'is-read-only')}
                onClick={rest.readOnly ? e => e.preventDefault() : onClick}
                {...rest}
                {...optionsRest}
              />

              <span className="control" />

              <span className="label">{label}</span>
            </label>
          ))}
        </div>

        {error && <span>{error}</span>}
      </Container>
    );
  }
);

export default Radio;
