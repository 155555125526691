type QueryData = { [key: string]: string | number | boolean | Date | null };

/**
 * Return a string where the prefix with set before the **"?"** on the query
 * string and the data will be added to the string with its key and value in
 * query string format like this:
 *
 * `prefix?key=value`
 */
export function buildQueryString(data: QueryData, prefix = '') {
  const entries = Object.entries(data);

  if (entries.length === 0) return prefix;

  const query = entries.reduce((acc, [key, value], index) => {
    if (value === undefined) return acc;

    const adjustedValue = value instanceof Date ? value.toISOString() : value;

    const concat = index > 0 && acc.slice(-1) !== '?' ? '&' : '';
    return `${acc}${concat}${key}=${adjustedValue}`;
  }, `${prefix}?`);

  return query;
}
