import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';

export const StyledParagraph = styled.p`
  width: calc(100% - 2.5rem);
  height: calc(100% - 3.5rem);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${color('title-active')};

  span {
    color: ${color('secondary-dark')};
  }

  svg {
    color: ${color('placeholder')};
    transition: ${cvar('transition-normal')};
    margin-bottom: 1rem;
  }

  &.drag-reject {
    svg {
      color: ${color('danger-default')};
    }
  }

  &.drag-active {
    svg {
      color: ${color('secondary-default')};
    }
  }
`;
