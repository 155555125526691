import styled from 'styled-components';

import { sidebarLinkWithIconStyle } from '../SidebarLink/styles';

export const SignOutButton = styled.button`
  ${sidebarLinkWithIconStyle}
  background: transparent;
  width: 100%;
  border: none;
`;
