import { ContentLoading, Text } from 'components';

import { formatDate } from 'lib';

import { useActivePoll } from 'hooks/api';

import { formatAlternativesAsChartData } from 'utils/formatAlternativesAsChartData';

import { PollWithChart } from '../PollWithChart';

export function Active() {
  const { data: activePoll, isLoading } = useActivePoll({
    select: data => {
      if (!data) return null;

      return {
        ...data,
        startDate: formatDate(new Date(data.startDate), 'P'),
        chart: formatAlternativesAsChartData(data.alternatives),
      };
    },
  });

  if (isLoading) return <ContentLoading label="Carregando enquete ativa" />;

  return (
    <div>
      <Text as="h2" variant="heading-medium" mb="2rem">
        Enquete ativa
      </Text>

      {activePoll ? (
        <PollWithChart poll={activePoll} />
      ) : (
        <div className="no-data-container">
          <Text variant="heading-x-small">
            Nenhuma enquete ativa no momento
          </Text>
        </div>
      )}
    </div>
  );
}
