import { Control, Controller, useWatch } from 'react-hook-form';

import { Dropzone } from 'components';

import { Container } from './styles';

//* TYPES
type DropzoneWithInitialPreviewProps = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  defaultValue?: {
    file?: File;
    preview: string;
  };
  name: string;
  error?: string;
  label: string;
  readOnly: boolean;
  helpText?: string;
  initialPreview: {
    src: string;
    alt: string;
  };
};

//* COMPONENT
export function DropzoneWithInitialPreview({
  control,
  name,
  error,
  defaultValue,
  label,
  helpText,
  readOnly,
  initialPreview,
}: DropzoneWithInitialPreviewProps) {
  const image = useWatch({ control, name });

  return (
    <Container>
      <Controller
        control={control}
        name={name}
        defaultValue={defaultValue}
        render={({ field: { onChange, ...rest } }) => (
          <Dropzone
            label={label}
            helpText={helpText}
            error={error}
            readOnly={readOnly}
            maxSize={1024 * 1024 * 10}
            onChange={([file]) => onChange(file)}
            {...rest}
          />
        )}
      />
      {!image?.file && (
        <img
          src={initialPreview.src}
          alt={initialPreview.alt}
          referrerPolicy="no-referrer"
        />
      )}
    </Container>
  );
}
