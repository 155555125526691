import { Controller } from 'react-hook-form';
import { RiAddLine, RiEyeLine, RiSubtractLine } from 'react-icons/ri';

import { addDays } from 'date-fns';

import {
  Button,
  Can,
  Checkbox,
  ContentModal,
  DatePicker,
  Dropzone,
  Editor,
  InputText,
  Radio,
  Select,
  Text,
} from 'components';

import { uploadEditorImage } from 'utils/uploadEditorImage';

import {
  DynamicFieldset,
  EditorHeader,
  FieldsetGrid,
  GalleryItem,
  PreviewTriggersContainer,
} from './styles';
import { CreateNewsFormProps, FlatError } from './types';

export function CreateNewsForm({
  editorRef,
  errors,
  isValid,
  isLoading,
  control,
  fields,
  imageDropzoneKey,
  editorError,
  isHighlight,
  hasGallery,
  isPublicationModalOpen,
  categories,
  periodicals,
  authors,
  highlightTypes,
  positions,
  onSubmit,
  register,
  setValue,
  watch,
  append,
  isCategoryOptionDisabled,
  toggleHighlight,
  toggleGallery,
  onOpenPublicationModal,
  onClosePublicationModal,
  removeGalleryItem,
  openPreview,
}: CreateNewsFormProps) {
  return (
    <form onSubmit={onSubmit}>
      {/* GENERAL INFORMATION */}
      <FieldsetGrid>
        <div>
          <InputText
            label="Título"
            error={errors.title?.message}
            readOnly={isLoading}
            {...register('title')}
          />

          <Controller
            control={control}
            name="categoriesId"
            defaultValue={[]}
            render={({ field }) => (
              <Select
                label="Categorias"
                options={categories}
                isOptionDisabled={isCategoryOptionDisabled}
                isMulti
                isDisabled={isLoading}
                error={(errors.categoriesId as unknown as FlatError)?.message}
                {...field}
              />
            )}
          />
        </div>

        <InputText
          multiline
          label="Descrição"
          maxLength={128}
          error={errors.description?.message}
          readOnly={isLoading}
          {...register('description')}
        />

        <Controller
          control={control}
          name="periodicalId"
          defaultValue={undefined}
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              label="Coluna"
              isDisabled={isLoading}
              error={errors.periodicalId?.message}
              options={periodicals}
              value={periodicals?.filter(opt => opt.value === value)}
              onChange={option => onChange(option?.value)}
              isClearable
              {...rest}
            />
          )}
        />
        <InputText
          label="Autor"
          list="authors"
          readOnly={isLoading}
          {...register('author')}
        />
        <datalist id="authors">
          {authors?.map(author => (
            <option key={author.id} value={author.name}>
              {author.name}
            </option>
          ))}
        </datalist>

        {/* MAIN IMAGE */}
        <Controller
          key={imageDropzoneKey}
          control={control}
          name="mainImage"
          render={({ field: { onChange, ...rest } }) => (
            <Dropzone
              label="Foto destaque"
              helpText="Anexe a foto principal que estará em destaque na matéria"
              error={(errors.mainImage as unknown as FlatError)?.message}
              readOnly={isLoading}
              maxSize={1024 * 1024 * 10}
              onChange={files => onChange(files[0])}
              {...rest}
            />
          )}
        />

        <div>
          <InputText
            label="Descrição da foto"
            error={errors.mainImageDescription?.message}
            readOnly={isLoading}
            {...register('mainImageDescription')}
          />
          <InputText
            label="Autor da foto"
            error={errors.mainImageAuthor?.message}
            readOnly={isLoading}
            containerClassName="author-input-container"
            {...register('mainImageAuthor')}
          />
        </div>
      </FieldsetGrid>

      {/* CONTENT (EDITOR) */}
      <EditorHeader>
        <Text as="h2" variant="heading-small" color="label">
          Redigir matéria
        </Text>

        <PreviewTriggersContainer>
          <Button color="secondary" onClick={openPreview}>
            Pré-visualizar
          </Button>

          <Button color="secondary" onClick={openPreview} icon={RiEyeLine} />
        </PreviewTriggersContainer>
      </EditorHeader>

      <Editor
        ref={editorRef}
        imageUploadHandler={uploadEditorImage}
        disabled={isLoading}
        error={editorError}
      />

      {/* HIGHLIGHT */}
      <Can roles={['root', 'editor']}>
        <DynamicFieldset>
          <Checkbox
            label="Marcar como destaque"
            name="highlight"
            checked={isHighlight}
            disabled={isLoading}
            onChange={toggleHighlight}
            containerClassName="toggle"
          />

          {isHighlight && (
            <FieldsetGrid as="div">
              <Controller
                control={control}
                name="highlightId"
                render={({ field: { onChange, ...rest } }) => (
                  <Select
                    label="Tipo de destaque"
                    options={highlightTypes}
                    isDisabled={isLoading}
                    onChange={option => {
                      onChange(option);
                      setValue(
                        'position',
                        option && option.label !== 'Carrossel'
                          ? { label: '1', value: '0' }
                          : null
                      );
                    }}
                    isClearable
                    {...rest}
                  />
                )}
              />
              {watch('highlightId')?.type === 'carousel' && (
                <Controller
                  control={control}
                  name="position"
                  render={({ field }) => (
                    <Select
                      label="Posição"
                      error={(errors.position as unknown as FlatError)?.message}
                      options={positions}
                      isDisabled={isLoading}
                      isClearable
                      {...field}
                    />
                  )}
                />
              )}
            </FieldsetGrid>
          )}
        </DynamicFieldset>
      </Can>

      {/* GALLERY */}
      <DynamicFieldset>
        <Checkbox
          label="Adicionar galeria de imagens"
          name="gallery"
          checked={hasGallery}
          disabled={isLoading}
          onChange={toggleGallery}
          containerClassName="toggle"
        />

        <div style={{ display: hasGallery ? 'initial' : 'none' }}>
          {fields.map((field, index) => (
            <GalleryItem key={field.id}>
              <FieldsetGrid as="div">
                <Controller
                  control={control}
                  name={`gallery.${index}.image`}
                  defaultValue={field.image}
                  render={({ field: { onChange, ...rest } }) => (
                    <Dropzone
                      label="Foto"
                      error={
                        (errors.gallery?.[index]?.image as unknown as FlatError)
                          ?.message
                      }
                      readOnly={isLoading}
                      maxSize={1024 * 1024 * 10}
                      onChange={files => onChange(files[0])}
                      {...rest}
                    />
                  )}
                />
                <div>
                  <InputText
                    label="Descrição da foto"
                    error={errors.gallery?.[index]?.description?.message}
                    readOnly={isLoading}
                    defaultValue={field.description}
                    {...register(`gallery.${index}.description`)}
                  />
                  <InputText
                    label="Autor da foto"
                    error={errors.gallery?.[index]?.author?.message}
                    readOnly={isLoading}
                    containerClassName="author-input-container"
                    defaultValue={field.description}
                    {...register(`gallery.${index}.author`)}
                  />
                </div>
              </FieldsetGrid>
              <div className="gallery__action-remove">
                <Button
                  icon={RiSubtractLine}
                  color="danger"
                  size="small"
                  disabled={isLoading}
                  onClick={() => removeGalleryItem(index)}
                >
                  Remover
                </Button>
              </div>
            </GalleryItem>
          ))}

          <div className="gallery__action-add">
            <Button
              icon={RiAddLine}
              color="secondary"
              size="small"
              className="gallery-add"
              disabled={isLoading}
              onClick={() =>
                append({ image: undefined, description: '', author: '' })
              }
            >
              Nova imagem
            </Button>
          </div>
        </div>
      </DynamicFieldset>

      {/* SCHEDULING */}
      <Can roles={['root', 'editor']}>
        <ContentModal
          style={{
            overlay: { overflow: 'auto' },
            content: { overflow: 'visible' },
          }}
          isOpen={isPublicationModalOpen}
          onRequestClose={onClosePublicationModal}
          size="small"
          variant="primary"
          title="Publicar notícia"
          showCancelButton
          shouldReturnFocusAfterClose={false}
          actionButton={{
            text: 'Salvar',
            onClick: () => {
              onSubmit();
              if (!isValid) onClosePublicationModal();
            },
            disabled: watch('status') === 'sketch',
            isLoading,
          }}
        >
          <Radio
            options={[
              {
                label: 'Agora',
                value: 'publish',
                defaultChecked: watch('status') === 'publish',
              },
              {
                label: 'Agendar',
                value: 'schedule',
                defaultChecked: watch('status') === 'schedule',
              },
            ]}
            {...register('status')}
          />

          {watch('status') === 'schedule' && (
            <Controller
              control={control}
              name="publicationDate"
              render={({ field: { value, ...rest } }) => (
                <DatePicker
                  removeMarginTop
                  label="Publicar em"
                  dateFormat="Pp"
                  placeholderText="dd/mm/aaaa hh:mm"
                  showTimeSelect
                  selected={value}
                  error={errors.publicationDate?.message}
                  minDate={addDays(new Date(), 1)}
                  {...rest}
                />
              )}
            />
          )}
        </ContentModal>
      </Can>

      <Button
        type="submit"
        isLoading={isLoading}
        onClick={() => setValue('status', isHighlight ? 'publish' : 'sketch')}
      >
        {isHighlight ? 'Publicar' : 'Salvar rascunho'}
      </Button>

      {!isHighlight && (
        <Can roles={['root', 'editor']}>
          <Button
            color="secondary"
            isLoading={isLoading}
            onClick={onOpenPublicationModal}
          >
            Publicar
          </Button>
        </Can>
      )}
    </form>
  );
}
