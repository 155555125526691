import ReactModal from 'react-modal';

import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const StyledModal = styled(ReactModal)`
  .content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: max-content;
    padding: 0 1rem;

    pointer-events: none;
  }

  img,
  p {
    background-color: ${color('white')};
    outline: none;
  }

  .image {
    position: relative;
    width: 100%;
    max-width: max-content;
    margin: 0 auto;

    > * {
      pointer-events: all;
    }

    img {
      width: auto;
      max-width: 100%;
      max-height: 65vh;
      display: block;
      padding: 0.25rem;
    }

    &__navigate {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);

      display: grid;
      place-items: center;
      border: 0;
      outline: 0;
      padding: 0.375rem;
      color: ${color('white')};
      background-color: ${color('overlay-dark-30')};

      transition-property: background-color, color;
      transition-duration: ${cvar('transition-quick')};

      svg {
        width: 2.25rem;
        height: 2.25rem;
      }

      &:not(:disabled) {
        &:hover,
        &:focus {
          background-color: ${color('overlay-dark-50')};
        }
      }

      &:disabled {
        opacity: 0.2;
      }

      &--left {
        left: 0.25rem;
      }

      &--right {
        right: 0.25rem;
      }
    }

    .close-modal {
      position: absolute;
      top: -0.5rem;
      right: -0.5rem;
    }
  }

  p {
    padding: 0.5rem;
    margin: 0.5rem auto 0;
    width: fit-content;
    max-width: 40rem;
  }

  ${mediaQuery.tabletPortraitUp} {
    .content {
      padding: 0 1.5rem;
    }

    .image img {
      max-height: 75vh;
    }
  }

  ${mediaQuery.desktopUp} {
    .content {
      width: min(100%, 80vw);
    }

    .image img {
      max-height: 82.5vh;
    }
  }
`;
