/* eslint-disable import/no-duplicates */
import { formatDistanceToNowStrict as dateFnsFormatDistanceToNowStrict } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type Options = Omit<
  NonNullable<Parameters<typeof dateFnsFormatDistanceToNowStrict>['1']>,
  'locale'
>;

export default function formatDateDistanceToNowStrict(
  date: number | Date,
  options?: Options
) {
  return dateFnsFormatDistanceToNowStrict(date, {
    locale: ptBR,
    ...options,
  });
}
