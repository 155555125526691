import { ComponentType } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { PageLoading } from 'components';
import { AdminLayout } from 'pages/layout';

import { useAuth } from 'contexts/AuthContext';

import { validateUserRoles } from 'utils/validateUserRoles';

import { ADMIN_AUTH_SIGN_IN, ADMIN_DASHBOARD } from '../PATHS';

type AdminRouteProps = Omit<RouteProps, 'children' | 'render'> & {
  component: ComponentType;
  roles?: string[];
};

export default function AdminRoute({
  component: Component,
  roles,
  ...rest
}: AdminRouteProps) {
  const { isLoading, user } = useAuth();

  if (isLoading) return <PageLoading />;
  if (!user?.isAdmin) return <Redirect to={ADMIN_AUTH_SIGN_IN} />;

  if (roles) {
    const hasRoles = validateUserRoles({ user, roles });
    if (!hasRoles) return <Redirect to={ADMIN_DASHBOARD} />;
  }

  return (
    <Route
      {...rest}
      render={props => (
        <AdminLayout>
          <Component {...props} />
        </AdminLayout>
      )}
    />
  );
}
