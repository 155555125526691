import { useEffect, useContext, useState, createContext } from 'react';
import { useHistory } from 'react-router-dom';

import api from 'services/api';

import { validateUserRoles } from 'utils/validateUserRoles';

import {
  AuthContextData,
  AuthProviderProps,
  Credentials,
  GetMeResponseData,
  PostSessionsData,
  Role,
  User,
} from './types';

//* CONSTANTS
const ACCESS_TOKEN_KEY = '@jl:access-token';
const REFRESH_TOKEN_KEY = '@jl:refresh-token';

//* CLEAR AUTH TOKENS FROM BOTH LOCAL STORAGE AND API CLIENT
function clearTokens() {
  localStorage.removeItem(ACCESS_TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
  api.defaults.headers.Authorization = undefined;
}

//* GET ACCESS TOKEN FROM LOCAL STORAGE
function getAccessToken() {
  return localStorage.getItem(ACCESS_TOKEN_KEY);
}

//* CHECK IF THE USER IS AN ADMIN
const ADMIN_ROLES = ['root', 'columnist', 'intern', 'editor'];

function isAdmin(roles: Role[]) {
  return validateUserRoles({ user: { roles }, roles: ADMIN_ROLES });
}

//* CONTEXT
const AuthContext = createContext<AuthContextData>({} as AuthContextData);

//* CONTEXT PROVIDER
export function AuthProvider({ children }: AuthProviderProps) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User | null>(null);
  const history = useHistory();

  useEffect(() => {
    async function loadUser() {
      if (!getAccessToken()) return;

      try {
        const { data } = await api.get<GetMeResponseData>('users/me');
        setUser({ ...data, isAdmin: isAdmin(data.roles) });
        setIsAuthenticated(true);
      } catch {
        clearTokens();
        setIsAuthenticated(false);
      }
    }

    loadUser().finally(() => setIsLoading(false));
  }, []);

  async function signIn(credentials: Credentials) {
    const { data } = await api.post<PostSessionsData>('sessions', credentials);

    setUser({ ...data.user, isAdmin: isAdmin(data.user.roles) });
    setIsAuthenticated(true);
    localStorage.setItem(ACCESS_TOKEN_KEY, data.accessToken);
    localStorage.setItem(REFRESH_TOKEN_KEY, data.refreshToken);
    api.defaults.headers.Authorization = `Bearer ${data.accessToken}`;
  }

  function signOut(redirectPath: string) {
    setUser(null);
    setIsAuthenticated(false);
    clearTokens();
    history.push(redirectPath);
  }

  return (
    <AuthContext.Provider
      value={{
        signIn,
        signOut,
        user,
        isAuthenticated,
        isLoading,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuth() {
  return useContext(AuthContext);
}
