import ReactModal from 'react-modal';

import styled, { css } from 'styled-components';

import { color } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

import { StyledContentModalProps } from './types';

const getSize = (size: StyledContentModalProps['size']) => {
  if (size === 'small') return '25rem';
  if (size === 'medium') return '37.5rem';
  return '60rem';
};

// eslint-disable-next-line prettier/prettier
export const StyledContentModal = styled(ReactModal) <StyledContentModalProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  max-width: ${p => getSize(p.size)};
  max-height: calc(100vh - 4rem);
  transform: translate(-50%, -50%);
  overflow: auto;

  background-color: ${color('white')};
  outline: none;

  ${p =>
    p.variant === 'neutral' &&
    css`
      --color-neutral-default: ${color('body')};
      --color-neutral-lightest: ${color('input-background')};
    `}

  ${p => css`
    header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 1rem 0.75rem 1rem 1.5rem;
      background-color: ${`var(--color-${p.variant}-lightest)`};

      div {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-right: 0.5rem;

        svg {
          color: ${`var(--color-${p.variant}-default)`};
          margin-right: 0.75rem;
        }

        h2 {
          color: ${`var(--color-${p.variant}-default)`};
        }
      }

      --color-tertiary-default: ${color('tertiary-darker')};
    }
  `}

  main {
    padding: 1rem 1.5rem 0.75rem;
  }

  footer {
    text-align: right;
    padding: 1.5rem;

    button + button {
      margin-left: 0.5rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    max-height: calc(100vh - 10rem);

    header {
      padding: 1rem 0.75rem 1rem 1rem;
    }

    main {
      padding: 1rem 1rem 0.75rem;
    }

    footer {
      padding: 1rem;
      display: flex;

      button {
        flex: 1;
      }
    }
  }
`;
