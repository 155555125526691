import { useQuery } from 'react-query';

import api from 'services/api';

import { Author, UseAuthorsProps } from './types';

// ********************************************************
// ***                     LIST ALL                     ***
// ********************************************************
const getAuthors = async () => {
  const { data } = await api.get<Author[]>('authors');
  return data;
};

export function useAuthors<T = Author[]>(props?: UseAuthorsProps<T>) {
  return useQuery('authors', getAuthors, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30, // ? 30 minutes,
    ...props,
  });
}
