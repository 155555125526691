import { Link } from 'react-router-dom';

import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';

import { StyledLinkProps } from './types';

// eslint-disable-next-line prettier/prettier
export const StyledLink = styled(Link) <StyledLinkProps>`
  font-weight: 600;
  color: ${p => color(`${p.$color}-default`)};
  transition: color ${cvar('transition-quick')};

  &:hover {
    color: ${p => color(`${p.$color}-dark`)};
  }

  &:active {
    color: ${p => color(`${p.$color}-darker`)};
  }
`;
