import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  max-width: 40rem;
  margin: 0 auto;
  padding: 1rem;

  display: grid;
  place-items: center;
  align-content: center;
  gap: 1rem;
  min-height: 100vh;

  ${mediaQuery.min('44em')} {
    padding: 1.5rem;
  }
`;
