/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
/* eslint-disable no-template-curly-in-string */

export default {
  mixed: {
    required: '${path} é obrigatório',
  },
  string: {
    length: ({ path, length }: any) =>
      `${path} deve ter exatamente ${length} caractere${length === 1 ? '' : 's'}`,
    min: ({ path, min }: any) =>
      `${path} deve ter pelo menos ${min} caractere${min === 1 ? '' : 's'}`,
    max: ({ path, max }: any) =>
      `${path} deve ter no máximo ${max} caractere${max === 1 ? '' : 's'}`,
    email: '${path} deve ser um e-mail válido',
  },
  array: {
    min: ({ path, min }: any) =>
      `${path} deve ter pelo menos ${min} ${min === 1 ? 'item' : 'itens'}`,
    max: ({ path, max }: any) =>
      `${path} deve ter no máximo ${max} ${max === 1 ? 'item' : 'itens'}`,
  },
};
