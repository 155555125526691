import { forwardRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';

import AlertModal from 'components/modal/AlertModal';

import { useToggle } from 'hooks';
import { useSoftDeleteNews } from 'hooks/api';

export type ConfirmDeleteModalRef = {
  open: () => void;
};

type ConfirmDeleteModalProps = {
  id: string;
  title: string;
  onConfirmDelete: () => void;
};

export const ConfirmDeleteModal = forwardRef<
  ConfirmDeleteModalRef,
  ConfirmDeleteModalProps
>(({ id, title, onConfirmDelete }, ref) => {
  const [isOpen, onToggle] = useToggle();
  useImperativeHandle(ref, () => ({ open: onToggle }));

  const mutation = useSoftDeleteNews({
    onError: () => {
      toast.error('Um erro inesperado ocorreu. Por favor, tente novamente');
    },
    onSuccess: () => {
      toast.success('Notícia excluída com sucesso');
      onToggle();
      onConfirmDelete();
    },
  });

  return (
    <AlertModal
      isOpen={isOpen}
      onRequestClose={onToggle}
      title="Excluir notícia"
      variant="danger"
      showCancelButton
      disabledCloseButtons={mutation.isLoading}
      shouldCloseOnOverlayClick={!mutation.isLoading}
      actionButton={{
        text: 'Excluir',
        onClick: () => mutation.mutate(id),
        isLoading: mutation.isLoading,
      }}
    >
      <p>
        Deseja excluir a notícia intitulada <strong>{title}</strong>?
      </p>
    </AlertModal>
  );
});
