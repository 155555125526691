import styled, { css } from 'styled-components';
import { sideContentLayout } from 'styles/layouts';
import { contentFromEditorStyles } from 'styles/shared';

import mediaQuery from 'utils/mediaQuery';

const containerStyle = css`
  max-width: 85.375rem;
  margin: 1.5rem auto 6rem;
`;

export const NoDataContainer = styled.div`
  ${containerStyle}
  padding: 0 1rem;
  min-height: 40vh;
  display: grid;
  place-items: center;
  align-content: center;
  gap: 1.5rem;
`;

export const Container = styled.div`
  ${containerStyle}
  ${sideContentLayout}
  grid-template-areas:
    'about'
    'main-image'
    'content'
    'side-content';

  .container {
    &__about {
      grid-area: about;
    }

    &__main-image {
      grid-area: main-image;
    }

    &__content {
      grid-area: content;

      > * {
        max-width: 40.5rem;
      }
    }

    &__side-content {
      grid-area: side-content;
    }
  }

  ${mediaQuery.max('43.99em')} {
    --layout-padding: 0;

    > *:not(figure) {
      padding: 0 var(--items-gap);
    }
  }

  ${mediaQuery.min('44em')} {
    grid-template-areas:
      'about      about'
      'main-image main-image'
      'content    side-content';
  }

  ${mediaQuery.tabletLandscapeUp} {
    grid-template-areas:
      'about      side-content'
      'main-image side-content'
      'content    side-content';
    grid-template-rows: auto auto 1fr;
  }
`;

export const AboutSection = styled.section`
  display: grid;
  gap: 0.75rem;
  margin-bottom: 1rem;

  .categories {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
  }

  .metadata {
    margin-top: 0.5rem;
  }

  ${mediaQuery.tabletPortraitUp} {
    margin-bottom: 1rem;
    gap: 1.25rem;

    .metadata {
      margin-top: 0.75rem;
    }
  }
`;

export const MainImage = styled.figure`
  display: block;

  .ratio-box {
    position: relative;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc((9 / 16) * 100%);
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  figcaption {
    margin-top: 0.5rem;
  }

  ${mediaQuery.max('43.99em')} {
    figcaption {
      margin: 0.5rem var(--items-gap) 0;
    }
  }

  ${mediaQuery.between('44em', '56.24em')} {
    margin-bottom: 1rem;
  }
`;

export const Content = styled.div`
  margin: var(--space-top, 1rem) auto 0;

  ${contentFromEditorStyles}

  &::after {
    display: block;
    content: '';
    clear: both;
  }

  ${mediaQuery.min('44em')} {
    --space-top: 3rem;
  }

  ${mediaQuery.between('44em', '56.24em')} {
    --space-top: 0;
  }
`;

export const Gallery = styled.div`
  margin: 3rem auto 0;
`;

export const SocialLinks = styled.div`
  margin: 3rem auto 0;

  .share > div {
    display: flex;
    flex-wrap: wrap;
    gap: 0.75rem;
  }

  .social-media {
    margin-top: 3rem;

    a {
      background: var(--bg-color);

      & + a {
        margin-left: 1rem;
      }

      &:not(:disabled) {
        &:hover {
          background: var(--bg-color);
          opacity: 0.8;
        }

        &:focus {
          box-shadow: none;
          outline: 2px solid var(--outline-color, #ffffff);
          outline-offset: -6px;
        }
      }
    }

    .twitter {
      --bg-color: #1da1f2;
    }

    .facebook {
      --bg-color: #3b5998;
    }

    .instagram {
      --bg-color: linear-gradient(
        30.57deg,
        #ffdb7a 10.12%,
        #fcaf45 20.04%,
        #f77737 31.15%,
        #e1306c 44.24%,
        #833ab4 74.83%,
        #405de6 95.02%
      );
    }
  }
`;
