import { RiLinksLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import Tippy from '@tippyjs/react';

import { useDisclosure } from 'hooks';

import { Container } from './styles';
import { ShareButtonProps } from './types';

import 'tippy.js/animations/shift-away.css';
import 'tippy.js/dist/border.css';

const shareErrorMessage =
  'Um erro ocorreu ao realizar o compartilhamento. Por favor, tente novamente.';

export default function ShareButton({
  link = window.location.href,
  title,
  text,
}: ShareButtonProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleClick = () => {
    if (navigator.share === undefined) {
      navigator.clipboard
        .writeText(link)
        .then(onOpen)
        .catch(() => toast.error(shareErrorMessage));
      return;
    }

    navigator.share({ title, text, url: link }).catch(error => {
      if (error instanceof DOMException && error.name === 'AbortError') return;

      toast.error(shareErrorMessage);
    });
  };

  const handleOnShow = () => {
    setTimeout(onClose, 1500);
  };

  return (
    <Container>
      <Tippy
        interactive
        visible={isOpen}
        aria={{ expanded: 'auto' }}
        arrow
        hideOnClick={false}
        placement="top"
        animation="shift-away"
        onShow={handleOnShow}
        offset={[0, 12]}
        content="Link copiado"
      >
        <button type="button" onClick={handleClick}>
          <RiLinksLine size="0.875rem" />
          Compartilhar
        </button>
      </Tippy>
    </Container>
  );
}
