import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// ? When the page location change, the restore scroll
export function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

// ? Restore scroll only on mount component
export function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}
