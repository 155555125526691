import styled, { css } from 'styled-components';
import { fixMarginCollapsing } from 'styles/shared';

import { color, font, cvar } from 'utils/cvar';

import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  ${fixMarginCollapsing}

  ${p =>
    !p.removeMarginTop &&
    css`
      margin-top: 1rem;
    `}

  label {
    display: flex;
    align-items: flex-start;
    width: fit-content;
    position: relative;
    cursor: pointer;
    margin-bottom: ${p => (p.hasError ? '0.5rem' : '2rem')};

    .control {
      display: inline-flex;
      background-color: ${color('input-background')};
      border: 1px solid ${color('line')};
      transition: ${cvar('transition-quick')};
      margin-top: 0.1875rem; //? 3px when 1rem = 16px

      svg {
        color: ${color('white')};
        opacity: 0;
        transition: ${cvar('transition-quick')};
      }
    }

    .label {
      display: inline-block;
      margin-left: 0.75rem;
      color: ${color('label')};
    }

    input {
      position: absolute;
      left: 0;
      opacity: 0;
      pointer-events: none;

      &:checked {
        & + .control {
          background-color: ${color('primary-default')};
          border-color: ${color('primary-default')};

          svg {
            opacity: 1;
          }
        }
      }

      &:hover:not(:disabled):not(.is-read-only) {
        & + .control {
          background-color: ${color('line')};
          border-color: ${color('primary-light')};
        }

        &:checked {
          & + .control {
            background-color: ${color('primary-dark')};
            border-color: ${color('primary-dark')};
          }
        }
      }

      &:focus {
        & + .control {
          box-shadow: 0 0 0 4px ${color('primary-lighter')};
        }

        &:not(:checked) + .control {
          border: 1px solid ${color('primary-light')};
        }

        &:checked {
        }
      }
    }
  }

  ${p =>
    (p.isDisabled || p.isReadOnly) &&
    css`
      label {
        cursor: ${p.isDisabled ? 'not-allowed' : 'default'};

        .control {
          background-color: ${color('line')};
          opacity: 0.8;
        }

        input:checked + .control {
          background-color: ${color('primary-light')};
          border-color: ${color('primary-light')};
        }
      }
    `}

  ${p =>
    p.hasError &&
    css`
      label .control {
        border-color: ${color('danger-default')};
        background-color: ${color('danger-lightest')};
      }

      > span {
        display: inline-block;
        font: ${font('body-small')};
        color: ${color('danger-default')};
      }
    `}
`;
