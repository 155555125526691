import { RefObject, useEffect } from 'react';

import { useLatest } from '.';

/**
 ** Inspired by:
 ** https://github.com/Andarist/use-onclickoutside/
 ** https://usehooks.com/useOnClickOutside/
 */

type PossibleEvent = MouseEvent | TouchEvent;
type Handler = (event: PossibleEvent) => void;

export default function useOnClickOutside(
  ref: RefObject<HTMLElement | null>,
  handler: Handler
) {
  const savedHandler = useLatest(handler);

  useEffect(() => {
    const eventListener = (event: PossibleEvent) => {
      const element = ref.current;

      if (element && !element.contains(event.target as Node))
        savedHandler(event);
    };

    document.addEventListener('mousedown', eventListener);
    document.addEventListener('touchstart', eventListener);

    return () => {
      document.removeEventListener('mousedown', eventListener);
      document.removeEventListener('touchstart', eventListener);
    };
  }, [ref, savedHandler]);
}
