import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const PollsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(var(--poll-width, 30rem), 1fr)
  );
  gap: 1rem;

  .pagination {
    justify-self: center;
    margin-top: 2rem;
  }

  ${mediaQuery.phoneOnly} {
    --poll-width: 18rem;
  }
`;
