import { useEffect, useRef } from 'react';
import { RiAddLine, RiCloseLine } from 'react-icons/ri';

import { Button, Card, Text } from 'components';

import { placeholder } from 'assets';

import { useArray, useToggle } from 'hooks';
import { useNewsHighlightTypes } from 'hooks/api';

import AddHighlightModal from '../AddHighlightModal';
import ConfirmRemoveModal, {
  ConfirmRemoveModalRef,
} from '../ConfirmRemoveModal';
import RepositionModal, { RepositionModalRef } from '../RepositionModal';
import { News } from '../types';
import { Container } from './styles';

type CarouselHighlightsProps = { data: News[] };

export default function CarouselHighlights({ data }: CarouselHighlightsProps) {
  const confirmDeleteModalRef = useRef<ConfirmRemoveModalRef>(null);
  const repositionModalRef = useRef<RepositionModalRef>(null);

  const [isAddHighlightModalOpen, toggleAddHighlightModal] = useToggle();
  const { array: highlights, move, remove, replace } = useArray(data);

  const { data: type } = useNewsHighlightTypes({
    select: types => types.find(v => v.type === 'carousel'),
  });

  useEffect(() => replace(data), [data, replace]);

  return (
    <Container>
      <Text as="h1" variant="heading-medium" mb="0.5rem">
        Carrossel
      </Text>
      <Text>
        Os destaques aparecem na mesma ordem em que são apresentados abaixo.
      </Text>

      <div className="card-grid">
        {highlights.map((news, index) => (
          <div className="card-wrapper" key={news.id}>
            <Card
              variant="x-small"
              title={news.title}
              image={{
                url: news.mainImage.url,
                alt: news.mainImage.description,
                aspectRatio: 3 / 2,
              }}
            />

            <Button
              size="small"
              className="card-wrapper__action--position"
              onClick={() =>
                repositionModalRef.current?.open({
                  highlighId: news.newsHighlightId,
                  initialPosition: index,
                })
              }
            >
              {index + 1}
            </Button>

            {highlights.length > 5 && (
              <Button
                size="small"
                color="danger"
                icon={RiCloseLine}
                className="card-wrapper__action--delete"
                onClick={() => confirmDeleteModalRef.current?.open(news, index)}
              />
            )}
          </div>
        ))}

        {highlights.length < 10 && (
          <>
            <div className="add-new-highlight">
              <Card
                variant="x-small"
                title="Novo destaque"
                image={{ url: placeholder, alt: '', aspectRatio: 3 / 2 }}
              />
              <button type="button" onClick={toggleAddHighlightModal}>
                <RiAddLine size="4rem" />
              </button>
            </div>

            <AddHighlightModal
              isOpen={isAddHighlightModalOpen}
              onRequestClose={toggleAddHighlightModal}
              availablePositions={highlights.length + 1}
              highlightId={type?.id as string}
            />
          </>
        )}
      </div>

      <RepositionModal
        ref={repositionModalRef}
        availablePositions={highlights.length}
        onReposition={move}
      />

      <ConfirmRemoveModal
        ref={confirmDeleteModalRef}
        title="Remover noticia do carrossel"
        onConfirm={remove}
      />
    </Container>
  );
}
