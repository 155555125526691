import styled from 'styled-components';

import { color, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const Container = styled.section`
  max-width: 72rem;
  margin: 0 auto 3rem;

  .header {
    margin-bottom: 2rem;
  }

  .loading-container {
    height: 50vh;
    display: grid;
    place-items: center;
  }

  .no-data-container {
    display: grid;
    place-items: center;
    height: 30vh;
  }

  ${mediaQuery.phoneOnly} {
    .header h1 {
      margin-bottom: 1rem;
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  ${mediaQuery.max('74.999em')} {
    .data-table thead {
      display: none;
    }
  }
`;

export const DataTableBody = styled.tbody`
  td.categories {
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }

  ${mediaQuery.max('74.999em')} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 1rem;

    tr {
      background-color: ${color('white')};
      border: 1px solid ${color('line')};
      padding: 0.75rem;
      display: grid;
      grid-gap: 1.5rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: max-content auto max-content;
      grid-template-areas:
        'category category'
        'title    title'
        'date     status';

      td:nth-child(1) {
        grid-area: title;
        margin-top: -0.75rem;
      }

      td:nth-child(2) {
        grid-area: category;
      }

      td:nth-child(3) {
        grid-area: date;
        align-self: center;
        position: relative;

        &:before {
          content: attr(data-header);
          font: ${font('body-normal-heavy')};
        }

        &:after {
          content: '';
          position: absolute;
          top: -0.75rem;
          left: 0;
          width: calc(200% + 1.5rem);
          height: 1px;
          background: ${color('line')};
        }
      }

      td:nth-child(4) {
        grid-area: status;
        text-align: center;
      }
    }
  }

  ${mediaQuery.desktopUp} {
    td.truncate {
      overflow: hidden;
      text-overflow: ellipsis;
      width: 100%;
      max-width: 0;
      min-width: 10rem;
    }
  }
`;

export const PreviewTriggerButton = styled.button.attrs({
  type: 'button',
})`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  text-align: left;
  background: transparent;
  color: inherit;
  border: 0;
  outline: 0;

  &:hover {
    text-decoration: underline;
    color: ${color('secondary-dark')};
  }

  &:focus {
    outline: 1px solid ${color('secondary-default')};
    outline-offset: 0.25rem;
  }
`;
