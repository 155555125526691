import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const Container = styled.section`
  max-width: 72rem;
  margin: 0 auto 3rem;

  fieldset {
    border: 0;
  }

  form > button {
    margin-top: 2rem;

    & + button {
      margin-left: 1rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    form > button {
      width: 100%;

      & + button {
        margin-top: 1rem;
        margin-left: 0;
      }
    }
  }
`;
