import { useEffect, useState, Fragment } from 'react';
import { useForm } from 'react-hook-form';
import { RiCloseLine } from 'react-icons/ri';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { debounce } from 'lodash';

import { Button, InputText, Spinner, Text } from 'components';

import { useInfiniteNews, usePromoteNewsToHighlight } from 'hooks/api';

import { StyledReactModal, NewsRadio } from './styles';
import { AddHighlightFormData, AddHighlightModalProps } from './types';

export default function AddHighlightModal({
  isOpen,
  onRequestClose,
  availablePositions,
  highlightId,
}: AddHighlightModalProps) {
  const [search, setSearch] = useState('');
  const queryClient = useQueryClient();

  const {
    register,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { isValid },
  } = useForm<AddHighlightFormData>({ mode: 'onChange' });

  const { data, isFetching, isFetchingNextPage, hasNextPage, fetchNextPage } =
    useInfiniteNews({
      query: { search, homePage: true },
      staleTime: 1000 * 60 * 5,
    });

  const promoteNewsToHighlightMutation = usePromoteNewsToHighlight({
    onSuccess: () => {
      queryClient.refetchQueries('news_highlight');
      queryClient.invalidateQueries(['news_infinite', { homePage: true }]);
      reset({});
      toast.success('Destaque adicionado com sucesso');
      onRequestClose();
    },
  });

  useEffect(() => {
    watch(
      debounce(async (values, info) => {
        if (info.name === 'search') {
          setSearch(values.search);
          setValue('newsId', null);
        }
      }, 1000)
    );
  }, [setValue, watch]);

  const onSubmit = handleSubmit(({ position, newsId }) => {
    promoteNewsToHighlightMutation.mutate({
      newsId: newsId as string,
      highlightId,
      position: position ? Number(position) : undefined,
    });
  });

  return (
    <StyledReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      overlayClassName="ReactModal__Overlay"
    >
      <Button
        icon={RiCloseLine}
        color="neutral"
        size="small"
        variant="ghost"
        className="close-modal"
        onClick={onRequestClose}
      />

      <header>
        {availablePositions !== undefined && (
          <div className="position">
            <Text as="label" color="label" mb="0.75rem">
              Posição
            </Text>

            <div className="position__options">
              {Array.from(Array(availablePositions).keys()).map(position => (
                <label key={position} htmlFor={`position-${position}`}>
                  <input
                    type="radio"
                    id={`position-${position}`}
                    readOnly={promoteNewsToHighlightMutation.isLoading}
                    value={position}
                    {...register('position', { required: true })}
                  />

                  <Text
                    as="span"
                    variant="heading-small"
                    className="position-number"
                  >
                    {position + 1}
                  </Text>
                </label>
              ))}
            </div>
          </div>
        )}

        <div className="search-container">
          <InputText
            type="search"
            label="Procurar noticia"
            removeErrorSlotMargin
            removeMarginTop
            readOnly={promoteNewsToHighlightMutation.isLoading}
            {...register('search')}
          />
          <Spinner
            color="primary-default"
            isLoading={isFetching && !isFetchingNextPage}
            className="spinner"
          />
        </div>
      </header>

      <hr />

      {data && data.pages[0].total > 0 && (
        <div className="list-container">
          <ul>
            {data.pages.map(group => (
              <Fragment key={`page-${group.page}`}>
                {group.data.map(news => (
                  <li key={news.id}>
                    <NewsRadio>
                      <input
                        type="radio"
                        id={`radio-${news.id}`}
                        value={news.id}
                        readOnly={promoteNewsToHighlightMutation.isLoading}
                        {...register('newsId', { required: true })}
                      />

                      <label htmlFor={`radio-${news.id}`}>
                        <img
                          src={news.mainImage.url}
                          alt={news.mainImage.description}
                        />
                        <Text
                          as="span"
                          variant="body-normal-heavy"
                          align="left"
                        >
                          {news.title}
                        </Text>
                      </label>
                    </NewsRadio>
                  </li>
                ))}
              </Fragment>
            ))}
          </ul>

          {hasNextPage && (
            <div className="load-more">
              <Button
                size="small"
                color="neutral"
                isLoading={isFetchingNextPage}
                onClick={() => fetchNextPage()}
              >
                Carregar mais
              </Button>
            </div>
          )}
        </div>
      )}

      {data && data.pages[0].total === 0 && (
        <div className="no-data">
          <Text variant="heading-small">Nenhum notícia encontrada</Text>
        </div>
      )}

      <footer>
        <Button
          size="small"
          color="neutral"
          variant="ghost"
          onClick={onRequestClose}
          isLoading={promoteNewsToHighlightMutation.isLoading}
        >
          Cancelar
        </Button>

        <Button
          size="small"
          color="primary"
          disabled={!isValid}
          onClick={onSubmit}
          isLoading={promoteNewsToHighlightMutation.isLoading}
        >
          Adicionar
        </Button>
      </footer>
    </StyledReactModal>
  );
}
