import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ContentModal, Dropzone } from 'components';

import { useDisclosure } from 'hooks';
import { useCreateEdition, useUpdateEditionFile } from 'hooks/api';

import {
  CreateEditionForm,
  CreateEditionModalRef,
  FlatError,
  OpenFn,
} from './types';

const notifySuccess = (update?: boolean) => () => {
  toast.success(`Edição ${update ? 'atualizada' : 'criada'} com sucesso`);
};

export const CreateEditionModal = forwardRef<CreateEditionModalRef>(
  (_, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
      control,
      register,
      reset,
      handleSubmit,
      watch,
      formState: { errors, isSubmitting, isValid },
    } = useForm<CreateEditionForm>();

    // ****** Ref management ******
    const open = useCallback<OpenFn>(
      props => {
        if (props) reset({ ...props });
        onOpen();
      },
      [onOpen, reset]
    );
    useImperativeHandle(ref, () => ({ open }));

    // ****** Queries ******
    const createEdition = useCreateEdition({ onSuccess: notifySuccess() });
    const updateEditionFile = useUpdateEditionFile({
      onSuccess: notifySuccess(true),
    });

    const onSubmit = handleSubmit(async ({ id, file }) => {
      if (id) await updateEditionFile.mutateAsync({ id, file });
      else await createEdition.mutateAsync({ file });
    });

    return (
      <ContentModal
        isOpen={isOpen}
        onRequestClose={() => {
          reset({ id: '', file: undefined });
          onClose();
        }}
        variant="primary"
        size="small"
        title={watch('id') ? 'Atualizar edição' : 'Nova edição'}
        showCancelButton
        disabledCloseButtons={isSubmitting}
        shouldCloseOnOverlayClick={!isSubmitting}
        actionButton={{
          text: 'Salvar',
          onClick: onSubmit,
          disabled: !isValid,
          isLoading: isSubmitting,
        }}
      >
        <input type="hidden" {...register('id')} />

        <Controller
          control={control}
          name="file"
          rules={{ required: 'Capa da edição é obrigatório' }}
          render={({ field: { onChange, ...rest } }) => (
            <Dropzone
              style={{ height: '20rem' }}
              label="Capa da edição"
              error={(errors.file as unknown as FlatError)?.message}
              readOnly={isSubmitting}
              maxSize={1024 * 1024 * 10}
              onChange={files => onChange(files[0].file)}
              {...rest}
            />
          )}
        />
      </ContentModal>
    );
  }
);
