import { CarouselProvider } from 'pure-react-carousel';
import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';

export const StyledCarouselProvider = styled(CarouselProvider)`
  .carousel {
    &__slider {
      left: -0.25rem;
      width: calc(100% + 0.5rem);
    }

    &__inner-slide {
      padding-left: 0.25rem;
      padding-right: 0.25rem;
    }

    &__image {
      object-fit: cover;
      cursor: pointer;
    }
  }
`;

export const ErrorImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;

export const Nav = styled.nav`
  display: flex;
  justify-content: center;
  margin-top: 1rem;

  button {
    & + button {
      margin-left: 0.5rem;
    }

    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 0;
    outline: 0;
    transition: ${cvar('transition-quick')};
    color: ${color('white')};
    background-color: ${color('label')};
    padding: 0.5rem;

    &:not(:disabled) {
      &:hover {
        background-color: ${color('body')};
      }

      &:focus {
        box-shadow: 0 0 0 4px ${color('placeholder')};
      }
    }

    &:disabled {
      pointer-events: none;
      opacity: 0.4;
    }
  }
`;
