import styled from 'styled-components';

import { color, font } from 'utils/cvar';

export const SketchesContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  gap: 2rem 1rem;

  .sketch-card {
    display: flex;
    flex-direction: column;

    &__actions {
      display: flex;
      gap: 0.5rem;
    }

    &__main-content {
      flex: 1;
      background-color: ${color('white')};
      border: 1px solid ${color('line')};
      padding: 1rem;
    }

    h3 {
      font: ${font('body-normal-heavy')};
      margin-bottom: 1rem;
    }

    span {
      font: ${font('body-small-heavy')};
      color: ${color('label')};
      display: block;
      margin-bottom: 0.5rem;
    }

    ul {
      padding-left: 1rem;

      li {
        font: ${font('body-small')};
      }

      li + li {
        margin-top: 0.25rem;
      }
    }
  }
`;
