import { forwardRef } from 'react';
import ReactDatePicker, { registerLocale } from 'react-datepicker';

import ptBR from 'date-fns/locale/pt-BR';

import { Container } from './styles';
import { DatePickerProps } from './types';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('pt-BR', ptBR);

const DatePicker = forwardRef<ReactDatePicker, DatePickerProps>(
  (
    {
      name,
      label,
      containerClassName,
      removeMarginTop,
      removeErrorSlotMargin,
      error,
      containerStyle,
      dateFormat = 'dd/MM/yyyy',
      placeholderText = 'dd/mm/aaaa',
      timeCaption = 'Horário',
      isClearable = true,
      autoComplete = 'off',
      ...rest
    },
    forwardedRef
  ) => {
    return (
      <Container
        style={containerStyle}
        className={containerClassName}
        removeMarginTop={removeMarginTop}
        removeErrorSlotMargin={removeErrorSlotMargin}
        hasError={!!error}
      >
        {label && <label htmlFor={name}>{label}</label>}

        <ReactDatePicker
          id={name}
          name={name}
          locale="pt-BR"
          placeholderText={placeholderText}
          dateFormat={dateFormat}
          timeCaption={timeCaption}
          isClearable={isClearable}
          autoComplete={autoComplete}
          ref={forwardedRef}
          {...rest}
        />

        {error && <span>{error}</span>}
      </Container>
    );
  }
);

export default DatePicker;
