import { RiLogoutBoxLine } from 'react-icons/ri';

import { useAuth } from 'contexts/AuthContext';

import { ADMIN_AUTH_SIGN_IN } from 'routes/PATHS';

import { SignOutButton } from './styles';

export default function UserMenu() {
  const { signOut } = useAuth();

  return (
    <SignOutButton onClick={() => signOut(ADMIN_AUTH_SIGN_IN)}>
      <RiLogoutBoxLine size="1.5rem" />
      <span>Sair</span>
    </SignOutButton>
  );
}
