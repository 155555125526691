import styled from 'styled-components';
import {
  darkBackgroundScrollbar,
  scrollableContentIndicatorBorder,
  scrollbarWidth,
} from 'styles/shared';

import { color, cvar, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const MobileHeader = styled.header`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${color('primary-default')};
  padding: 0 1rem;
  z-index: ${cvar('z-index-header')};

  div.user {
    display: grid;
    place-items: center;
    height: 2rem;
    width: 2rem;
    background: ${color('tertiary-default')};
    border-radius: 50%;
    font: ${font('heading-x-small')};
    color: ${color('title-active')};
  }
`;

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${cvar('z-index-backdrop')};
  background: ${color('overlay-dark-50')};
  cursor: pointer;
`;

export const Aside = styled.aside`
  position: fixed;
  top: 0;
  width: 16rem; //? 256px when 1rem = 16px
  max-width: 16rem;
  height: 100vh;
  z-index: ${cvar('z-index-sidebar')};
  background: ${color('primary-default')};
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
  transition-duration: ${cvar('transition-quick')};
  transition-property: max-width, transform;
  transform: translateX(-100%);

  &.is-open {
    transform: translateX(0);
  }

  .sidebar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1.25rem;

    &__logo {
      font-size: 0;

      &:focus {
        outline: 2px solid ${color('secondary-default')};
        outline-offset: 0.5rem;
      }

      svg {
        width: auto;
        height: 1.5rem;
      }
    }
  }

  ${mediaQuery.tabletLandscapeUp} {
    position: sticky;
    transform: translateX(0);
    z-index: initial;

    &:not(.is-open) {
      max-width: 4rem;

      .sidebar-header {
        justify-content: center;

        &__logo {
          display: none;
        }
      }
    }
  }
`;

export const MainNavigation = styled.nav`
  flex: 1;
  overflow: hidden auto;
  margin-top: 2.5rem;

  ${scrollbarWidth}
  ${darkBackgroundScrollbar}
  ${scrollableContentIndicatorBorder}

  ul {
    list-style: none;
  }
`;
