import { forwardRef, useImperativeHandle } from 'react';
import { toast } from 'react-toastify';

import AlertModal from 'components/modal/AlertModal';

import { useToggle } from 'hooks';
import { useToggleNewsVisibility } from 'hooks/api';

export type ConfirmVisibilityModalRef = {
  open: () => void;
};

type ConfirmVisibilityModalProps = {
  id: string;
  title: string;
  isVisible: boolean;
  isHighlight?: boolean;
  onConfirm: () => void;
};

export const ConfirmVisibilityModal = forwardRef<
  ConfirmVisibilityModalRef,
  ConfirmVisibilityModalProps
>(({ id, title, isVisible, isHighlight, onConfirm }, ref) => {
  const [isOpen, onToggle] = useToggle();
  useImperativeHandle(ref, () => ({ open: onToggle }));

  const mutation = useToggleNewsVisibility({
    onError: error => {
      if (error.response?.data.field)
        toast.error(error.response.data.message, { autoClose: 10000 });
      else
        toast.error('Um erro inesperado ocorreu. Por favor, tente novamente');
    },
    onSuccess: () => {
      toast.success('Visibilidade alterada com sucesso');
      onToggle();
      onConfirm();
    },
  });

  return (
    <AlertModal
      isOpen={isOpen}
      onRequestClose={onToggle}
      title="Alterar visibilidade"
      variant="warning"
      showCancelButton={!isHighlight}
      disabledCloseButtons={mutation.isLoading}
      shouldCloseOnOverlayClick={!mutation.isLoading}
      actionButton={{
        text: isHighlight ? 'Entendi' : 'Alterar',
        onClick: isHighlight ? onToggle : () => mutation.mutate(id),
        isLoading: mutation.isLoading,
      }}
    >
      {isHighlight ? (
        <p>
          Não é possivel ocultar esta noticia pois ela está marcada como
          destaque. Por favor, primeiramente a remova do mesmo.
        </p>
      ) : (
        <p>
          Deseja tornar a notícia intitulada <strong>{title}</strong>{' '}
          {isVisible ? 'oculta' : 'visível'}?
        </p>
      )}
    </AlertModal>
  );
});
