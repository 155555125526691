import styled from 'styled-components';

export const Container = styled.div`
  max-width: 50rem;
  margin: 0 auto 3rem;
  height: 70vh;
  display: grid;
  place-items: center;
  align-content: center;
  text-align: center;
  gap: 3rem;
`;
