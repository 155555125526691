/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */

import { forwardRef, useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';

import clsx from 'clsx';

import Message from './Message';
import { Container, DropzoneContainer } from './styles';
import { DropzoneProps, FileWithPreview } from './types';

// TODO: Better handling of multiple files
// TODO: Better handling of different kind of files (not only image/*)
const Dropzone = forwardRef<HTMLInputElement, DropzoneProps>(
  (
    {
      label,
      name,
      error,
      helpText,
      containerClassName,
      containerStyle,
      removeMarginTop,
      maxSize,
      disabled,
      readOnly,
      className,
      style,
      onChange,
      onBlur,
      ...rest
    },
    forwardedRef
  ) => {
    const [files, setFiles] = useState<FileWithPreview | null>(null);

    const onDropAccepted = useCallback(
      (acceptedFiles: File[]) => {
        const preview = URL.createObjectURL(acceptedFiles[0]);

        setFiles({ file: acceptedFiles[0], preview });

        if (onChange) onChange([{ file: acceptedFiles[0], preview }]);
      },
      [onChange]
    );

    const {
      getRootProps,
      getInputProps,
      inputRef,
      isFocused,
      isDragActive,
      isDragReject,
    } = useDropzone({
      maxSize,
      maxFiles: 1,
      multiple: false,
      onDropAccepted,
      disabled: disabled || readOnly,
      ...rest,
    });

    useEffect(
      () => () => {
        // ? Revoke the data uris to avoid memory leaks
        if (files) URL.revokeObjectURL(files.preview);
      },
      [files]
    );

    useEffect(() => {
      if (inputRef.current && forwardedRef)
        if (typeof forwardedRef === 'function') forwardedRef(inputRef.current);
        // eslint-disable-next-line no-param-reassign
        else forwardedRef.current = inputRef.current;
    }, [forwardedRef, inputRef]);

    const maxSizeMb = maxSize ? maxSize / (1024 * 1024) : null;

    return (
      <Container
        style={containerStyle}
        className={containerClassName}
        hasError={!!error}
        isDisabled={disabled}
        removeMarginTop={removeMarginTop}
      >
        {(label || maxSizeMb) && (
          <div className="label">
            {label && (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <label
                htmlFor={name}
                onClick={e => {
                  // ? Prevent dialog being open by clicking on the label
                  if (disabled || readOnly) e.preventDefault();
                }}
              >
                {label}
              </label>
            )}
            {maxSizeMb && <span>Máx.: {maxSizeMb}mb</span>}
          </div>
        )}

        <DropzoneContainer
          {...getRootProps({
            hasError: !!error,
            hasHelpText: !!helpText,
            isDisabled: disabled,
            isReadOnly: readOnly,
            onBlur: onBlur || undefined,
            isFocused,
            style,
            className: clsx(
              disabled && 'is-disabled',
              readOnly && 'is-read-only',
              isDragReject && 'drag-reject',
              className
            ),
          })}
        >
          <input
            {...getInputProps({
              name,
              id: name,
              readOnly,
              'aria-readonly': readOnly,
            })}
          />

          {files ? (
            <img src={files.preview} alt={files.file.name} />
          ) : (
            <Message isDragActive={isDragActive} isDragReject={isDragReject} />
          )}
        </DropzoneContainer>

        {error ? (
          <span className="error">{error}</span>
        ) : (
          helpText && <span className="help">{helpText}</span>
        )}
      </Container>
    );
  }
);

export default Dropzone;
