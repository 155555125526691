import { Helmet } from 'react-helmet';

import { LastNewsList, SideContentWithWidgets } from 'components';
import { PollVoting } from 'components/common/PollVoting';

import { Active } from './Active';
import { History } from './History';
import { Container } from './styles';

export default function Polls() {
  return (
    <Container>
      <Helmet>
        <title>Enquetes</title>
      </Helmet>

      <div className="content">
        <Active />
        <History />
      </div>

      <SideContentWithWidgets>
        <LastNewsList />
        <PollVoting />
      </SideContentWithWidgets>
    </Container>
  );
}
