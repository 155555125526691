import styled, { css } from 'styled-components';

import { color, cvar } from 'utils/cvar';

import { StyledButtonProps } from './types';

function getColor({ $color, $variant }: StyledButtonProps) {
  if ($variant === 'fill') {
    if (['secondary', 'tertiary'].includes($color))
      return color('title-active');

    if ($color === 'light') return color('body');
    return color('white');
  }

  return `var(--color-${$color}-default)`;
}

export const StyledButton = styled.button<StyledButtonProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 0;
  outline: 0;
  font-weight: 600;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: ${cvar('transition-quick')};
  color: ${getColor};

  ${p =>
    !p.$iconOnly &&
    css`
      > svg {
        margin-left: 0.75rem;
      }
    `};

  // ? COLORS
  ${p =>
    p.$color === 'neutral' &&
    css`
      --color-neutral-dark: ${color('body')};
      --color-neutral-default: ${color('label')};
      --color-neutral-light: ${color('placeholder')};
      --color-neutral-lighter: ${color('line')};
    `}

  ${p =>
    p.$color === 'light' &&
    css`
      --color-light-dark: ${color('line')};
      --color-light-default: ${color('input-background')};
      --color-light-light: ${color('placeholder')};
      --color-light-lighter: ${color('overlay-light-15')};
    `}

  // ? SIZES
  ${p =>
    p.$size === 'large' &&
    css`
      padding: ${p.$iconOnly ? '1rem' : '1.125rem 2rem'};
      font-size: 1.125rem; //? 18px
      line-height: 1.35;

      > svg {
        width: ${p.$iconOnly ? '1.75rem' : '1.5rem'};
        height: ${p.$iconOnly ? '1.75rem' : '1.5rem'};
      }
    `}

  ${p =>
    p.$size === 'medium' &&
    css`
      padding: ${p.$iconOnly ? '0.75rem' : '0.875rem 1.5rem'};
      font-size: 1rem; //? 16px
      line-height: 1.25;

      > svg {
        width: ${p.$iconOnly ? '1.5rem' : '1.25rem'};
        height: ${p.$iconOnly ? '1.5rem' : '1.25rem'};
      }
    `}

  ${p =>
    p.$size === 'small' &&
    css`
      padding: ${p.$iconOnly ? '0.5rem' : '0.625rem 1rem'};
      font-size: 0.875rem; //? 14px
      line-height: 1.14;

      > svg {
        width: ${p.$iconOnly ? '1.25rem' : '1rem'};
        height: ${p.$iconOnly ? '1.25rem' : '1rem'};
      }
    `}

  // ? VARIANTS
  ${p =>
    p.$variant === 'fill' &&
    css`
      background-color: ${`var(--color-${p.$color}-default)`};

      &:not(:disabled) {
        &:hover {
          background-color: ${`var(--color-${p.$color}-dark)`};
        }

        &:focus {
          box-shadow: 0 0 0 4px ${`var(--color-${p.$color}-light)`};
        }
      }
    `}

  ${p =>
    p.$variant === 'ghost' &&
    css`
      background-color: transparent;

      &:not(:disabled) {
        &:hover {
          background-color: ${`var(--color-${p.$color}-lighter)`};
        }

        &:focus {
          box-shadow: 0 0 0 4px ${`var(--color-${p.$color}-light)`};
        }
      }
    `}

  // ? FULL WIDTH (BLOCK)
  ${p =>
    p.$isBlock &&
    !p.$iconOnly &&
    css`
      width: 100%;
    `}

  // ? DISABLED AND LOADING
  // * Loaders positioning and colors
  > span {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // ? PulseLoader
    > span {
      background-color: ${getColor};
    }

    // ? Spinner
    > svg {
      color: ${getColor};
    }
  }

  ${p =>
    (p.disabled || p.$isLoading) &&
    css`
      pointer-events: none;
    `}

  ${p =>
    p.disabled &&
    !p.$isLoading &&
    css`
      opacity: 0.4;
    `}

  ${p =>
    p.$isLoading &&
    css`
      opacity: 0.8;
      color: transparent;
    `}
`;
