import { forwardRef, useImperativeHandle, useCallback } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { toast } from 'react-toastify';

import { ContentModal, InputText } from 'components';

import { useDisclosure } from 'hooks';
import { useCreatePeriodical, useUpdatePeriodical } from 'hooks/api';

import { CreatePeriodicalModalRef, FormData } from './types';

export type { CreatePeriodicalModalRef } from './types';

//* HELPERS
const notifySuccess = (verb: 'criada' | 'atualizada') => () => {
  toast.success(`Coluna ${verb} com sucesso`);
};

//* COMPONENT
export const CreatePeriodicalModal = forwardRef<CreatePeriodicalModalRef>(
  (_, ref) => {
    const { isOpen, onOpen, onClose } = useDisclosure();

    const {
      register,
      handleSubmit,
      reset,
      setError,
      getValues,
      formState: { errors, isSubmitting },
    } = useForm<FormData>();

    const open = useCallback<CreatePeriodicalModalRef['open']>(
      props => {
        if (props) reset({ ...props });
        onOpen();
      },
      [onOpen, reset]
    );

    useImperativeHandle(ref, () => ({ open }));

    const createMutation = useCreatePeriodical({
      onSuccess: notifySuccess('criada'),
    });

    const updateMutation = useUpdatePeriodical({
      onSuccess: notifySuccess('atualizada'),
    });

    const onSubmit: SubmitHandler<FormData> = async ({ id, name }) => {
      try {
        if (id) await updateMutation.mutateAsync({ id, name });
        else await createMutation.mutateAsync({ name });
        reset({ id: '', name: '' });
        onClose();
      } catch (error) {
        if (error.response?.data?.field !== 'id') {
          const { field, message } = error.response.data;
          setError(field, { message }, { shouldFocus: true });
        } else
          toast.error('Um erro inesperado ocorreu. Por favor, tente novamente');
      }
    };

    return (
      <ContentModal
        isOpen={isOpen}
        onRequestClose={() => {
          const isEditing = !!getValues('id');
          if (isEditing) reset({ id: '', name: '' });
          onClose();
        }}
        showCancelButton
        variant="primary"
        size="small"
        title="Cadastrar coluna"
        disabledCloseButtons={isSubmitting}
        shouldCloseOnOverlayClick={!isSubmitting}
        actionButton={{
          text: 'Salvar',
          onClick: handleSubmit(onSubmit),
          isLoading: isSubmitting,
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" {...register('id')} />
          <InputText
            label="Nome"
            error={errors.name?.message}
            readOnly={isSubmitting}
            {...register('name', { required: 'Nome é obrigatório' })}
          />
        </form>
      </ContentModal>
    );
  }
);
