import { forwardRef } from 'react';

import { Container } from './styles';
import {
  InputProps,
  InputTextComponent,
  InputTextProps,
  InputTextRef,
  TextareaProps,
} from './types';

const InputText: InputTextComponent = forwardRef<InputTextRef, InputTextProps>(
  (
    {
      label,
      name,
      error,
      multiline,
      containerClassName,
      containerStyle,
      removeMarginTop,
      removeErrorSlotMargin,
      ...rest
    },
    ref
  ) => {
    const props = {
      name,
      id: name,
      'aria-label': name,
      ref,
      ...rest,
    };

    return (
      <Container
        style={containerStyle}
        className={containerClassName}
        removeMarginTop={removeMarginTop}
        removeErrorSlotMargin={removeErrorSlotMargin}
        hasError={!!error}
        isReadOnly={rest.readOnly}
        isDisabled={rest.disabled}
      >
        {label && <label htmlFor={name}>{label}</label>}

        {multiline ? (
          <textarea {...(props as TextareaProps)} />
        ) : (
          <input {...(props as InputProps)} />
        )}

        {error && <span>{error}</span>}
      </Container>
    );
  }
);

export default InputText;
