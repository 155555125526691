import { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { RiAddLine, RiEditLine } from 'react-icons/ri';

import clsx from 'clsx';

import { Button, Card, ContentLoading, Pagination, Text } from 'components';

import { useEditionsAdm } from 'hooks/api';

import { CreateEditionModal } from './CreateEditionModal';
import { CreateEditionModalRef } from './CreateEditionModal/types';
import { Container, EditionsList } from './styles';

export default function ManageEditions() {
  const createEditionModalRef = useRef<CreateEditionModalRef | null>(null);
  const [page, setPage] = useState(1);

  const { data: editions, isLoading } = useEditionsAdm({
    query: { page, limit: 16 },
  });

  return (
    <Container>
      <Helmet>
        <title>Edições</title>
      </Helmet>

      <div className="header">
        <div className="header__title">
          <Text as="h1" variant="heading-medium">
            Controle de edições
          </Text>
          <Text>A última edição cadastrada estará automaticamente ativa</Text>
        </div>

        <Button
          icon={RiAddLine}
          onClick={() => createEditionModalRef.current?.open()}
        >
          Nova edição
        </Button>
      </div>

      {isLoading && <ContentLoading label="Carregando edições" />}

      {editions && (
        <EditionsList>
          <div className="editions">
            {editions.data.map((edition, index) => (
              <div
                key={edition.id}
                className={clsx(
                  'card-wrapper',
                  index === 0 && page === 1 && 'active'
                )}
              >
                <Card
                  variant="small"
                  image={{
                    aspectRatio: 5 / 6,
                    url: edition.url,
                    alt: `Capa da edição de número ${edition.editionNumber}`,
                  }}
                  title={`Edição ${edition.editionNumber}`}
                />
                <Button
                  size="small"
                  icon={RiEditLine}
                  onClick={() =>
                    createEditionModalRef.current?.open({ id: edition.id })
                  }
                />
              </div>
            ))}
          </div>

          <Pagination
            pageCount={editions.totalPages}
            onPageChange={({ selected }) => setPage(selected + 1)}
            forcePage={page - 1}
          />
        </EditionsList>
      )}

      <CreateEditionModal ref={createEditionModalRef} />
    </Container>
  );
}
