import { HTMLAttributes, ReactNode } from 'react';

import { TradingWidget, WeatherWidget } from 'components';

import { LastEdition } from './LastEdition';
import { Container } from './styles';

type SideContentWithWidgetsProps = HTMLAttributes<HTMLDivElement> & {
  children?: ReactNode;
};

export default function SideContentWithWidgets({
  children,
  ...rest
}: SideContentWithWidgetsProps) {
  return (
    <Container {...rest}>
      <LastEdition />
      <TradingWidget />
      <WeatherWidget />
      {children}
    </Container>
  );
}
