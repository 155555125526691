import { useCallback, useState } from 'react';

export default function useArray<T>(initialValue: T[]) {
  const [array, setArray] = useState(initialValue);

  const replace = useCallback((value: T[]) => setArray(value), []);
  const clear = useCallback(() => setArray([]), []);
  const append = useCallback((value: T) => setArray(a => [...a, value]), []);
  const prepend = useCallback((value: T) => setArray(a => [value, ...a]), []);
  const insert = useCallback(
    (index: number, value: T) =>
      setArray(a => [...a.slice(0, index), value, ...a.slice(index)]),
    []
  );
  const remove = useCallback(
    (index: number) =>
      setArray(a => [...a.slice(0, index), ...a.slice(index + 1)]),
    []
  );
  const move = useCallback(
    (fromIndex: number, toIndex: number) =>
      setArray(a => {
        const clone = [...a];
        clone.splice(toIndex, 0, clone.splice(fromIndex, 1)[0]);
        return clone;
      }),
    []
  );

  return { array, replace, clear, append, prepend, insert, remove, move };
}
