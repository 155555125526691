import styled from 'styled-components';

import { color, font } from 'utils/cvar';

import { Property, StyledTagProps, StylesColors } from './types';

const colors: StylesColors = {
  inverse: {
    background: {
      primary: color('primary-lighter'),
      secondary: color('secondary-lighter'),
      danger: color('danger-lighter'),
      success: color('success-lighter'),
      warning: color('tertiary-lighter'),
      neutral: color('line'),
    },
    border: {
      primary: color('primary-lighter'),
      secondary: color('secondary-lighter'),
      danger: color('danger-lighter'),
      success: color('success-lighter'),
      warning: color('tertiary-lighter'),
      neutral: color('line'),
    },
    color: {
      primary: color('primary-default'),
      secondary: color('secondary-darker'),
      danger: color('danger-dark'),
      success: color('success-dark'),
      warning: color('tertiary-darker'),
      neutral: color('body'),
    },
  },
  outline: {
    background: {
      primary: 'transparent',
      secondary: 'transparent',
      danger: 'transparent',
      success: 'transparent',
      warning: 'transparent',
      neutral: 'transparent',
    },
    border: {
      primary: color('primary-light'),
      secondary: color('secondary-default'),
      danger: color('danger-default'),
      success: color('success-default'),
      warning: color('tertiary-default'),
      neutral: color('label'),
    },
    color: {
      primary: color('primary-default'),
      secondary: color('secondary-dark'),
      danger: color('danger-default'),
      success: color('success-dark'),
      warning: color('tertiary-darker'),
      neutral: color('body'),
    },
  },
};

function getColor(property: Property, { $color, variant }: StyledTagProps) {
  return colors[variant][property][$color];
}

// ****** COMPONENT ******
export const StyledTag = styled.span<StyledTagProps>`
  padding: 0.125rem 0.5rem;
  display: inline-block;
  background-color: ${p => getColor('background', p)};
  border: 1px solid ${p => getColor('border', p)};
  color: ${p => getColor('color', p)};
  font: ${font('body-small')};
`;
