// * PUBLIC ROUTES
export const HOME = '/';
export const NEWS = '/noticias';
export const NEWS_BY_CATEGORIES = '/noticias/categoria';
export const NEWS_BY_PERIODICALS = '/noticias/coluna';
export const SEARCH_NEWS = '/pesquisa';
export const ABOUT = '/sobre';
export const POLLS = '/enquetes';

// ? ADMIN AUTHENTICATION (it is public but still related with the admin area)
export const ADMIN_AUTH_SIGN_IN = '/admin/entrar';

// * PRIVATE ROUTES
// ? All private pages MUST be prefixed with "/admin" and its name with ADMIN_
export const ADMIN_DASHBOARD = '/admin';
export const ADMIN_HIGHLIGHTS = '/admin/destaques';
export const ADMIN_EDITIONS = '/admin/edicoes';
export const ADMIN_NEWS = '/admin/noticias';
export const ADMIN_NEWS_CREATE = '/admin/noticias/cadastrar';
export const ADMIN_NEWS_UPDATE = '/admin/noticias/atualizar';
export const ADMIN_PERIODICALS = '/admin/colunas';
export const ADMIN_CATEGORIES = '/admin/categorias';
export const ADMIN_POLLS = '/admin/enquetes';
export const ADMIN_POLLS_HISTORY = '/admin/enquetes/historico';
