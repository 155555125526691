import { useRef, useState, useEffect, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useForm, useFieldArray } from 'react-hook-form';
import { toast } from 'react-toastify';

import { NewsPreview, Text } from 'components';
import { TinyMCEEditor } from 'components/form/Editor/types';
import { NewsPreviewRef } from 'components/modal/NewsPreview/types';

import { useDisclosure } from 'hooks';
import {
  useAuthors,
  useCategories,
  usePeriodicals,
  useCreateNews,
  useCreateNewsImage,
  useNewsHighlights,
  useNewsHighlightTypes,
} from 'hooks/api';

import { createNewsValidator } from 'shared/validators/news';

import {
  selectAsDropdownItem,
  selectAsPositions,
  selectAsTypes,
} from './_querySelect';
import { CreateNewsForm } from './CreateNewsForm';
import { Container } from './styles';
import { ApiValidationError, CreateNewsFormData } from './types';

toast.configure({
  autoClose: 10000,
});

//* REACT COMPONENT
export default function CreateNews() {
  const editorRef = useRef<TinyMCEEditor>(null);
  const previewRef = useRef<NewsPreviewRef>(null);

  const {
    isOpen: isPublicationModalOpen,
    onOpen: onOpenPublicationModal,
    onClose: onClosePublicationModal,
  } = useDisclosure();

  // ****** STATES ******
  const [isHighlight, setIsHighlight] = useState(false);
  const [hasGallery, setHasGallery] = useState(false);
  const [imageDropzoneKey, setImageDropzoneKey] = useState(Math.random());
  const [editorError, setEditorError] = useState('');
  const [editorImages, setEditorImages] = useState<string[]>([]);
  const [isSubmitSuccessful, setIsSubmitSuccessful] = useState(false);

  // ****** QUERIES ******
  const categoriesQuery = useCategories({ select: selectAsDropdownItem });
  const periodicalsQuery = usePeriodicals({ select: selectAsDropdownItem });
  const authorsQuery = useAuthors();
  const highlightTypesQuery = useNewsHighlightTypes({ select: selectAsTypes });
  const highlightsQuery = useNewsHighlights({ select: selectAsPositions });

  // ****** MUTATIONS ******
  const newsMutation = useCreateNews();
  const createNewsImageMutation = useCreateNewsImage();

  // ****** FORM SETUP ******
  const {
    register,
    handleSubmit,
    reset,
    getValues,
    control,
    formState: { errors, isSubmitting, isValid },
    ...form
  } = useForm<CreateNewsFormData>({
    resolver: createNewsValidator,
    reValidateMode: 'onBlur',
    defaultValues: { status: 'sketch' },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'gallery',
  });

  // ****** SUBMITTING THE FORM ******
  const onSubmit = handleSubmit(
    async ({
      categoriesId,
      mainImage,
      highlightId,
      position,
      publicationDate: scheduledDate,
      status,
      gallery,
      ...rest
    }) => {
      const editor = editorRef.current;

      if (!editor) return;
      if (!editor.getContent()) {
        setEditorError('O conteúdo da matéria é obrigatório');
        editor.focus();
        editor.editorContainer.scrollIntoView({ block: 'center' });
        return;
      }

      setEditorError('');

      try {
        let hasErrorOnCreateGallery = false;

        // ? Upload images inserted in the editor
        await editor.uploadImages();

        // ? Get all the ids of the image inserted and uploaded in the editor
        // ? To send and associate with the news when creating it
        const storageBodyImagesId: string[] = JSON.parse(
          localStorage.getItem('@jl:bodyImagesId') ?? '[]'
        );

        const bodyImagesId = [...editorImages, ...storageBodyImagesId];
        setEditorImages(bodyImagesId);
        localStorage.removeItem('@jl:bodyImagesId');

        let publicationDate: Date | undefined;
        if (status === 'publish') publicationDate = new Date();
        if (status === 'schedule') publicationDate = scheduledDate;

        const highlightInfo = isHighlight && {
          highlightId: highlightId?.value,
          position: position?.value,
        };

        const regex =
          /(?!figcaption.+?)\s?contenteditable=\\?".+?\\?"\s?(?!\s)?/g;
        const contentHtml = editor.getContent().replace(regex, '');

        // ? Make a request to create the news
        const { newsId } = await newsMutation.mutateAsync({
          categoriesId: categoriesId.map(c => c.value),
          mainImage: mainImage.file,
          contentHtml,
          contentText: editor.getContent({ format: 'text' }),
          bodyImagesId,
          isVisible: status !== 'sketch',
          publicationDate,
          ...highlightInfo,
          ...rest,
        });

        // ? Create gallery if requested
        if (hasGallery && gallery?.length > 0) {
          const results = await Promise.allSettled(
            gallery.map(item =>
              createNewsImageMutation.mutateAsync({
                newsId,
                image: item.image.file,
                author: item.author,
                description: item.description,
              })
            )
          );

          hasErrorOnCreateGallery = results.some(r => r.status === 'rejected');
        }

        // ? Notify the user what happened
        if (hasErrorOnCreateGallery)
          toast.warning(
            'Notícia cadastrada com sucesso, porém um erro ocorreu ao cadastrar uma ou mais imagens da galeria'
          );
        else toast.success('Notícia cadastrada com sucesso');

        // ? Get the updated data for highlights if necessary
        if (position) highlightsQuery.refetch();

        // ? Check and append the author if new one was created
        if (rest.author) {
          const authorExists = authorsQuery.data?.some(
            author => author.name === rest.author
          );

          if (!authorExists) authorsQuery.refetch();
        }

        // ? Reset form and general content
        editor.setContent('');
        setHasGallery(false);
        setIsHighlight(false);
        setImageDropzoneKey(Math.random());
        setIsSubmitSuccessful(true);
      } catch (error) {
        // ? Check if the api threw any validation error
        let apiValidationErrors: ApiValidationError[] = [];

        if (error.response?.data.type === 'validation-000')
          apiValidationErrors = error.response.data.errors;

        if (error.response?.data.field)
          apiValidationErrors.push(error.response.data);

        // ? Show the api validation errors if we received any of them
        if (apiValidationErrors.length > 0) {
          apiValidationErrors.forEach(({ field, message }, index) => {
            form.setError(field, { message }, { shouldFocus: index === 0 });
          });
          toast.error('Campo(s) inválido(s)');
          return;
        }

        // ? We received some unknown error, so notify the user
        toast.error('Um erro inesperado ocorreu. Por favor, tente novamente');
      }

      onClosePublicationModal();
    }
  );

  // ****** EFFECTS ******
  // ? Clear the form
  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ categoriesId: [], status: 'sketch' });
      setIsSubmitSuccessful(false);
    }
  }, [isSubmitSuccessful, reset]);

  // ****** HELPER FUNCTIONS ******
  // ? Max of 3 categories
  const isCategoryOptionDisabled = useCallback(() => {
    const categories = getValues('categoriesId');
    if (categories?.length === 3) return true;
    return false;
  }, [getValues]);

  const handleToggleGallery = useCallback(() => {
    setHasGallery(!hasGallery);

    if (!fields.length)
      append({ image: undefined, description: '', author: '' });
  }, [append, fields, hasGallery]);

  const handleRemoveGalleryItem = useCallback(
    (index: number) => {
      if (fields.length === 1) setHasGallery(false);
      remove(index);
    },
    [fields, remove]
  );

  const handleOpenPreview = useCallback(() => {
    const data = getValues();
    const editor = editorRef.current;

    if (!editor) return;

    if (!data.mainImage) {
      toast.info(
        'Não é possível pré-visualizar as informações sem a imagem principal'
      );
      return;
    }

    if (data.gallery?.some(item => !item.image)) {
      toast.info(
        'Anexe as imagens da galeria para pré-visualizar ou remova aquelas que não possuem imagem.'
      );
      return;
    }

    previewRef.current?.open({
      title: data.title,
      description: data.description,
      author: data.author || 'Jornal Local',
      categories: data.categoriesId.map(category => ({
        id: category.value,
        name: category.label,
      })),
      contentHtml: editor.getContent(),
      mainImage: {
        url: data.mainImage.preview,
        author: data.mainImageAuthor,
        description: data.mainImageDescription,
      },
      gallery: data.gallery.map((item, index) => ({
        id: index.toString(),
        url: item.image.preview,
        author: item.author,
        description: item.description,
      })),
    });
  }, [getValues]);

  // ****** TOO MUCH LOADING VARS, SO JOIN THEM :) ******
  const isLoading =
    categoriesQuery.isLoading ||
    periodicalsQuery.isLoading ||
    authorsQuery.isLoading ||
    isSubmitting;

  // ****** PRESENTATION ******
  return (
    <Container>
      <Helmet>
        <title>Cadastro de notícias</title>
      </Helmet>

      <Text as="h1" variant="heading-medium" mb="0.5rem">
        Cadastrar nova notícia
      </Text>

      <CreateNewsForm
        editorRef={editorRef}
        editorError={editorError}
        errors={errors}
        isValid={isValid}
        isLoading={isLoading}
        control={control}
        fields={fields}
        imageDropzoneKey={imageDropzoneKey}
        isHighlight={isHighlight}
        hasGallery={hasGallery}
        isPublicationModalOpen={isPublicationModalOpen}
        categories={categoriesQuery.data}
        periodicals={periodicalsQuery.data}
        authors={authorsQuery.data}
        highlightTypes={highlightTypesQuery.data}
        positions={highlightsQuery.data?.carousel}
        onSubmit={onSubmit}
        register={register}
        setValue={form.setValue}
        watch={form.watch}
        append={append}
        isCategoryOptionDisabled={isCategoryOptionDisabled}
        toggleHighlight={() => setIsHighlight(state => !state)}
        toggleGallery={handleToggleGallery}
        onClosePublicationModal={onClosePublicationModal}
        onOpenPublicationModal={onOpenPublicationModal}
        removeGalleryItem={handleRemoveGalleryItem}
        openPreview={handleOpenPreview}
      />

      <NewsPreview isCreateMode ref={previewRef} />
    </Container>
  );
}
