import { formatDate, formatDateDistanceToNowStrict } from 'lib';

type StringOrDate = string | Date;

const getAsDate = (date: StringOrDate) =>
  typeof date === 'string' ? new Date(date) : date;

export function formatLastNewsDate(
  publishedAt: StringOrDate,
  editedAt?: StringOrDate | null
) {
  if (!editedAt) return `Publicado em ${formatDate(getAsDate(publishedAt))}`;

  return `Atualizado ${formatDateDistanceToNowStrict(getAsDate(editedAt), {
    addSuffix: true,
  })}`;
}
