import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';

export const Container = styled.div<{ isOpen: boolean }>`
  display: flex;
  align-items: center;
  justify-content: flex-end;

  background-color: ${p =>
    p.isOpen ? color('primary-darker') : 'transparent'};

  max-width: ${p => (p.isOpen ? '19rem' : '3rem')};
  overflow: hidden;

  transition: max-width ${cvar('transition-quick')};

  &:focus-within {
    outline: 2px solid ${color('primary-light')};
    outline-offset: -2px;
  }

  input {
    width: min(100%, 16rem);
    padding: 0.75rem 1rem;
    background-color: transparent;
    border: none;
    color: ${color('white')};
    outline: 0;

    &::placeholder {
      color: ${color('placeholder')};
    }
  }

  button {
    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: ${color('overlay-light-15')};
    }
  }
`;
