/* eslint-disable no-param-reassign */
import { forwardRef, useCallback } from 'react';

import { Editor as ReactTinyMCEEditor } from '@tinymce/tinymce-react';

import { Container } from './styles';
import {
  EditorProps,
  InitInstanceCallbackFn,
  OnInitFn,
  OnNodeChangeFn,
  PastePreprocessArgs,
  RegisterImageAlignToggler,
  TinyMCEEditor,
} from './types';

const registerImageAlignToggler: RegisterImageAlignToggler = (
  editor,
  { name, add, remove, ...rest }
) => {
  editor.ui.registry.addToggleButton(name, {
    ...rest,
    onAction: () => {
      editor.execCommand('mceToggleFormat', false, add);
      remove.forEach(format => editor.formatter.remove(format));
    },
    onSetup: buttonApi => {
      buttonApi.setActive(editor.formatter.match(add));
      const change = editor.formatter.formatChanged(add, state => {
        buttonApi.setActive(state);
      });

      return () => {
        change.unbind();
      };
    },
  });
};

const Editor = forwardRef<TinyMCEEditor, EditorProps>(
  (
    {
      imageUploadHandler,
      initialValue,
      placeholder,
      containerClassName,
      containerStyle,
      removeMarginTop,
      error,
      ...rest
    },
    forwardedRef
  ) => {
    // ? Function executed on TinyMCE React instance initialize
    const onInit = useCallback<OnInitFn>(
      (_, editor) => {
        if (forwardedRef) {
          if (typeof forwardedRef === 'function') forwardedRef(editor);
          else forwardedRef.current = editor;
        }

        // ? Register image align toggler button
        registerImageAlignToggler(editor, {
          name: 'imageFull',
          text: '100%',
          tooltip: 'Tamanho da imagem em 100%',
          add: 'image_full',
          remove: ['image_medium', 'image_small', 'image_right'],
        });

        registerImageAlignToggler(editor, {
          name: 'imageMedium',
          text: '50%',
          tooltip: 'Tamanho da imagem em 50%',
          add: 'image_medium',
          remove: ['image_full', 'image_small'],
        });

        registerImageAlignToggler(editor, {
          name: 'imageSmall',
          text: '25%',
          tooltip: 'Tamanho da imagem em 25%',
          add: 'image_small',
          remove: ['image_full', 'image_medium'],
        });

        registerImageAlignToggler(editor, {
          name: 'imageRight',
          icon: 'image-right',
          tooltip: 'Alinhar a direita',
          add: 'image_right',
          remove: [],
        });

        // ? Register a custom button to open image properties
        editor.ui.registry.addButton('imageProperties', {
          icon: 'image-options',
          onAction: () => {
            editor.execCommand('mceImage');
          },
        });
      },
      [forwardedRef]
    );

    // ? Callback executed after editor is initialized (runs after `onInit`)
    const initInstanceCallback = useCallback<InitInstanceCallbackFn>(editor => {
      const content = editor.startContent;

      // ? Add the "media-aspect-ratio" class to root paragraphs of all frame objects
      const regex = /(?!<p)><span.+?data-mce-object="iframe".+?<\/p>/g;
      const replaceString = ' class="media-aspect-ratio"$&';
      const replaced = content.replaceAll(regex, replaceString);

      editor.setContent(replaced);
    }, []);

    // ? Function fired every time a node is changed in the editor.
    const onNodeChange = useCallback<OnNodeChangeFn>(event => {
      // ? Check if a new media was added (which means that it has the class below)
      if (event.element.className.includes('mce-preview-object')) {
        const iframe = Array.from(event.element.children).find(
          child => child.tagName.toLowerCase() === 'iframe'
        );

        // ? Guaranty that we have an inner iframe for the media
        if (!iframe) return;

        const parent = event.element.parentElement;
        parent?.classList.add('media-aspect-ratio');
        iframe.setAttribute('width', '100%');
        iframe.setAttribute('height', '100%');
      }
    }, []);

    // ? Function to remove and replace any unneeded tags and attributes
    const pastePreprocess = useCallback((_, args: PastePreprocessArgs) => {
      const content = args.content
        .replaceAll(/(class="(.+?)")|(style="(.+?)")/g, '')
        .replaceAll(/h1/g, 'h2')
        .replaceAll(/h5/g, 'h3')
        .replaceAll(/h6/g, 'h4')
        .replaceAll(/header|main|section|aside|footer/g, 'div');

      args.content = content;
    }, []);

    return (
      <Container
        className={containerClassName}
        style={containerStyle}
        hasError={!!error}
        removeMarginTop={removeMarginTop}
      >
        <ReactTinyMCEEditor
          apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
          initialValue={initialValue}
          onInit={onInit}
          onNodeChange={onNodeChange}
          init={{
            init_instance_callback: initInstanceCallback,
            plugins: [
              'anchor autolink autoresize emoticons image link lists',
              'media paste quickbars searchreplace table charmap wordcount',
            ],
            toolbar:
              'undo redo | formatselect | bold italic underline strikethrough ' +
              'subscript superscript | link blockquote | bullist numlist | ' +
              'removeformat | image media | searchreplace table emoticons charmap',

            //* General config
            placeholder,
            min_height: 400,
            language: 'pt_BR',
            icons: 'jornal-local',
            icons_url: '/tinymce/icons.js',
            content_css: '/tinymce/content.css',
            menubar: false,
            object_resizing: true,
            toolbar_sticky: true,
            elementpath: false,
            block_formats:
              'Parágrafo=p; Cabeçalho 2=h2; Cabeçalho 3=h3; Cabeçalho 4=h4',
            // ? Remove this elements to avoid unnecessary markup
            invalid_elements: 'div,header,main,section,aside,footer',
            valid_children: '-p[iframe]',

            //* File picker config
            automatic_uploads: false,
            images_upload_handler: imageUploadHandler,
            file_picker_types: 'image',

            //* Image config
            image_title: true,
            image_caption: true,
            image_description: true,
            image_dimensions: false,
            resize_img_proportional: true,

            //* Link config
            default_link_target: '_blank',
            link_assume_external_targets: 'http',
            link_context_toolbar: true,
            target_list: false, // ? I'm assuming that all link will open on another tab

            //* Media config
            media_alt_source: false,
            media_dimensions: false,
            media_poster: false,

            //* Paste config
            // ? Paste images is disabled by default (prefer using this as it is)
            paste_webkit_styles: 'all',
            paste_remove_styles_if_webkit: true,
            paste_preprocess: pastePreprocess,

            //* Quickbars config
            quickbars_insert_toolbar: false,
            quickbars_selection_toolbar:
              'bold italic | blockquote | formatselect',
            quickbars_image_toolbar:
              'imageFull imageMedium imageSmall | imageRight | imageProperties',

            //* Table config
            table_appearance_options: false,
            table_advtab: false,
            table_cell_advtab: false,
            table_row_advtab: false,
            table_sizing_mode: 'relative',
            table_header_type: 'sectionCells',

            //* Spellcheck (to use default browser context menu use `ctrl + right click`)
            browser_spellcheck: true,

            // ? Formats used to handle the image alignment
            formats: {
              image_full: {
                selector: 'img,figure',
                classes: ['image__full'],
                ceFalseOverride: true,
              },
              image_medium: {
                selector: 'img,figure',
                classes: ['image__medium'],
                ceFalseOverride: true,
              },
              image_small: {
                selector: 'img,figure',
                classes: ['image__small'],
                ceFalseOverride: true,
              },
              image_right: {
                selector: 'img,figure',
                classes: ['image__right'],
                ceFalseOverride: true,
              },
            },
            mobile: {
              contextmenu: false,
            },
          }}
          {...rest}
        />

        {error && <span>{error}</span>}
      </Container>
    );
  }
);

export default Editor;
