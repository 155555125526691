import { PulseLoader, Text } from 'components';

import { Container } from './styles';

type ContentLoadingProps = { label: string };

export default function ContentLoading({ label }: ContentLoadingProps) {
  return (
    <Container>
      <PulseLoader size="1.25rem" isLoading />
      <Text variant="heading-small" align="center">
        {label}
      </Text>
    </Container>
  );
}
