import {
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiCloseLine,
  RiErrorWarningLine,
  RiInformationLine,
} from 'react-icons/ri';

import { Button, Text } from 'components';

import { StyledAlertModal } from './styles';
import { ActionButtonColor, AlertColorMap, AlertModalProps } from './types';

StyledAlertModal.setAppElement('#root');

const variantIconMap = {
  default: RiInformationLine,
  primary: RiInformationLine,
  danger: RiCloseCircleLine,
  success: RiCheckboxCircleLine,
  warning: RiErrorWarningLine,
};

const colorMap: AlertColorMap = {
  default: 'neutral',
  primary: 'primary',
  danger: 'danger',
  success: 'success',
  warning: 'tertiary',
};

export default function AlertModal({
  isOpen,
  onRequestClose,
  variant = 'default',
  icon: CustomIcon,
  children,
  title,
  message,
  cancelButtonText,
  disabledCloseButtons,
  showCancelButton,
  actionButton: { text: actionButtonText, ...restActionButton },
  ...rest
}: AlertModalProps) {
  const Icon = CustomIcon || variantIconMap[variant];
  const colorVariant = colorMap[variant];

  return (
    <StyledAlertModal
      overlayClassName="ReactModal__Overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      variant={colorVariant}
      {...rest}
    >
      <Button
        icon={RiCloseLine}
        color="neutral"
        size="small"
        variant="ghost"
        className="close-modal"
        disabled={disabledCloseButtons}
        onClick={onRequestClose}
      />
      <Icon size="6rem" className="alert-icon" />

      <Text as="h2" variant="heading-small" color="title-active" mb="0.75rem">
        {title}
      </Text>

      {message && <Text>{message}</Text>}

      {children}

      <footer>
        {showCancelButton && (
          <Button
            size="small"
            variant="ghost"
            color="neutral"
            disabled={disabledCloseButtons}
            onClick={onRequestClose}
          >
            {cancelButtonText || 'Cancelar'}
          </Button>
        )}
        <Button
          size="small"
          color={colorVariant as ActionButtonColor}
          {...restActionButton}
        >
          {actionButtonText}
        </Button>
      </footer>
    </StyledAlertModal>
  );
}
