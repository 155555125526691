import { Helmet } from 'react-helmet';
import { useForm, SubmitHandler } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, InputText, Text } from 'components';

import { useAuth } from 'contexts/AuthContext';

import { ADMIN_NEWS } from 'routes/PATHS';
import { signInValidator } from 'shared/validators/auth';

import { Form } from './styles';

//* TYPES
type SignInFormData = {
  email: string;
  password: string;
};

//* COMPONENT
export default function SignIn() {
  const { signIn } = useAuth();
  const history = useHistory();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
  } = useForm<SignInFormData>({
    resolver: signInValidator,
    mode: 'all',
  });

  const onSubmit: SubmitHandler<SignInFormData> = async data => {
    try {
      await signIn(data);
      history.push(ADMIN_NEWS);
    } catch (error) {
      if (error.response?.data.message) {
        toast.error(error.response.data.message, { autoClose: 10000 });
        return;
      }
      toast.error('Um erro inesperado ocorreu. Por favor, tente novamente');
    }
  };

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <Helmet>
        <title>Acesso administrativo</title>
      </Helmet>

      <Text as="h1" color="title-active" variant="heading-x-small">
        Acessar a plataforma
      </Text>
      <InputText
        label="Email"
        type="email"
        autoComplete="email"
        placeholder="exemplo@email.com"
        readOnly={isSubmitting}
        error={errors.email?.message}
        {...register('email')}
      />
      <InputText
        label="Senha"
        type="password"
        autoComplete="current-password"
        readOnly={isSubmitting}
        error={errors.password?.message}
        {...register('password')}
      />
      <Button type="submit" disabled={!isValid} isLoading={isSubmitting}>
        Entrar
      </Button>
    </Form>
  );
}
