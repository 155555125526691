import { PulseLoaderContainer } from './styles';
import { PulseLoaderProps } from './types';

export default function PulseLoader({
  isLoading,
  size = '1rem',
  margin = '0.2rem',
  color = 'primary-default',
  ...rest
}: PulseLoaderProps) {
  return isLoading ? (
    <PulseLoaderContainer $color={color} size={size} margin={margin} {...rest}>
      <span />
      <span />
      <span />
    </PulseLoaderContainer>
  ) : null;
}
