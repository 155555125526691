import axios, { AxiosError } from 'axios';

import { ADMIN_AUTH_SIGN_IN, HOME } from 'routes/PATHS';

import { history } from './history';

//* TYPES
type FailedRequestsQueue = Array<{
  onSuccess: (token: string) => void;
  onFailure: (error: AxiosError) => void;
}>;

//* CONSTANTS
const ACCESS_TOKEN_KEY = '@jl:access-token';
const REFRESH_TOKEN_KEY = '@jl:refresh-token';

// ------------
const accessToken = localStorage.getItem(ACCESS_TOKEN_KEY);
let isRefreshing = false;
let failedRequestsQueue: FailedRequestsQueue = [];

//* API CLIENT
const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Authorization: `Bearer ${accessToken}`,
  },
});

//* INTERCEPTORS
api.interceptors.response.use(
  response => response,
  (error: AxiosError) => {
    if (error.response?.status === 401) {
      if (error.response.data.type === 'accessToken.expired') {
        // ? RENEW THE TOKEN
        const originalConfig = error.config;
        const refreshToken = localStorage.getItem(REFRESH_TOKEN_KEY);

        if (!isRefreshing) {
          isRefreshing = true;

          api
            .post('sessions/refresh_token', { refreshToken })
            .then(({ data: { accessToken: newAccessToken, ...rest } }) => {
              localStorage.setItem(ACCESS_TOKEN_KEY, newAccessToken);
              localStorage.setItem(REFRESH_TOKEN_KEY, rest.refreshToken);
              api.defaults.headers.Authorization = `Bearer ${newAccessToken}`;

              failedRequestsQueue.forEach(req => req.onSuccess(newAccessToken));
              failedRequestsQueue = [];
            })
            .catch(err => {
              failedRequestsQueue.forEach(req => req.onFailure(err));
              failedRequestsQueue = [];
            })
            .finally(() => {
              isRefreshing = false;
            });
        }

        return new Promise((resolve, reject) => {
          failedRequestsQueue.push({
            onSuccess: token => {
              originalConfig.headers.Authorization = `Bearer ${token}`;
              resolve(api(originalConfig));
            },
            onFailure: err => reject(err),
          });
        });
      }
      // ? SIGN OUT THE USER
      localStorage.removeItem(ACCESS_TOKEN_KEY);
      localStorage.removeItem(REFRESH_TOKEN_KEY);
      api.defaults.headers.Authorization = undefined;
      const wasInAdminPage = history.location.pathname.startsWith('/admin');
      const redirectTo = wasInAdminPage ? ADMIN_AUTH_SIGN_IN : HOME;
      history.push(redirectTo);
    }

    return Promise.reject(error);
  }
);

export default api;
