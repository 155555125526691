import styled from 'styled-components';

import { color, cvar, font } from 'utils/cvar';

export const Container = styled.div`
  ul {
    display: inline-grid;
    grid-auto-flow: column;
    gap: 0.5rem;
    list-style: none;
  }

  li {
    a {
      display: inline-grid;
      place-items: center;
      padding: 0.5rem 1rem;
      height: 2.75rem;
      user-select: none;
      border: 1px solid ${color('line')};
      font: ${font('body-normal-heavy')};
      transition: ${cvar('transition-quick')};

      &.icon-link {
        width: 2.75rem;
        padding: 0;
      }
    }

    &:not(.disabled):not(.selected) a {
      color: ${color('primary-default')};
      cursor: pointer;

      &:hover {
        background-color: ${color('primary-lighter')};
      }

      &:focus {
        outline: 2px solid ${color('primary-lighter')};
      }
    }

    &.selected a {
      border-color: ${color('primary-default')};
      background-color: ${color('primary-default')};
      color: ${color('white')};
    }

    &.disabled a {
      color: ${color('placeholder')};
    }
  }
`;
