//* ------------
//* Format api return to use as options for select
//* ------------
type Data = Array<{ name: string; id: string }>;

export const selectAsDropdownItem = (data: Data) =>
  data.map(item => ({ label: item.name, value: item.id }));

//* ------------
//* Select news highlights as positions options
//* ------------
type NewsHighlights = { carousel: unknown[] };

export const selectAsPositions = (data: NewsHighlights) => {
  const carousel = data.carousel.map((_, index) => ({
    label: `${index + 1}`,
    value: `${index}`,
  }));

  if (data.carousel.length < 10)
    carousel.push({
      label: `${data.carousel.length + 1}`,
      value: `${data.carousel.length}`,
    });

  return { carousel };
};

//* ------------
//* Select news highlights types as types options
//* ------------
type NewsHighlightType = { id: string; type: string };

export const selectAsTypes = (data: NewsHighlightType[]) =>
  data
    .filter(({ type }) => type !== 'periodical')
    .map(({ type, id: value }) => {
      let label: string;

      if (type === 'carousel') label = 'Carrossel';
      else if (type === 'mainNews') label = 'Destaque principal';
      else label = 'Destaque secundário';

      return { type, label, value };
    });
