import ReactModal from 'react-modal';

import styled from 'styled-components';

import { color, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const Container = styled.section`
  max-width: 72rem;
  margin: 0 auto 3rem;

  .no-data-container {
    display: grid;
    place-items: center;
    height: 30vh;
  }

  ${mediaQuery.max('74.999em')} {
    .data-table thead {
      display: none;
    }
  }
`;

export const Filters = styled.div`
  margin-bottom: 1.5rem;

  .date-range {
    &__inputs {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }

    &__picker {
      width: 100%;

      .react-datepicker-wrapper {
        margin-bottom: 0;
      }
    }

    &__dash {
      width: 0.5rem;
      height: 1px;
      background-color: ${color('placeholder')};
    }
  }

  ${mediaQuery.phoneOnly} {
    .date-range__label {
      display: none;
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    display: flex;
    justify-content: flex-end;

    .date-range {
      display: flex;
      align-items: center;

      &__label {
        color: ${color('label')};
        margin-right: 1rem;
      }

      &__picker {
        max-width: 12rem;
      }
    }
  }
`;

export const DataTableBody = styled.tbody`
  td {
    white-space: initial;
  }

  ${mediaQuery.max('74.999em')} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
    gap: 1rem;

    tr {
      background-color: ${color('white')};
      border: 1px solid ${color('line')};
      padding: 0.75rem;
      display: grid;
      grid-gap: 1.5rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: max-content auto max-content;
      grid-template-areas:
        'question     question'
        'votes        status'
        'start-date   end-date';

      td:nth-child(1) {
        grid-area: question;
        font: ${font('body-normal-heavy')};
      }

      td:nth-child(2) {
        grid-area: votes;
        align-self: end;

        &:after {
          content: ' votos';
        }
      }

      td:nth-child(3) {
        grid-area: start-date;
        align-self: center;
        position: relative;

        &:after {
          content: '';
          position: absolute;
          top: -0.75rem;
          left: 0;
          width: calc(200% + 1.5rem);
          height: 1px;
          background: ${color('line')};
        }
      }

      td:nth-child(4) {
        grid-area: end-date;
      }

      td:nth-child(5) {
        grid-area: status;
        align-self: end;
      }

      td[data-header] {
        &:before {
          content: attr(data-header);
          color: ${color('label')};
        }
      }
    }
  }
`;

export const DetailsTrigger = styled.button.attrs({
  type: 'button',
})`
  width: 100%;
  text-align: left;
  background: transparent;
  color: inherit;
  border: 0;
  outline: 0;

  &:hover {
    text-decoration: underline;
    color: ${color('secondary-dark')};
  }

  &:focus {
    outline: 1px solid ${color('secondary-default')};
    outline-offset: 0.25rem;
  }
`;

export const DetailsModal = styled(ReactModal)`
  position: absolute;
  top: 50%;
  left: 50%;
  width: min(100%, 50rem);
  max-height: calc(100vh - 4rem);
  transform: translate(-50%, -50%);

  background-color: ${color('white')};
  padding: 2rem 1.5rem 1.5rem;

  .close-modal {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }

  .about {
    text-align: center;
    margin: 0 auto 2rem;
    max-width: 38rem;

    h2 {
      font: ${font('heading-x-small')};
      margin-bottom: 0.5rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    padding: 1rem;
    overflow-y: auto;

    .about {
      margin: 0 2rem 2rem;
    }
  }
`;
