import { useCallback } from 'react';

import clsx from 'clsx';
import { Instance } from 'tippy.js';

import { DropdownItem } from './DropdownItem';
import { MenuContainer } from './styles';
import { DropdownProps } from './types';

import 'tippy.js/animations/shift-away.css';

const Dropdown = ({
  children,
  triggerElement,
  variant = 'normal',
  hideOnClickInnerButtons = true,
  className,
  ...rest
}: DropdownProps) => {
  const onShown = useCallback((instance: Instance) => {
    instance.popper.firstChild?.firstChild?.firstChild?.childNodes.forEach(
      child => {
        if (child.nodeName === 'BUTTON') {
          child.addEventListener('click', instance.hide);
        }
      }
    );
  }, []);

  const props = {
    ...rest,
  };

  if (hideOnClickInnerButtons) {
    props.onShown = instance => {
      onShown(instance);
      if (rest.onShown) rest.onShown(instance);
    };
  }

  return (
    <MenuContainer
      interactive
      placement="bottom"
      animation="shift-away"
      trigger="click"
      className={clsx(variant, className)}
      content={children}
      arrow={false}
      {...props}
    >
      {triggerElement}
    </MenuContainer>
  );
};

Dropdown.Item = DropdownItem;

export default Dropdown;
