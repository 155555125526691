import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  display: grid;
  gap: 1rem;
  margin-bottom: 1rem;

  ${mediaQuery.tabletPortraitUp} {
    grid-template-columns: repeat(2, 1fr);

    .search {
      grid-column: 1/3;
    }
  }

  ${mediaQuery.min('48em')} {
    --max-select-width: 14rem;
    grid-template-columns: 1fr repeat(2, minmax(13rem, var(--max-select-width)));

    .search {
      grid-column: 1/2;
      width: min(26rem, 100%);
    }
  }

  ${mediaQuery.tabletLandscapeUp} {
    --max-select-width: 16rem;
  }
`;
