import { useRef } from 'react';
import { Helmet } from 'react-helmet';
import { RiAddLine } from 'react-icons/ri';

import { Button, Text } from 'components';

import { ActivePoll } from './ActivePoll';
import { CreatePollModal, CreatePollModalRef } from './CreatePollModal';
import { Sketches } from './Sketches';
import { Container } from './styles';

export default function PollsInProgress() {
  const createPollModalRef = useRef<CreatePollModalRef>(null);

  return (
    <Container>
      <Helmet>
        <title>Enquete em progresso</title>
      </Helmet>

      <section>
        <div className="header">
          <Text as="h2" variant="heading-medium">
            Enquete em progresso
          </Text>

          <Button
            icon={RiAddLine}
            onClick={() => createPollModalRef.current?.open()}
          >
            Nova enquete
          </Button>
        </div>

        <ActivePoll />
      </section>

      <section>
        <div className="header">
          <Text as="h2" variant="heading-medium">
            Rascunhos
          </Text>
        </div>

        <Sketches />
      </section>

      <CreatePollModal ref={createPollModalRef} />
    </Container>
  );
}
