import { Tabs } from 'react-tabs';

import styled from 'styled-components';

import { color, font } from 'utils/cvar';

export const StyledTabs = styled(Tabs)`
  .react-tabs {
    -webkit-tap-highlight-color: transparent;

    /****** TAB LIST ******/
    &__tab-list {
      border-bottom: 1px solid ${color('placeholder')};
      margin: 0 0 1rem;
      padding: 0;
    }

    /****** TAB ******/
    &__tab {
      display: inline-block;
      border: 1px solid transparent;
      border-bottom: none;
      bottom: -1px;
      position: relative;
      list-style: none;
      padding: 0.375rem 1.5rem;
      height: 2.25rem;
      cursor: pointer;
      color: ${color('title-active')};

      &:focus {
        box-shadow: 0 0 0 2px ${color('primary-light')};
        border-color: ${color('primary-light')};
        outline: none;

        &:after {
          content: '';
          position: absolute;
          height: 5px;
          left: -4px;
          right: -4px;
          bottom: -5px;
          background: ${color('background')};
        }
      }

      &--selected {
        background: ${color('background')};
        border-color: ${color('placeholder')};
        font: ${font('body-normal-heavy')};
      }

      &--disabled {
        color: ${color('label')};
        cursor: default;
      }
    }

    /****** TAB PANEL ******/
    &__tab-panel {
      display: none;

      &--selected {
        display: block;
      }
    }
  }
`;
