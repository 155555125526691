import styled from 'styled-components';

import { color } from 'utils/cvar';

export const Container = styled.span`
  background-color: ${color('primary-darker')};

  position: absolute;
  top: 0;
  right: 0;
  pointer-events: none;

  display: grid;
  place-items: center;

  padding: 0.25rem 0.5rem;
  opacity: 0.8;
`;
