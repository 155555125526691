import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import { Link } from 'react-router-dom';

import {
  ButtonBack,
  ButtonNext,
  Image,
  Slide,
  Slider,
} from 'pure-react-carousel';

import { Text } from 'components';

import { placeholder } from 'assets';

import { useMediaQuery } from 'hooks';

import { NEWS } from 'routes/PATHS';
import { MQ_PHONE_ONLY } from 'utils/mediaQuery';

import CountInfo from './CountInfo';
import { StyledCarouselProvider, ErrorImageContainer } from './styles';

import 'pure-react-carousel/dist/react-carousel.es.css';

type CarouselProps = {
  items: Array<{
    id: string;
    slug: string;
    image: { url: string; alt: string };
    title: string;
    description: string;
  }>;
};

export default function Carousel({ items }: CarouselProps) {
  const isPhoneOnly = useMediaQuery(MQ_PHONE_ONLY);
  const isTouchDevice = useMediaQuery('(pointer: coarse)');

  return (
    <StyledCarouselProvider
      naturalSlideWidth={isPhoneOnly ? 11 : 16}
      naturalSlideHeight={9}
      totalSlides={items.length}
      dragEnabled={isTouchDevice}
      hasMasterSpinner
      infinite
      isPlaying
    >
      <Slider>
        {items.map((item, index) => (
          <Slide key={item.id} index={index}>
            <Link to={`${NEWS}/${item.slug}`} className="carousel__link">
              <Image
                src={item.image.url}
                alt={item.image.alt}
                hasMasterSpinner
                renderError={() => (
                  <ErrorImageContainer>
                    <img src={placeholder} alt={item.image.alt} />
                    <Text variant="body-small-heavy" color="danger-dark">
                      Erro ao carregar a imagem
                    </Text>
                  </ErrorImageContainer>
                )}
              />

              <div className="carousel__info">
                <Text as="h2" color="white" variant="heading-small">
                  {item.title}
                </Text>

                <Text color="input-background" mt="0.5rem">
                  {item.description}
                </Text>
              </div>
            </Link>
          </Slide>
        ))}
      </Slider>

      <ButtonBack>
        <RiArrowLeftSLine size="2.25rem" />
      </ButtonBack>

      <ButtonNext>
        <RiArrowRightSLine size="2.25rem" />
      </ButtonNext>

      <CountInfo />
    </StyledCarouselProvider>
  );
}
