import { Tab as ReactTab, TabProps as ReactTabProps } from 'react-tabs';

import clsx from 'clsx';

type TabProps = Omit<
  ReactTabProps,
  'ref' | 'disabledClassName' | 'selectedClassName'
>;

export const Tab = ({ children, className, ...rest }: TabProps) => (
  <ReactTab className={clsx('react-tabs__tab', className)} {...rest}>
    {children}
  </ReactTab>
);

Tab.tabsRole = 'Tab';
