import { forwardRef, useImperativeHandle, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ContentModal, Text } from 'components';

import { useToggle } from 'hooks';
import { useRepositionNewsHighlight } from 'hooks/api';

import { PositionsInputs } from './styles';

type News = { highlighId: string; initialPosition: number };
type RepositionModalRef = { open: (news: News) => void };
type RepositionModalProps = {
  availablePositions: number;
  onReposition?: (oldPosition: number, newPosition: number) => void;
};

const RepositionModal = forwardRef<RepositionModalRef, RepositionModalProps>(
  ({ availablePositions, onReposition }, ref) => {
    const [news, setNews] = useState<News>();
    const [isOpen, onToggle] = useToggle();

    const { register, handleSubmit, reset } = useForm<{ position: number }>();
    const { mutateAsync } = useRepositionNewsHighlight();

    useImperativeHandle(ref, () => ({
      open: data => {
        onToggle();
        setNews(data);
        reset({ position: data.initialPosition });
      },
    }));

    return (
      <ContentModal
        isOpen={isOpen}
        onRequestClose={onToggle}
        size="small"
        variant="primary"
        title="Alterar posição"
        showCancelButton
        actionButton={{
          text: 'Confirmar',
          onClick: handleSubmit(async data => {
            if (!news) return;

            const position = Number(data.position);

            if (news.initialPosition !== position) {
              await mutateAsync({ highlightId: news.highlighId, position });
              onReposition?.(news.initialPosition, position);
            }
            onToggle();
          }),
        }}
      >
        <PositionsInputs>
          {Array.from(Array(availablePositions).keys()).map(position => (
            <label key={position} htmlFor={`position-${position}`}>
              <input
                type="radio"
                id={`position-${position}`}
                defaultChecked={position === news?.initialPosition}
                value={position}
                {...register('position')}
              />

              <Text
                as="span"
                variant="heading-small"
                className="position-number"
              >
                {position + 1}
              </Text>
            </label>
          ))}
        </PositionsInputs>
      </ContentModal>
    );
  }
);

export type { RepositionModalRef };
export default RepositionModal;
