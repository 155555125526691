import { useState, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { RiAddLine, RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { AlertModal, Button, PulseLoader, Text } from 'components';

import { useToggle } from 'hooks';
import { useCategories, useSoftDeleteCategory } from 'hooks/api';

import {
  CreateCategoryModal,
  CreateCategoryModalRef,
} from './CreateCategoryModal';
import { Container } from './styles';

export default function ListCategories() {
  const createCategoryModalRef = useRef<CreateCategoryModalRef | null>(null);

  const [categoryToDelete, setCategoryToDelete] = useState(-1);
  const [isDeleteModalOpen, toggleDeleteModal] = useToggle();

  const { data: categories, isLoading } = useCategories({
    query: {
      withNewsCount: true,
      sort: 'created_at',
      order: 'DESC',
    },
  });
  const softDeleteMutation = useSoftDeleteCategory({
    onError: () => {
      toast.error('Um erro inesperado ocorreu. Por favor, tente novamente');
    },
    onSuccess: () => {
      toast.success('Categoria excluída com sucesso');
      toggleDeleteModal();
    },
  });

  return (
    <Container>
      <Helmet>
        <title>Categorias cadastradas</title>
      </Helmet>

      <div className="header">
        <Text as="h1" variant="heading-medium">
          Categorias cadastradas
        </Text>

        <Button
          icon={RiAddLine}
          onClick={() => createCategoryModalRef.current?.open()}
        >
          Nova categoria
        </Button>
      </div>

      {isLoading && (
        <div className="loading-container">
          <PulseLoader color="primary-default" isLoading={isLoading} />
        </div>
      )}

      {categories?.length === 0 && (
        <div className="no-data-container">
          <Text variant="heading-small">Não há nenhuma notícia cadastrada</Text>
        </div>
      )}

      {categories && categories?.length > 0 && (
        <>
          <div className="table-container">
            <table>
              <thead>
                <tr>
                  <th scope="col" className="actions">
                    Ações
                  </th>
                  <th scope="col">Nome</th>
                  <th scope="col">Notícias</th>
                </tr>
              </thead>
              <tbody>
                {categories.map((category, index) => (
                  <tr key={category.id}>
                    <td className="actions">
                      <Button
                        icon={RiEdit2Line}
                        variant="ghost"
                        size="small"
                        onClick={() =>
                          createCategoryModalRef.current?.open({
                            id: category.id,
                            name: category.name,
                          })
                        }
                      />
                      <Button
                        icon={RiDeleteBinLine}
                        color="danger"
                        variant="ghost"
                        size="small"
                        onClick={() => {
                          setCategoryToDelete(index);
                          toggleDeleteModal();
                        }}
                      />
                    </td>
                    <td title={category.name}>{category.name}</td>
                    <td>{category.news}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <AlertModal
            isOpen={isDeleteModalOpen}
            onRequestClose={toggleDeleteModal}
            variant="danger"
            title="Excluir categoria"
            disabledCloseButtons={softDeleteMutation.isLoading}
            shouldCloseOnOverlayClick={!softDeleteMutation.isLoading}
            showCancelButton
            actionButton={{
              text: 'Excluir',
              onClick: () =>
                softDeleteMutation.mutate(categories[categoryToDelete]?.id),
              isLoading: softDeleteMutation.isLoading,
            }}
          >
            <Text>
              Deseja excluir a categoria intitulada{' '}
              <strong>{categories[categoryToDelete]?.name}</strong>?
            </Text>

            <Text as="small" variant="body-small" color="label" mt="1rem">
              Ao excluir uma categoria, todas as notícias associadas a esta
              serão desassociadas.
            </Text>
          </AlertModal>
        </>
      )}

      <CreateCategoryModal ref={createCategoryModalRef} />
    </Container>
  );
}
