import { useRef, useState } from 'react';
import { RiDeleteBinLine, RiEdit2Line } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { AlertModal, Button, ContentLoading, Text } from 'components';

import { useToggle } from 'hooks';
import { useStartPoll, usePolls, useDeletePoll } from 'hooks/api';

import { UpdatePollModal, UpdatePollModalRef } from '../UpdatePollModal';
import { SketchesContainer } from './styles';

//* COMPONENT
export function Sketches() {
  const updatePollModalRef = useRef<UpdatePollModalRef>(null);

  const [selectedSketch, setSelectedSketch] = useState(-1);
  const [isStartModalOpen, toggleStartModal] = useToggle();
  const [isDeleteModalOpen, toggleDeleteModal] = useToggle();

  const startPoll = useStartPoll({
    onSuccess: () => {
      toggleStartModal();
      setSelectedSketch(-1);
      toast.success('Enquete iniciada com sucesso');
    },
  });

  const deletePoll = useDeletePoll({
    onSuccess: () => {
      toggleDeleteModal();
      setSelectedSketch(-1);
      toast.success('Enquete excluída com sucesso');
    },
  });

  const { data: sketches, isLoading } = usePolls({
    query: { status: 'sketch', limit: 50 },
  });

  function handleOpenStartModal(index: number) {
    setSelectedSketch(index);
    toggleStartModal();
  }

  function handleOpenDeleteModal(index: number) {
    setSelectedSketch(index);
    toggleDeleteModal();
  }

  function handleOpenEditModal(index: number) {
    if (!sketches || !updatePollModalRef.current) return;
    const { id, question, alternatives } = sketches.data[index];

    updatePollModalRef.current.open({
      id,
      question,
      alternatives: alternatives.map(alternative => ({
        id: alternative.id,
        title: alternative.title,
      })),
    });
  }

  if (isLoading) return <ContentLoading label="Carregando rascunhos" />;

  if (!sketches || sketches?.total === 0)
    return (
      <div className="no-data-container">
        <Text variant="heading-x-small">Nenhum rascunho cadastrado</Text>
      </div>
    );

  return (
    <>
      <SketchesContainer>
        {sketches.data.map((sketch, index) => (
          <div key={sketch.id} className="sketch-card">
            <div className="sketch-card__actions">
              <Button
                size="small"
                color="neutral"
                onClick={() => handleOpenStartModal(index)}
              >
                Ativar
              </Button>

              <Button
                icon={RiEdit2Line}
                size="small"
                color="secondary"
                onClick={() => handleOpenEditModal(index)}
              />

              <Button
                icon={RiDeleteBinLine}
                size="small"
                color="danger"
                onClick={() => handleOpenDeleteModal(index)}
              />
            </div>
            <div className="sketch-card__main-content">
              <h3>{sketch.question}</h3>
              <span>Alternativas:</span>
              <ul>
                {sketch.alternatives.map(({ id, title }) => (
                  <li key={id}>{title}</li>
                ))}
              </ul>
            </div>
          </div>
        ))}
      </SketchesContainer>

      {/* Modal to START a poll */}
      <AlertModal
        isOpen={isStartModalOpen}
        onRequestClose={toggleStartModal}
        variant="warning"
        title="Ativar enquete"
        disabledCloseButtons={startPoll.isLoading}
        shouldCloseOnOverlayClick={!startPoll.isLoading}
        showCancelButton
        actionButton={{
          text: 'Ativar',
          onClick: () => startPoll.mutate(sketches.data[selectedSketch]?.id),
          isLoading: startPoll.isLoading,
        }}
      >
        <Text>
          Deseja realmente ativar a enquete{' '}
          <strong>{sketches.data[selectedSketch]?.question}</strong>?
        </Text>

        <Text as="small" variant="body-small" color="label" mt="1rem">
          Se houver um enquete ativa no momento, esta será finalizada
          automaticamente.
        </Text>
      </AlertModal>

      {/* Modal to EDIT a poll */}
      <UpdatePollModal ref={updatePollModalRef} />

      {/* Modal to DELETE a poll */}
      <AlertModal
        isOpen={isDeleteModalOpen}
        onRequestClose={toggleDeleteModal}
        variant="danger"
        title="Excluir rascunho"
        disabledCloseButtons={deletePoll.isLoading}
        shouldCloseOnOverlayClick={!deletePoll.isLoading}
        showCancelButton
        actionButton={{
          text: 'Excluir',
          onClick: () => deletePoll.mutate(sketches.data[selectedSketch]?.id),
          isLoading: deletePoll.isLoading,
        }}
      >
        <Text>
          Deseja realmente excluir o enquete rascunho{' '}
          <strong>{sketches.data[selectedSketch]?.question}</strong>?
        </Text>
      </AlertModal>
    </>
  );
}
