import styled from 'styled-components';

import { color, cvar, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const FlexCenterAligned = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .page-range,
  .limit-label {
    font: ${font('body-small')};
    color: ${color('label')};
  }

  .limit-label {
    margin-right: 0.75rem;
  }
`;

export const Table = styled.table`
  width: 100%;
  border-spacing: 0;
  text-align: left;

  ${mediaQuery.desktopUp} {
    position: relative;
    border: 1px solid ${color('line')};

    tr {
      box-shadow: 0 1px ${color('line')};
    }

    th,
    td {
      padding: 0.25rem 0.75rem;
      height: 3.5rem;
      white-space: nowrap;
    }

    th {
      background: ${color('input-background')};
      font: ${font('body-normal-heavy')};
      position: sticky;
      top: 0;

      button {
        font: inherit;
        color: inherit;
        display: flex;
        align-items: center;
        background: transparent;
        border: 0;

        &:hover svg {
          color: ${color('body')};
        }

        svg {
          margin-left: 0.75rem;
          color: ${color('label')};
          transition: color ${cvar('transition-quick')};
        }
      }
    }
  }
`;

export const Footer = styled(FlexCenterAligned)`
  ${mediaQuery.phoneOnly} {
    flex-direction: column;

    > span {
      margin-bottom: 1rem;
    }
  }
`;
