import styled from 'styled-components';
import { sideContentLayout } from 'styles/layouts';

import { font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const LoadingContainer = styled.div`
  max-width: 85.375rem;
  margin: 1.5rem auto 6rem;
  padding: 0 1rem;

  display: grid;
  place-items: center;
  align-content: center;
  gap: 0.5rem;
  height: 40vh;

  ${mediaQuery.min('44em')} {
    padding: 0 1.5rem;
  }
`;

export const Container = styled.div`
  max-width: 85.375rem;
  margin: 1.5rem auto 6rem;

  ${sideContentLayout}
  grid-template-areas:
    'carousel'
    'news'
    'side-content';

  .container {
    &__carousel {
      grid-area: carousel;
    }

    &__news {
      grid-area: news;
    }

    &__side-content {
      grid-area: side-content;
    }
  }

  ${mediaQuery.max('43.99em')} {
    --layout-padding: 0;

    .container {
      &__news,
      &__side-content {
        padding: 0 1rem;
      }
    }
  }

  ${mediaQuery.min('44em')} {
    grid-template-areas:
      'carousel carousel'
      'news     side-content';
  }

  ${mediaQuery.tabletLandscapeUp} {
    grid-template-areas:
      'carousel side-content'
      'news     side-content';
  }
`;

export const NewsSection = styled.section`
  --items-gap: 1rem;

  > * + * {
    margin-top: calc(var(--items-gap) * 3);
  }

  .news {
    &__grid {
      display: grid;
      gap: var(--items-gap);

      .card {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: auto 1fr;

        &__title {
          margin-bottom: 1rem;
        }
      }
    }

    &__text-cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
      gap: var(--items-gap);
    }

    &__highlight {
      &--multi {
        display: grid;
        gap: 1.5rem;
      }
    }
  }

  .section-title {
    font: ${font('heading-medium')};
    grid-column: 1/-1;
    margin-bottom: var(--items-gap);
  }

  ${mediaQuery.between('37.5em', '56.24em')} {
    .news__grid .card {
      --card-img-aspect-ratio: 4/3;
      display: grid;
      grid-template-columns: calc(36.5% - (var(--card-padding) / 2)) auto;
    }
  }

  ${mediaQuery.tabletLandscapeUp} {
    .news {
      &__grid {
        grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
      }

      &__highlight--single .card {
        --card-img-aspect-ratio: 2/1;
        display: grid;
        grid-template-columns: calc(50% - (var(--card-padding) / 2)) auto;
      }

      &__highlight--multi {
        grid-template-columns: repeat(2, 1fr);

        .card {
          display: grid;
          grid-template-columns: 1fr;
          grid-template-rows: auto 1fr;
        }
      }
    }
  }

  ${mediaQuery.desktopUp} {
    .news {
      &__highlight--multi {
        grid-template-columns: repeat(2, 1fr);

        .card {
          grid-template-columns: 1fr;
          grid-template-rows: auto 1fr;
        }
      }
    }
  }
`;
