/* eslint-disable @typescript-eslint/no-explicit-any */
import { createContext, useCallback, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';

import { Button, NewsPreview, PulseLoader, Tabs, Text } from 'components';
import { NewsPreviewRef } from 'components/modal/NewsPreview/types';

import {
  useAuthors,
  useCategories,
  useNewsByIdOrSlug,
  usePeriodicals,
} from 'hooks/api';

import { GalleryFormSection } from './GalleryFormSection';
import { GeneralFormSection } from './GeneralFormSection';
import { Container, LoadingContainer } from './styles';
import {
  Params,
  UpdateNewsContextData,
  RegisterGetter,
  GeneralInfoFormGetter,
  OldGalleryFormGetters,
  UnregisterGetter,
  NewGalleryFormGetter,
  GalleryData,
} from './types';

//* CONTEXT
export const UpdateNewsContext = createContext<UpdateNewsContextData>(
  {} as UpdateNewsContextData
);
// ****** COMPONENT ******
export default function UpdateNews() {
  const previewRef = useRef<NewsPreviewRef>(null);
  const generalFormGetter = useRef<GeneralInfoFormGetter>();
  const oldGalleryFormGetters = useRef<OldGalleryFormGetters>({});
  const newGalleryFormGetter = useRef<NewGalleryFormGetter>();

  const { id } = useParams<Params>();

  // ****** QUERIES ******
  const { data: news, refetch, isLoading } = useNewsByIdOrSlug(id);
  // ? Load data needed in the forms inside
  const { isLoading: isCategoriesLoading } = useCategories();
  const { isLoading: isPeriodicalsLoading } = usePeriodicals();
  const { isLoading: isAuthorsLoading } = useAuthors();

  // ****** CONTEXT DATA ******
  const registerGetter = useCallback<RegisterGetter>((type, getter, key) => {
    if (type === 'general') generalFormGetter.current = getter as any;
    else if (type === 'gallery_new')
      newGalleryFormGetter.current = getter as any;
    else if (key) oldGalleryFormGetters.current[key] = getter as any;
  }, []);

  const unregisterGetter = useCallback<UnregisterGetter>((type, key) => {
    if (type === 'general') generalFormGetter.current = undefined;
    else if (type === 'gallery_new') newGalleryFormGetter.current = undefined;
    else if (key) {
      const oldGalleryGetters = oldGalleryFormGetters.current;
      const filteredGetters = Object.entries(oldGalleryGetters).filter(
        ([getterKey]) => getterKey !== key
      );
      oldGalleryFormGetters.current = filteredGetters as any;
    }
  }, []);

  // ****** OPEN PREVIEW ******
  const handleOpenPreview = () => {
    if (!generalFormGetter.current) return;

    let galleryData: GalleryData;
    const generalData = generalFormGetter.current();

    galleryData = Object.values(oldGalleryFormGetters.current).map(getter =>
      getter()
    );

    const newGalleryData = newGalleryFormGetter.current?.().gallery;

    if (newGalleryData) galleryData = galleryData.concat(newGalleryData);

    if (galleryData.some(item => !item.image)) {
      toast.info(
        'Anexe as imagens da galeria para pré-visualizar ou remova aquelas que não possuem imagem.'
      );
      return;
    }

    const gallery = galleryData.map(
      ({ image, id: databaseId, ...rest }, index) => ({
        id: databaseId || index.toString(),
        url: image.preview,
        ...rest,
      })
    );

    previewRef.current?.open({
      publicationDate: news?.publicationDate
        ? new Date(news?.publicationDate)
        : undefined,
      updatedAt: news?.publicationDate ? new Date(news?.updatedAt) : undefined,
      title: generalData.title,
      description: generalData.description,
      author: generalData.author || 'Jornal Local',
      categories: generalData.categoriesId.map(category => ({
        id: category.value,
        name: category.label,
      })),
      contentHtml: generalData.contentHtml,
      mainImage: {
        url: generalData.mainImage.preview,
        author: generalData.mainImageAuthor,
        description: generalData.mainImageDescription,
      },
      gallery,
    });
  };

  const isLoadingData =
    isLoading ||
    isCategoriesLoading ||
    isPeriodicalsLoading ||
    isAuthorsLoading;

  // ****** PRESENTATION ******
  // ? GETTING NECESSARY DATA
  if (isLoadingData)
    return (
      <LoadingContainer>
        <PulseLoader color="primary-default" isLoading={isLoadingData} />
        <Text variant="heading-x-small" align="center">
          Estamos carregando os dados necessários
        </Text>
      </LoadingContainer>
    );

  if (!news)
    return (
      <LoadingContainer>
        <Text variant="heading-x-small" align="center">
          Não foi possível carregar a notícia informada
        </Text>
      </LoadingContainer>
    );

  return (
    <UpdateNewsContext.Provider
      value={{
        registerGetter,
        unregisterGetter,
        refetchNews: refetch,
        news,
      }}
    >
      <Helmet>
        <title>Atualizar notícia</title>
      </Helmet>

      <Container>
        <header className="page-header">
          <Text as="h1" variant="heading-medium">
            Atualizar notícia
          </Text>

          <Button
            color="secondary"
            onClick={handleOpenPreview}
            className="preview-trigger"
          >
            Pré-visualizar
          </Button>
        </header>

        <Tabs>
          <Tabs.TabList>
            <Tabs.Tab>Notícia</Tabs.Tab>
            <Tabs.Tab>Galeria</Tabs.Tab>
          </Tabs.TabList>

          <Tabs.TabPanel>
            <GeneralFormSection />
          </Tabs.TabPanel>
          <Tabs.TabPanel>
            <GalleryFormSection />
          </Tabs.TabPanel>
        </Tabs>
      </Container>

      <NewsPreview isCreateMode ref={previewRef} />
    </UpdateNewsContext.Provider>
  );
}
