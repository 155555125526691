import { useEffect, useRef } from 'react';

const SCRIPT_ID = 'trading-script';

export default function TradingWidget() {
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = SCRIPT_ID;
    script.src =
      'https://s3.tradingview.com/external-embedding/embed-widget-mini-symbol-overview.js';
    script.async = true;
    script.innerHTML = JSON.stringify({
      symbol: 'FX_IDC:USDBRL',
      width: '100%',
      height: '100%',
      locale: 'br',
      dateRange: '12M',
      colorTheme: 'light',
      trendLineColor: 'rgba(7, 19, 77, 1)',
      underLineColor: 'rgba(163, 172, 215, 0.6)',
      underLineBottomColor: 'rgba(163, 172, 215, 0)',
      isTransparent: false,
      autosize: false,
      largeChartUrl: '',
    });

    containerRef.current?.appendChild(script);

    return () => {
      document.getElementById(SCRIPT_ID)?.remove();
    };
  }, []);

  return (
    <>
      <div
        className="tradingview-widget-container"
        style={{ maxHeight: '22rem' }}
      >
        <div
          className="tradingview-widget-container__widget"
          ref={containerRef}
        />
        <div className="tradingview-widget-copyright">
          <a
            href="https://br.tradingview.com/symbols/USDBRL/?exchange=FX_IDC"
            rel="noopener noreferrer"
            target="_blank"
          >
            <span className="blue-text">USDBRL Cotação</span>
          </a>{' '}
          por TradingView
        </div>
      </div>
    </>
  );
}
