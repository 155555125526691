import styled, { css } from 'styled-components';
import { fixMarginCollapsing } from 'styles/shared';

import { color, cvar, font } from 'utils/cvar';

import { ContainerProps, DropzoneContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  ${fixMarginCollapsing}

  ${p =>
    !p.removeMarginTop &&
    css`
      margin-top: 1rem;
    `}

  div.label {
    display: grid;
    grid-auto-flow: column;
    align-items: center;
    margin-bottom: 0.5rem;
    color: ${color('label')};

    span {
      font: ${font('body-small')};
      justify-self: end;
    }
  }

  > span {
    font: ${font('body-small')};
    display: inline-block;

    &.error {
      color: ${color('danger-default')};
    }

    &.help {
      color: ${color('label')};
    }
  }

  ${p =>
    p.isDisabled &&
    css`
      * {
        cursor: not-allowed;
      }
    `}
`;

export const DropzoneContainer = styled.div<DropzoneContainerProps>`
  outline: 0;
  padding: 0.25rem;
  height: 12rem;
  background-color: ${color('input-background')};
  border: 1px solid ${color('line')};
  margin-bottom: ${p => (p.hasError || p.hasHelpText ? '0.5rem' : '2rem')};
  overflow: hidden;

  transition-duration: ${cvar('transition-quick')};
  transition-property: background, color, border, box-shadow;

  display: flex;
  justify-content: center;
  align-items: center;

  ${p =>
    (p.isDisabled || p.isReadOnly) &&
    css`
      background-color: ${color('line')};
      opacity: 0.8;
    `}

  ${p =>
    p.isDisabled &&
    css`
      cursor: not-allowed;
    `}

  ${p =>
    !p.isDisabled &&
    !p.isReadOnly &&
    css`
      cursor: pointer;

      &:active,
      &:hover {
        border-color: ${color('primary-light')};

        svg {
          color: ${color('secondary-default')};
        }
      }
    `}

  &.drag-reject {
    border-color: ${color('danger-default')};
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  ${p =>
    p.isFocused &&
    css`
      box-shadow: 0 0 0 4px ${color('primary-lighter')};
      border-color: ${color('primary-light')};
    `}

  ${p =>
    p.hasError &&
    css`
      border-color: ${color('danger-default')};
      background-color: ${color('danger-lightest')};
    `}
`;
