import { ReactNode } from 'react';

import { useCan } from 'hooks';

type CanProps = {
  children: ReactNode;
  roles: string[];
};

export default function Can({ children, roles }: CanProps) {
  const userCanSeeComponent = useCan({ roles });

  if (!userCanSeeComponent) return null;

  return <>{children}</>;
}
