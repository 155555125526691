import { useEffect, SetStateAction, Dispatch } from 'react';
import { useForm, WatchObserver } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import { debounce } from 'lodash';

import { InputText, Select } from 'components';

import { useQueryParams } from 'hooks';
import { useCategories } from 'hooks/api';

import { buildQueryString } from 'utils/buildQueryString';

import { Filters as FiltersType, Params, QueryParams } from '../types';
import { FiltersContainer } from './styles';

type FiltersProps = {
  filters: FiltersType;
  onChangeFilters: Dispatch<SetStateAction<FiltersType>>;
};

//* FORMAT API RETURN TO USE AS OPTIONS FOR SELECT
const selectFn = (data: Array<{ name: string; slug: string }>) =>
  data.map(category => ({
    label: category.name,
    value: category.slug,
  }));

export default function Filters({ filters, onChangeFilters }: FiltersProps) {
  const { search: queryParams } = useLocation();
  const { search } = useParams<Params>();
  const query = useQueryParams<QueryParams>(queryParams);
  const history = useHistory();

  // ****** Queries ******
  const { data: categories } = useCategories({
    query: { withNews: true },
    select: selectFn,
  });

  const { register, watch, setValue } = useForm<{ search: string }>();

  // ****** Update filters on url changes ******
  useEffect(() => {
    setValue('search', search);
    onChangeFilters({ page: 1, search, category: query?.categoria || '' });
  }, [search, query, onChangeFilters, setValue]);

  // ? Observe search input value to update filters
  useEffect(() => {
    const observer: WatchObserver<{ search: string }> = (data, { name }) => {
      if (!name || name !== 'search') return;

      const value = data.search;
      onChangeFilters(values => ({ ...values, page: 1, search: value.trim() }));
      history.replace((value || '%20') + queryParams);
    };

    const subscription = watch(debounce(observer, 1000));
    return () => subscription.unsubscribe();
  }, [history, onChangeFilters, queryParams, watch]);

  // ? Update filters on change select
  function handleChangeSelect(key: 'category', value?: string) {
    const uiKey = key === 'category' ? 'categoria' : 'coluna';
    const newQueryParams = buildQueryString({ ...query, [uiKey]: value });

    onChangeFilters({ ...filters, page: 1, [key]: value });
    history.replace(filters.search + newQueryParams);
  }

  return (
    <FiltersContainer>
      <InputText
        containerClassName="input-text"
        label="Pesquisar"
        placeholder="Digite um termo a ser pesquisado"
        removeMarginTop
        removeErrorSlotMargin
        {...register('search')}
      />

      <Select
        containerClassName="select"
        label="Categoria"
        name="category"
        options={categories}
        value={categories?.filter(
          category => category.value === query.categoria
        )}
        isClearable
        removeMarginTop
        removeErrorSlotMargin
        onChange={option => handleChangeSelect('category', option?.value)}
      />
    </FiltersContainer>
  );
}
