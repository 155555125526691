import { cssValue } from './unitConverter';

export const MQ_PHONE_ONLY = '(max-width: 37.49em)';
export const MQ_TABLET_PORTRAIT_UP = '(min-width: 37.5em)';
export const MQ_TABLET_LANDSCAPE_UP = '(min-width: 56.25em)';
export const MQ_DESKTOP_UP = '(min-width: 75em)';
export const MQ_BIG_DESKTOP_UP = '(min-width: 112.5em)';

export default {
  phoneOnly: `@media ${MQ_PHONE_ONLY}`, // ? ~599px
  tabletPortraitUp: `@media ${MQ_TABLET_PORTRAIT_UP}`, // ? 600px
  tabletLandscapeUp: `@media ${MQ_TABLET_LANDSCAPE_UP}`, // ? 900px
  desktopUp: `@media ${MQ_DESKTOP_UP}`, // ? 1200px
  bigDesktopUp: `@media ${MQ_BIG_DESKTOP_UP}`, // ? 1800px

  /**
   * If `number` is passed, defaults to `px` values
   *
   * @param size min-width
   * @returns string
   */
  min: (size: number | string) => `@media (min-width: ${cssValue(size)})`,

  /**
   * If `number` is passed, defaults to `px` values
   *
   * @param size min-width
   * @returns string
   */
  max: (size: number | string) => `@media (max-width: ${cssValue(size)})`,

  /**
   * If `number` is passed, defaults to `px` values
   *
   * @param min min-width
   * @param max max-width
   * @returns string
   */
  between: (min: number | string, max: number | string) =>
    `@media (min-width: ${cssValue(min)}) and (max-width: ${cssValue(max)})`,
};
