import { RiCloseCircleLine, RiUploadCloud2Line } from 'react-icons/ri';

import { StyledParagraph } from './styles';
import { MessageProps } from './types';

export default function Message({
  multiple,
  isDragActive,
  isDragReject,
}: MessageProps) {
  const s = multiple && 's';

  if (isDragReject)
    return (
      <StyledParagraph className="drag-reject">
        <RiCloseCircleLine size="3rem" />
        Arquivo{s} inválido{s}
      </StyledParagraph>
    );

  if (isDragActive)
    return (
      <StyledParagraph className="drag-active">
        <RiUploadCloud2Line size="3rem" />
        Solte o{s} arquivo{s} aqui...
      </StyledParagraph>
    );

  return (
    <StyledParagraph>
      <RiUploadCloud2Line size="3rem" />
      Arraste e solte o{s} arquivo{s} aqui ou{' '}
      <span>pesquise no computador</span>
    </StyledParagraph>
  );
}
