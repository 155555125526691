import styled from 'styled-components';

import { color, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const Container = styled.section`
  max-width: 72rem;
  margin: 0 auto 3rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
  }

  .loading-container {
    height: 50vh;
    display: grid;
    place-items: center;
  }

  .no-data-container {
    display: grid;
    place-items: center;
    height: 30vh;
  }

  .table-container {
    overflow-x: auto;
  }

  table {
    width: 100%;
    border-spacing: 0;
    position: relative;
    border: 1px solid ${color('line')};
    text-align: left;

    th,
    td {
      padding: 0.25rem 0.75rem;
      height: 3.5rem;
      white-space: nowrap;
      box-shadow: 0 1px ${color('line')};
    }

    th {
      background: ${color('input-background')};
      font: ${font('body-normal-heavy')};
      position: sticky;
      top: 0;
    }

    th:nth-child(3) {
      width: 10rem;
    }

    th.actions {
      width: 6.5rem;
      color: transparent;
    }

    td.actions button + button {
      margin-left: 0.5rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    .header {
      flex-direction: column;
      align-items: flex-start;

      button {
        margin-top: 1.5rem;
      }
    }
  }
`;
