import { useMutation, useQuery } from 'react-query';

import api from 'services/api';
import { queryClient } from 'services/queryClient';

import { buildQueryString } from 'utils/buildQueryString';

import {
  Category,
  CreateData,
  GetCategoriesQuery,
  MutateResponseData,
  UpdateData,
  UseCategoriesProps,
  UseCreateCategoryProps,
  UseSoftDeleteCategoryProps,
  UseUpdateCategoryProps,
} from './types';

const QUERY_KEY = 'categories';

// ********************************************************
// ***                     LIST ALL                     ***
// ********************************************************
const getCategories = async (query: GetCategoriesQuery = {}) => {
  const uri = buildQueryString(query, 'categories');

  const { data } = await api.get<Category[]>(uri);
  return data;
};

export function useCategories<T = Category[]>({
  query,
  ...options
}: UseCategoriesProps<T> = {}) {
  const key = query ? [QUERY_KEY, query] : QUERY_KEY;

  return useQuery(key, () => getCategories(query), {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 30, // ? 30 minutes,
    ...options,
  });
}

// ********************************************************
// ***                      CREATE                      ***
// ********************************************************
const createCategory = async ({ name }: CreateData) => {
  const { data } = await api.post<MutateResponseData>('categories', { name });
  return data;
};

export function useCreateCategory({
  onSuccess,
  ...options
}: UseCreateCategoryProps = {}) {
  return useMutation(createCategory, {
    onSuccess: (data, ...rest) => {
      queryClient.setQueriesData<Category[]>(
        {
          queryKey: QUERY_KEY,
          predicate: ({ queryHash }) => !queryHash.includes('withDeleted'),
        },
        oldData => {
          const newCategory = { ...data, news: 0 };
          return oldData ? [newCategory, ...oldData] : [newCategory];
        }
      );
      onSuccess?.(data, ...rest);
    },
    ...options,
  });
}

// ********************************************************
// ***                      UPDATE                      ***
// ********************************************************
const updateCategory = async ({ id, name }: UpdateData) => {
  const resource = `categories/${id}`;
  const { data } = await api.put<MutateResponseData>(resource, { name });
  return data;
};

export function useUpdateCategory({
  onSuccess,
  ...options
}: UseUpdateCategoryProps = {}) {
  return useMutation(updateCategory, {
    onSuccess: (data, variables, context) => {
      queryClient.setQueriesData<Category[]>(QUERY_KEY, oldData => {
        const newData = oldData?.map(category => {
          if (category.id === variables.id) return { ...category, ...data };
          return category;
        });
        return newData ?? [];
      });

      onSuccess?.(data, variables, context);
    },
    ...options,
  });
}

// *******************************************************
// ***                   SOFT DELETE                   ***
// *******************************************************
const softDeleteCategory = async (id: string) => {
  await api.patch(`categories/${id}/soft_delete`);
};

export function useSoftDeleteCategory({
  onSuccess,
  ...options
}: UseSoftDeleteCategoryProps = {}) {
  return useMutation(softDeleteCategory, {
    onSuccess: (data, categoryId, context) => {
      queryClient.setQueriesData<Category[]>(QUERY_KEY, oldData => {
        const filteredCategories = oldData?.filter(
          category => category.id !== categoryId
        );
        return filteredCategories ?? [];
      });

      onSuccess?.(data, categoryId, context);
    },
    ...options,
  });
}
