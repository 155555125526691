import ReactModal from 'react-modal';

import styled from 'styled-components';
import { contentFromEditorStyles } from 'styles/shared';

import { color } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const PreviewModal = styled(ReactModal).attrs({
  overlayClassName: 'ReactModal__Overlay',
})`
  position: absolute;
  top: 50%;
  left: 50%;
  width: min(100%, 65.5rem);
  max-height: calc(100vh - 4rem);
  transform: translate(-50%, -50%);
  overflow: auto;

  background-color: ${color('white')};
  border: 1px solid ${color('line')};
  outline: none;

  --z-index-close-btn: 10;

  .close-modal {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    z-index: var(--z-index-close-btn);
  }

  main {
    padding: 0 1.5rem 2rem;

    &.create-mode {
      padding-top: 1rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    max-height: calc(100vh - 10rem);

    main {
      padding: 0 1rem 2rem;
    }
  }
`;

export const Actions = styled.header`
  padding: 1rem 1.5rem;
  position: sticky;
  top: -1px;
  z-index: calc(var(--z-index-close-btn) - 1);
  background: ${color('white')};
  border-bottom: 1px solid ${color('line')};

  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  ${mediaQuery.phoneOnly} {
    padding: 1rem;
  }
`;

export const AboutSection = styled.section`
  display: grid;
  justify-items: flex-start;
  gap: 1.25rem;
  margin-top: 1rem;
  margin-bottom: 2rem;

  .categories span + span {
    margin-left: 0.5rem;
  }

  .author-date {
    margin-top: 0.75rem;
  }
`;

export const MainImage = styled.figure`
  display: block;

  .ratio-box {
    position: relative;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc((9 / 16) * 100%);
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`;

export const ContentSection = styled.section`
  max-width: 40.5rem;
  margin: 3rem auto 0;

  ${contentFromEditorStyles}
`;

export const Gallery = styled.div`
  max-width: 40.5rem;
  margin: 3rem auto 4rem;
`;
