import { yupResolver } from '@hookform/resolvers/yup';

import { yup } from 'lib';

export const signInValidator = yupResolver(
  yup.object().shape({
    email: yup
      .string()
      .email('Deve ser um e-mail válido')
      .required()
      .label('Email'),
    password: yup.string().required().label('Senha'),
  })
);
