import { useContext, useEffect, useState } from 'react';

import { CarouselContext } from 'pure-react-carousel';

import { Text } from 'components';

import { Container } from './styles';

type SlidesState = {
  total: number;
  current: number;
};

export default function Navigation() {
  const carouselContext = useContext(CarouselContext);
  const [slides, setSlides] = useState<SlidesState>({
    current: carouselContext.state.currentSlide,
    total: carouselContext.state.totalSlides,
  });

  useEffect(() => {
    function onChange() {
      setSlides({
        current: carouselContext.state.currentSlide,
        total: carouselContext.state.totalSlides,
      });
    }
    carouselContext.subscribe(onChange);
    return () => carouselContext.unsubscribe(onChange);
  }, [carouselContext]);

  return (
    <Container>
      <Text variant="body-normal-heavy" color="white">
        {slides.current + 1} / {slides.total}
      </Text>
    </Container>
  );
}
