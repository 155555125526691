/* eslint-disable import/no-duplicates */
import { format as dateFnsFormat } from 'date-fns';
import { ptBR } from 'date-fns/locale';

type Options = Omit<
  NonNullable<Parameters<typeof dateFnsFormat>['2']>,
  'locale'
>;

export default function formatDate(
  date: number | Date,
  format = 'P p',
  options?: Options
) {
  return dateFnsFormat(date, format, {
    locale: ptBR,
    ...options,
  });
}
