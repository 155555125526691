import styled, { css } from 'styled-components';
import { fixMarginCollapsing } from 'styles/shared';

import { color, cvar, font } from 'utils/cvar';

import { ContainerProps } from './types';

const getMarginBottom = (props: ContainerProps) =>
  props.hasError || props.removeErrorSlotMargin ? '0.5rem' : '2rem';

export const Container = styled.div<ContainerProps>`
  ${fixMarginCollapsing}

  ${p =>
    !p.removeMarginTop &&
    css`
      margin-top: 1rem;
    `}


  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: ${color('label')};
  }

  .select-container {
    margin-bottom: ${getMarginBottom};
    color: ${color('body')};
  }

  .react-select {
    &__control {
      cursor: text;
      width: 100%;
      min-height: 3rem;
      border: 1px solid ${color('line')};
      background-color: ${color('input-background')};
      transition: all ${cvar('transition-quick')};

      &--is-focused {
        box-shadow: 0 0 0 4px ${color('primary-lighter')};
        border-color: ${color('primary-light')};

        .react-select__dropdown-indicator,
        .react-select__clear-indicator {
          color: ${color('label')};
        }
      }

      &--is-disabled {
        background-color: ${color('line')};
        opacity: 0.8;
      }

      &:hover {
        border-color: ${color('primary-light')};
      }
    }

    &__dropdown-indicator,
    &__clear-indicator {
      cursor: pointer;
      color: ${color('placeholder')};

      &:hover {
        color: ${color('body')};
      }
    }

    &__menu {
      border: 1px solid ${color('line')};
      box-shadow: ${cvar('elevation-x-large')};

      &-notice {
        padding: 0.75rem 1rem;
        color: ${color('label')};
      }
    }

    &__option {
      padding: 0.75rem 1rem;

      &:not(&--is-disabled) {
        cursor: pointer;
      }

      &--is-focused {
        background-color: ${color('primary-lightest')};
      }

      &--is-selected {
        background: ${color('primary-light')};
        color: ${color('title-active')};
      }

      &:active:not(&--is-disabled) {
        background: ${color('primary-lighter')};
      }
    }

    &__value-container {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    &__multi-value {
      background-color: ${color('primary-lighter')};

      &--is-disabled {
        background-color: ${color('input-background')};

        .react-select__multi-value {
          &__label {
            padding-right: 6px;
          }
          &__remove {
            display: none;
          }
        }
      }

      &__label {
        font: ${font('body-small')};
        color: ${color('body')};
      }

      &__remove {
        cursor: pointer;

        &:hover {
          background-color: ${color('danger-lighter')};
          color: ${color('danger-default')};
        }
      }
    }

    &__placeholder {
      color: ${color('placeholder')};
    }

    &__single-value {
      color: ${color('body')};
    }
  }

  ${p =>
    p.hasError &&
    css`
      .react-select {
        &__control {
          background-color: ${color('danger-lightest')};

          &:not(:hover):not(.react-select__control--is-focused) {
            border-color: ${color('danger-default')};
          }
        }
      }

      > span {
        display: inline-block;
        font: ${font('body-small')};
        color: ${color('danger-default')};
      }
    `}
`;
