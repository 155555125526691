import { useAuth } from 'contexts/AuthContext';

import { validateUserRoles } from 'utils/validateUserRoles';

type UseCanProps = { roles: string[] };

export default function useCan({ roles }: UseCanProps) {
  const { isAuthenticated, user } = useAuth();

  if (!isAuthenticated || !user) return false;

  const userHasValidRoles = validateUserRoles({ user, roles });
  return userHasValidRoles;
}
