import { useEffect, useContext, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { InputText, Select, Text, Editor, Button, Can } from 'components';
import { TinyMCEEditor } from 'components/form/Editor/types';

import { useDisclosure } from 'hooks';
import {
  useAuthors,
  useCategories,
  useDeleteNewsImage,
  usePeriodicals,
  useUpdateNews,
  useUpdateNewsImageFile,
  useUpdateNewsImageInfo,
} from 'hooks/api';

import { updateNewsValidator } from 'shared/validators/news';
import { uploadEditorImage } from 'utils/uploadEditorImage';

import { DropzoneWithInitialPreview } from '../DropzoneWithInitialPreview';
import { FieldsetGrid } from '../styles';
import { FlatError } from '../types';
import { StatusModal } from './StatusModal';
import { EditorHeader, Form } from './styles';
import {
  ApiValidationErrors,
  Status,
  UpdateNewsGeneralFormData,
  UpdateRequests,
} from './types';

import { UpdateNewsContext } from '..';

//* FORMAT API RETURN TO USE AS OPTIONS FOR SELECT
const selectFn = (data: Array<{ name: string; id: string }>) =>
  data.map(category => ({
    label: category.name,
    value: category.id,
  }));

//* STATUS TRANSFORMATION
const statusMap: { [key: string]: Status } = {
  hidden: 'publish',
  published: 'publish',
  scheduled: 'schedule',
  sketch: 'sketch',
};

//* COMPONENT
export function GeneralFormSection() {
  const editorRef = useRef<TinyMCEEditor | null>(null);

  const { registerGetter, news } = useContext(UpdateNewsContext);
  const {
    isOpen: isStatusModalOpen,
    onOpen: onOpenStatusModal,
    onClose: onCloseStatusModal,
  } = useDisclosure();

  // ****** STATES ******
  const [isPublished, setIsPublished] = useState(
    ['hidden', 'published'].includes(news.status)
  );
  const [editorImages, setEditorImages] = useState<string[]>([]);

  // ****** QUERIES ******
  const { data: categories } = useCategories({ select: selectFn });
  const { data: periodicals } = usePeriodicals({ select: selectFn });
  const { data: authors, ...authorsQuery } = useAuthors();

  // ****** MUTATIONS ******
  const updateNewsMutation = useUpdateNews();
  const updateNewsImageFileMutation = useUpdateNewsImageFile();
  const updateNewsImageInfoMutation = useUpdateNewsImageInfo();
  const deleteNewsImageMutation = useDeleteNewsImage();

  // ****** FORM SETUP ******
  const {
    register,
    handleSubmit,
    getValues,
    reset,
    control,
    formState: { errors, isSubmitting, dirtyFields, isValid, isDirty },
    ...form
  } = useForm<UpdateNewsGeneralFormData>({
    resolver: updateNewsValidator,
    reValidateMode: 'onBlur',
    defaultValues: {
      title: news.title,
      description: news.description,
      categoriesId: news.categories.map(category => ({
        label: category.name,
        value: category.id,
      })),
      author: news.author.name,
      periodicalId: news.periodical?.id || null,
      mainImage: { preview: news.mainImage.url },
      mainImageAuthor: news.mainImage.author,
      mainImageDescription: news.mainImage.description,
      contentHtml: news.contentHtml,
      contentText: news.contentText,
      publicationDate: news.publicationDate
        ? new Date(news.publicationDate)
        : undefined,
      status: statusMap[news.status],
    },
  });

  const onSubmit = handleSubmit(
    async ({
      mainImage: { file: mainImageFile },
      mainImageAuthor,
      mainImageDescription,
      publicationDate: scheduledDate,
      status,
      ...newsData
    }) => {
      // After uploading the editor images we're going to need to get the updated
      // values with the new images url
      const editor = editorRef.current;
      if (!editor) return;

      const requestErrors: string[] = [];
      const apiValidationErrors: ApiValidationErrors = [];
      const requests: UpdateRequests = [];
      let bodyImagesId: string[] = [];

      // --- START - UPLOAD BODY IMAGES ---
      try {
        await editor.uploadImages();

        const storageBodyImagesId: string[] = JSON.parse(
          localStorage.getItem('@jl:bodyImagesId') ?? '[]'
        );

        bodyImagesId = [...editorImages, ...storageBodyImagesId];
        setEditorImages(bodyImagesId);
        localStorage.removeItem('@jl:bodyImagesId');
      } catch (error) {
        requestErrors.push(
          'Erro ao atualizar uma das imagens do texto principal'
        );
      }

      const {
        mainImage: isMainImageUpdated,
        mainImageAuthor: isMainImageAuthorUpdated,
        mainImageDescription: isMainImageDescriptionUpdated,
        ...newsDirtyFields
      } = dirtyFields;

      const shouldUpdateNews = Object.values(newsDirtyFields).some(Boolean);

      // --- START - NEWS ---
      if (shouldUpdateNews) {
        requests.push({
          run: async () => {
            let isVisible;
            let publicationDate;

            if (newsDirtyFields.status) {
              if (status === 'sketch') {
                isVisible = false;
                publicationDate = null;
              } else {
                isVisible = true;

                if (status === 'publish') {
                  publicationDate = new Date();
                  setIsPublished(true);
                } else if (status === 'schedule')
                  publicationDate = scheduledDate;
              }
            }

            const regex =
              /(?!figcaption.+?)\s?contenteditable=\\?".+?\\?"\s?(?!\s)?/g;
            const contentHtml = editor.getContent().replace(regex, '');

            await updateNewsMutation.mutateAsync({
              id: news.id,
              slug: news.slug,
              title: newsData.title,
              author: newsData.author,
              periodicalId: newsData.periodicalId,
              description: newsData.description,
              categoriesId: newsData.categoriesId?.map(c => c.value),
              bodyImagesId: !bodyImagesId.length ? undefined : bodyImagesId,
              contentHtml,
              contentText: editor.getContent({ format: 'text' }),
              isVisible,
              publicationDate,
            });

            await Promise.allSettled(
              news.bodyImages
                .filter(image => !contentHtml.includes(image.url))
                .map(image => deleteNewsImageMutation.mutateAsync(image.id))
            );

            setEditorImages([]);
          },
        });
      }

      // --- FILE | MAIN IMAGE ---
      if (isMainImageUpdated && mainImageFile)
        requests.push({
          run: () =>
            updateNewsImageFileMutation.mutateAsync({
              id: news.mainImage.id,
              image: mainImageFile,
            }),
          onError: () =>
            requestErrors.push(
              'Um erro ocorreu ao atualizar a imagem destaque'
            ),
        });

      // --- INFO | MAIN IMAGE ---
      if (isMainImageAuthorUpdated || isMainImageDescriptionUpdated)
        requests.push({
          run: () =>
            updateNewsImageInfoMutation.mutateAsync({
              id: news.mainImage.id,
              author: mainImageAuthor,
              description: mainImageDescription,
            }),
        });

      // --- RUN ALL REQUESTS ---
      const results = await Promise.allSettled(requests.map(req => req.run()));

      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          const { reason } = result;

          if (reason.response?.data.field) {
            const { field, message } = reason.response.data;

            apiValidationErrors.push({ field, message });

            const toastMsg = 'Campo(s) inválido(s)';
            if (!requestErrors.includes(toastMsg)) requestErrors.push(toastMsg);
          }

          requests[index].onError?.(reason);
        }
      });

      //* PRESENT ANY ERROR THAT HAPPENED DURING THE EXECUTIONS
      if (apiValidationErrors.length > 0 || requestErrors.length > 0) {
        apiValidationErrors.forEach(({ field, message }, index) => {
          form.setError(field, { message }, { shouldFocus: index === 0 });
        });
        requestErrors.forEach((message, index) =>
          toast.error(message, { autoClose: 7500, delay: index * 500 })
        );
      } else {
        toast.success('Todas as informações foram atualizadas com sucesso');
      }

      const authorExists = authors?.some(
        author => author.name === newsData.author
      );
      if (!authorExists) authorsQuery.refetch();

      reset(
        {},
        { keepDirty: false, keepValues: true, keepDefaultValues: true }
      );
    }
  );

  // ****** SIDE EFFECTS ******
  useEffect(() => {
    registerGetter('general', getValues);
  }, [getValues, registerGetter]);

  // ****** HELPER FUNCTIONS ******
  // ? MAX OF 3 CATEGORIES
  function isCategoryOptionDisabled() {
    const categoriesId = getValues('categoriesId');
    if (categoriesId?.length === 3) return true;
    return false;
  }

  return (
    <Form onSubmit={onSubmit}>
      <FieldsetGrid>
        <div>
          <InputText
            label="Título"
            error={errors.title?.message}
            readOnly={isSubmitting}
            {...register('title')}
          />

          <Controller
            control={control}
            name="categoriesId"
            defaultValue={[]}
            render={({ field }) => (
              <Select
                label="Categorias"
                options={categories}
                isOptionDisabled={isCategoryOptionDisabled}
                isMulti
                isDisabled={isSubmitting}
                error={(errors.categoriesId as unknown as FlatError)?.message}
                {...field}
              />
            )}
          />
        </div>

        <InputText
          multiline
          label="Descrição"
          maxLength={128}
          readOnly={isSubmitting}
          error={errors.description?.message}
          {...register('description')}
        />

        <Controller
          control={control}
          name="periodicalId"
          render={({ field: { onChange, value, ...rest } }) => (
            <Select
              label="Coluna"
              isDisabled={isSubmitting}
              error={errors.periodicalId?.message}
              options={periodicals}
              value={periodicals?.find(opt => opt.value === value)}
              onChange={option => onChange(option?.value || null)}
              isClearable
              {...rest}
            />
          )}
        />
        <InputText
          label="Autor"
          list="authors"
          readOnly={isSubmitting}
          {...register('author')}
        />
        <datalist id="authors">
          {authors?.map(author => (
            <option key={author.id} value={author.name}>
              {author.name}
            </option>
          ))}
        </datalist>

        {/* MAIN IMAGE */}
        <DropzoneWithInitialPreview
          control={control}
          name="mainImage"
          label="Foto destaque"
          helpText="Anexe a foto principal que estará em destaque na matéria."
          error={(errors.mainImage as unknown as FlatError)?.message}
          readOnly={isSubmitting}
          initialPreview={{
            src: news.mainImage.url,
            alt: news.mainImage.description,
          }}
        />

        <div>
          <InputText
            label="Descrição da foto"
            error={errors.mainImageDescription?.message}
            readOnly={isSubmitting}
            {...register('mainImageDescription')}
          />
          <InputText
            label="Autor da foto"
            error={errors.mainImageAuthor?.message}
            readOnly={isSubmitting}
            containerClassName="author-input-container"
            {...register('mainImageAuthor')}
          />
        </div>
      </FieldsetGrid>

      {/* CONTENT (EDITOR) */}
      <EditorHeader>
        <Text as="h2" variant="heading-small" color="label">
          Redigir matéria
        </Text>
      </EditorHeader>

      <Controller
        control={control}
        name="contentHtml"
        render={({ field: { onChange, ref, ...rest } }) => (
          <Editor
            imageUploadHandler={uploadEditorImage}
            disabled={isSubmitting}
            error={errors.contentHtml?.message}
            onEditorChange={valueAsHtml => onChange(valueAsHtml)}
            ref={node => {
              ref(node);
              editorRef.current = node;
            }}
            {...rest}
          />
        )}
      />

      <Button type="submit" isLoading={isSubmitting} disabled={!isDirty}>
        Atualizar
      </Button>

      <Can roles={['root', 'editor']}>
        {!isPublished && (
          <>
            <Button
              color="secondary"
              onClick={onOpenStatusModal}
              isLoading={isSubmitting}
            >
              Alterar status
            </Button>

            <StatusModal
              isOpen={isStatusModalOpen}
              isSubmitting={isSubmitting}
              isValid={isValid}
              isDirty={isDirty}
              control={control}
              register={register}
              onRequestClose={onCloseStatusModal}
              onSubmit={onSubmit}
            />
          </>
        )}
      </Can>
    </Form>
  );
}
