import { toast } from 'react-toastify';

import { AlertModal, Button, ContentLoading, PieChart, Text } from 'components';

import { formatDate } from 'lib';

import { useToggle } from 'hooks';
import { useActivePoll, useFinishPoll } from 'hooks/api';

import { formatAlternativesAsChartData } from 'utils/formatAlternativesAsChartData';

import { ActivePollContainer } from './styles';

export function ActivePoll() {
  const [isFinishModalOpen, toggleFinishModal] = useToggle();

  const finishPoll = useFinishPoll({
    onSuccess: () => {
      toast.success('Enquete finalizada com sucesso');
    },
  });

  const { data: activePoll, isLoading } = useActivePoll({
    staleTime: 1000 * 60 * 5, // ? 5 minutes,
    select: data => {
      if (!data) return null;

      return {
        ...data,
        startDate: formatDate(new Date(data.startDate), 'P'),
        chart: formatAlternativesAsChartData(data.alternatives),
      };
    },
  });

  if (isLoading) return <ContentLoading label="Carregando enquete ativa" />;

  if (!activePoll)
    return (
      <div className="no-data-container">
        <Text variant="heading-x-small">Nenhuma enquete ativa no momento</Text>
      </div>
    );

  return (
    <>
      <ActivePollContainer>
        <div className="info">
          <div className="info__question">
            <Text variant="body-large-heavy">{activePoll.question}</Text>
          </div>

          <div className="info__complementary">
            <p>
              Total de respostas: <strong>{activePoll.votes}</strong>
            </p>
            <p>
              Ativo desde: <strong>{activePoll.startDate}</strong>
            </p>
          </div>
        </div>

        <div className="chart">
          <PieChart
            data={activePoll.chart.data}
            labels={activePoll.chart.labels}
            options={{
              legend: { width: 300 },
              responsive: [
                { breakpoint: 1200, options: { legend: { width: 250 } } },
                { breakpoint: 900, options: { legend: { width: 400 } } },
                { breakpoint: 700, options: { legend: { width: 300 } } },
                {
                  breakpoint: 600,
                  options: {
                    legend: {
                      width: undefined,
                      position: 'bottom',
                      horizontalAlign: 'left',
                    },
                  },
                },
              ],
            }}
          />
        </div>

        <Button color="secondary" onClick={toggleFinishModal}>
          Finalizar
        </Button>
      </ActivePollContainer>

      {/* Modal to finish a poll */}
      <AlertModal
        isOpen={isFinishModalOpen}
        onRequestClose={toggleFinishModal}
        variant="warning"
        title="Finalizar enquete"
        disabledCloseButtons={finishPoll.isLoading}
        shouldCloseOnOverlayClick={!finishPoll.isLoading}
        showCancelButton
        actionButton={{
          text: 'Finalizar',
          onClick: () => finishPoll.mutate(activePoll.id),
          isLoading: finishPoll.isLoading,
        }}
      >
        <Text>
          Deseja realmente finalizar a enquete{' '}
          <strong>{activePoll.question}</strong>?
        </Text>
      </AlertModal>
    </>
  );
}
