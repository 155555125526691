import { useLocation } from 'react-router-dom';

import { Button, Text } from 'components';

import { ADMIN_NEWS } from 'routes/PATHS';

import { Container } from './styles';

const pageText: { [key: string]: string } = {
  destaques: 'Controle de Destaques',
  edicoes: 'Controle de Edições',
  enquetes: 'Controle de Enquetes',
  dashboard: 'Dashboard',
};

export default function UnderDevelopment() {
  const { pathname } = useLocation();

  const resource = pathname
    .replace(/\/admin\//g, '')
    .replace(/\/admin/g, 'dashboard');

  return (
    <Container>
      <div>
        <Text variant="heading-x-small" color="label" mb="0.25rem">
          {pageText[resource]}
        </Text>
        <Text as="h1" variant="display-medium-heavy">
          Funcionalidade em desenvolvimento
        </Text>
      </div>
      <Button color="secondary" to={ADMIN_NEWS}>
        Voltar as notícias
      </Button>
    </Container>
  );
}
