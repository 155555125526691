import styled from 'styled-components';

import { color, cvar, font } from 'utils/cvar';

export const Container = styled.div`
  background-color: ${color('white')};
  border: 1px solid ${color('line')};

  header {
    background-color: ${color('line')};
    padding: 0.5rem 0.75rem;

    h2 {
      font: ${font('body-large-heavy')};
      text-align: center;
    }
  }

  .status-container {
    padding: 1.5rem;
    text-align: center;
  }

  main {
    padding: 0.75rem;
  }
`;

export const NewsItem = styled.div`
  position: relative;

  & + div {
    margin-top: 2rem;

    &::before {
      position: absolute;
      content: '';
      height: 1px;
      width: 100%;
      background-color: ${color('line')};
      top: -1rem;
    }
  }

  .tags {
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
  }

  a {
    display: block;
    color: ${color('title-active')};
    font: ${font('body-normal')};
    margin-top: 0.5rem;
    transition: color ${cvar('transition-quick')};

    &:hover {
      color: ${color('secondary-dark')};
    }
  }
`;
