import { useRef } from 'react';
import { RiSearchLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';

import { Button } from 'components';

import { useDisclosure, useMediaQuery, useOnClickOutside } from 'hooks';

import { SEARCH_NEWS } from 'routes/PATHS';
import { MQ_TABLET_PORTRAIT_UP } from 'utils/mediaQuery';

import { Container } from './styles';
import { SearchBarProps } from './types';

export default function SearchBar({
  isOpen: controlledIsOpen,
  onOpen: controlledOnOpen,
  onClose: controlledOnClose,
}: SearchBarProps) {
  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const history = useHistory();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const isTabletPortraitUp = useMediaQuery(MQ_TABLET_PORTRAIT_UP);

  const isControlledMode = typeof controlledIsOpen === 'boolean';

  useOnClickOutside(containerRef, () => {
    if (isControlledMode) controlledOnClose?.();
    else onClose();
  });

  function searchOrOpen() {
    if (isOpen || controlledIsOpen || isTabletPortraitUp) {
      const input = inputRef.current;
      if (!input) return;

      const inputValue = input.value.trim();

      if (inputValue) {
        history.push(`${SEARCH_NEWS}/${inputValue}`);
        input.value = '';
        input.blur();
        return;
      }
    }

    if (isControlledMode) controlledOnOpen?.();
    else onOpen();
  }

  return (
    <Container
      ref={containerRef}
      isOpen={
        isTabletPortraitUp ||
        (isControlledMode ? (controlledIsOpen as boolean) : isOpen)
      }
    >
      <input
        ref={inputRef}
        name="search"
        aria-label="Pesquisar"
        placeholder="Pesquisar"
        onKeyDown={e => {
          if (e.key === 'Enter') searchOrOpen();
        }}
      />
      <Button
        icon={RiSearchLine}
        color="light"
        variant="ghost"
        onClick={searchOrOpen}
      />
    </Container>
  );
}
