import { ReactNode, SyntheticEvent } from 'react';
import { Link } from 'react-router-dom';

import clsx from 'clsx';

import { Tag, Text } from 'components';

import { Container, Content, Image } from './styles';
import { CardProps } from './types';

export default function Card({
  image,
  tags,
  title,
  linkTo,
  description,
  bottomText,
  variant = 'normal',
  className,
  ...rest
}: CardProps) {
  const withLink = (element: ReactNode) =>
    linkTo ? <Link to={linkTo}>{element}</Link> : element;

  const onImageError = (event: SyntheticEvent<HTMLImageElement>) => {
    event.currentTarget.parentElement?.classList.add('image-load-error');
  };

  return (
    <Container className={clsx('card', className)} variant={variant} {...rest}>
      {image && (
        <Image
          className="card__image"
          aspectRatio={image?.aspectRatio || 16 / 9}
        >
          {withLink(
            <img src={image.url} alt={image.url} onError={onImageError} />
          )}
        </Image>
      )}

      <Content className="card__content" variant={variant} hasImage={!!image}>
        {!!tags?.length && (
          <div className="card__tags">
            {tags.map(tag => (
              <Tag key={tag.id}>{tag.title}</Tag>
            ))}
          </div>
        )}

        <h2 className="card__title">{withLink(title)}</h2>

        {description && (
          <Text className="card__description">{description}</Text>
        )}

        {bottomText && (
          <Text
            as="small"
            variant="body-small"
            color="label"
            className="card__bottom-text"
          >
            {bottomText}
          </Text>
        )}
      </Content>
    </Container>
  );
}
