import { Link, useParams } from 'react-router-dom';

import { useNews } from 'hooks/api';

import { NEWS } from 'routes/PATHS';

import PulseLoader from '../PulseLoader';
import Tag from '../Tag';
import Text from '../Text';
import { Container, NewsItem } from './styles';

export default function LastNewsList() {
  const { slug } = useParams<{ slug?: string }>();

  const { data, isLoading, isError, isSuccess } = useNews({
    query: { page: 1 },
    refetchOnMount: false,
    select: ({ data: newsData }) => {
      return newsData.filter(news => news.slug !== slug).slice(0, 6);
    },
  });

  return (
    <Container>
      <header>
        <h2>Últimas notícias</h2>
      </header>

      {isLoading && (
        <div className="status-container">
          <PulseLoader isLoading />
        </div>
      )}

      {isError && (
        <div className="status-container">
          <Text variant="body-small">
            Um erro ocorreu. Não foi possível carregar as últimas notícias
          </Text>
        </div>
      )}

      {isSuccess && (
        <main>
          {data?.map(news => (
            <NewsItem key={news.id}>
              <div className="tags">
                {news.categories.map(category => (
                  <Tag key={category.id} variant="inverse">
                    {category.name}
                  </Tag>
                ))}
              </div>
              <Link to={`${NEWS}/${news.slug}`}>{news.title}</Link>
            </NewsItem>
          ))}
        </main>
      )}
    </Container>
  );
}
