import { RiLoader3Line } from 'react-icons/ri';

import { cssValue } from 'utils/unitConverter';

import { SpinnerContainer } from './styles';
import { SpinnerProps } from './types';

export default function Spinner({
  isLoading,
  size = '2rem',
  color = 'primary-default',
  ...rest
}: SpinnerProps) {
  return isLoading ? (
    <SpinnerContainer $color={color} {...rest}>
      <RiLoader3Line size={cssValue(size)} />
    </SpinnerContainer>
  ) : null;
}
