import { QueryClient } from 'react-query';
import { toast } from 'react-toastify';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      refetchOnWindowFocus: false,
    },
    mutations: {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      retry: (failureCount, error: any) => {
        if (
          error.response?.data?.message === 'Erro de validação' ||
          error.response?.data?.field ||
          failureCount === 2
        )
          return false;

        return true;
      },
      onError: () => {
        toast.error('Um erro inesperado ocorreu. Por favor tente novamente.');
      },
    },
  },
});
