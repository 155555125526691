import { css } from 'styled-components';

import { color, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

/**
 * Fix margin collapsing by adding `::before` and `::after` pseudo elements
 * and changing them to `display: table`.
 *
 * Resource: https://www.thecodingfox.com/blog/collapsing-margins
 */
export const fixMarginCollapsing = css`
  &::before {
    content: ' ';
    display: table;
  }

  &::after {
    content: ' ';
    display: table;
    clear: both;
  }
`;

/**
 * Define the scrollbar width **when device is NOT mobile**.
 *
 * It only sets the width when device has media hover.
 *
 * To customize the width, define a CSS variable called _**--scrollbar-width**_.
 *
 * @default '0.5rem'
 */
export const scrollbarWidth = css`
  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: var(--scrollbar-width, 0.5rem);
    }
  }
`;

/**
 * Change the colors of the scrollbar to fit better on a dark background
 */
export const darkBackgroundScrollbar = css`
  @media (hover: hover) {
    ::-webkit-scrollbar {
      &-thumb {
        background: hsla(220, 27%, 98%, 0.3);

        &:hover {
          background: hsla(220, 27%, 98%, 0.4);
        }
      }

      &-track {
        background: hsla(220, 27%, 98%, 0.1);
      }
    }
  }
`;

/**
 * Apply the styles using in the new presentation.
 *
 * These styles are shared between the editor (located in the file `content.css`
 * inside `public/tinymce` folder) and the presentation of the content to the
 * final user like in the *NewsPreview* and the public news page.
 */
export const contentFromEditorStyles = css`
  p {
    font: ${font('body-large')};
    margin-bottom: 1.5rem;

    > img {
      margin-top: 1rem;
    }

    > img:not(.image__medium):not(.image__small) {
      margin-bottom: 1rem;
    }

    /* Fallback to that news using the old style */
    > img:not(.image-left):not(.image-right) {
      margin-bottom: 1rem;
    }
  }

  strong {
    font-weight: 600;
  }

  h2 {
    font: ${font('heading-medium')};
    margin-top: 3rem;
    margin-bottom: 1.5rem;
  }

  h3 {
    font: ${font('heading-small')};
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  h4 {
    font: ${font('heading-x-small')};
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  a {
    font-weight: 600;
    color: ${color('secondary-dark')};
    transition: color 150ms;

    &:hover {
      color: ${color('secondary-darker')};
    }
  }

  ul,
  ol {
    padding: revert;
    margin: 1.5rem 0;

    li + li,
    li + li {
      margin-top: 0.5rem;
      margin-top: 0.5rem;
    }
  }

  table {
    border-collapse: collapse;

    th,
    td {
      border: 1px solid ${color('line')};
      padding: 0.4rem;
    }

    th {
      font-weight: 600;
      background-color: ${color('input-background')};
    }
  }

  figure {
    margin: initial;
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    figcaption {
      display: block;
      margin-top: 0.25rem;
      font: ${font('body-small')};
      color: ${color('label')};
    }
  }

  img,
  figure.image img {
    height: auto;
    max-width: 100%;
  }

  p > img,
  figure.image {
    float: left;
    margin-bottom: 1rem;
    margin-right: 1.5rem;
  }

  img[class*='image__']:not(.image__right),
  figure[class*='image__']:not(.image__right) img {
    width: 100%;
  }

  img.image__full,
  figure.image__full {
    float: unset;
    margin-right: unset;
  }

  img.image__medium,
  figure.image__medium {
    max-width: 50%;
  }

  img.image__small,
  figure.image__small {
    max-width: 25%;
  }

  img.image__right,
  figure.image__right {
    float: right;
    margin-right: 0;
    margin-left: 1.5rem;
  }

  blockquote {
    border-left: 6px solid ${color('primary-light')};
    background-color: ${color('input-background')};
    font-style: italic;
    margin: 1.5rem;
    padding: 0.25rem 1rem;

    p {
      margin: 1rem 0;
    }
  }

  /* Media preview */
  .media-aspect-ratio {
    margin: 2rem 0;
    position: relative;
    width: 100%;

    &:before {
      display: block;
      content: '';
      width: 100%;
      padding-top: calc((9 / 16) * 100%);
    }

    > * {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    iframe {
      border: 0;
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    /* Fallback to the news with the old styles */
    img.image-full,
    figure.image-full {
      float: unset;
      margin-right: unset;
    }

    figure.image-left,
    figure.image-right {
      margin-top: 0.25rem;
    }

    img[class*='image-']:not(.image-full),
    figure[class*='image-']:not(.image-full) {
      height: auto;
      margin-bottom: 1rem;
      max-width: 50%;
    }

    img.image-left,
    figure.image.image-left {
      float: left;
      margin-right: 1.5rem;
    }

    img.image-right,
    figure.image.image-right {
      float: right;
      margin-right: 0;
      margin-left: 1.5rem;
    }
    /* End fallback */
  }
`;

/**
 * Add a background to simulate a border indicating there is more content to scroll
 */
export const scrollableContentIndicatorBorder = css`
  background: linear-gradient(var(--color-primary-default) 30%, transparent),
    linear-gradient(transparent, var(--color-primary-default) 70%) 0 100%,
    linear-gradient(var(--color-primary-darker) 2px, transparent 2px),
    linear-gradient(to top, var(--color-primary-darker) 2px, transparent 2px) 0
      100%;
  background-repeat: no-repeat;
  background-size: 100% 2.5rem, 100% 2.5rem, 100% 0.875rem, 100% 0.875rem;
  background-attachment: local, local, scroll, scroll;
`;
