import { ReactNode } from 'react';

import Footer from './Footer';
import Header from './Header';
import { LayoutWrapper } from './styles';

export default function PublicLayout({ children }: { children: ReactNode }) {
  return (
    <LayoutWrapper>
      <Header />
      <main>{children}</main>
      <Footer />
    </LayoutWrapper>
  );
}
