import { PieChart } from 'components';

import { Container } from './styles';

type ChartData = { labels: string[]; data: number[] };

type PollWithChartProps = {
  poll: {
    question: string;
    startDate: string;
    endDate?: string | null;
    votes: number;
    chart: ChartData;
  };
};

export function PollWithChart({ poll }: PollWithChartProps) {
  return (
    <Container>
      <div className="about">
        <h2>{poll.question}</h2>

        {poll.endDate ? (
          <p>
            Período: {poll.startDate} - {poll.endDate}
          </p>
        ) : (
          <p>Ativo desde: {poll.startDate}</p>
        )}

        <p>Total de votos: {poll.votes}</p>
      </div>

      <PieChart
        labels={poll.chart.labels}
        data={poll.chart.data}
        responsiveHeight={{
          largerBreakpointHeight: 250,
          breakpoints: [
            [900, 400],
            [600, 500],
          ],
        }}
        options={{
          legend: { width: 450 },
          responsive: [
            { breakpoint: 1200, options: { legend: { width: 400 } } },
            { breakpoint: 950, options: { legend: { width: 350 } } },
            {
              breakpoint: 900,
              options: {
                legend: {
                  width: undefined,
                  position: 'bottom',
                  horizontalAlign: 'left',
                },
              },
            },
          ],
        }}
      />
    </Container>
  );
}
