import { ChangeEvent } from 'react';

import { debounce } from 'lodash';

import { InputText, Select } from 'components';

import { useCategories, usePeriodicals } from 'hooks/api';

import { Container } from './styles';
import { FilterFunction, FiltersProps, Option } from './types';

//* FORMAT API RETURN TO USE AS OPTIONS FOR SELECT
const selectFn = (data: Array<{ name: string; slug: string }>) =>
  data.map(category => ({
    label: category.name,
    value: category.slug,
  }));

//* COMPONENT
export function Filters({
  onChangeCategory,
  onChangePeriodical,
  onChangeSearch,
}: FiltersProps) {
  const categoriesQuery = useCategories({ select: selectFn });
  const periodicalsQuery = usePeriodicals({
    query: { visibility: 'all' },
    select: selectFn,
  });

  function handleChangeSelect(changeFn: FilterFunction, option: Option) {
    if (option) changeFn({ name: option.label, slug: option.value });
    else changeFn(null);
  }

  function handleChangeSearch(event: ChangeEvent<HTMLInputElement>) {
    onChangeSearch(event.target.value.trim());
  }

  if (categoriesQuery.isSuccess && periodicalsQuery.isSuccess)
    return (
      <Container>
        <InputText
          type="search"
          label="Pesquisar"
          name="search"
          removeMarginTop
          removeErrorSlotMargin
          containerClassName="search"
          onChange={debounce(handleChangeSearch, 1000)}
        />

        <Select
          label="Categoria"
          name="category"
          placeholder="Todos"
          removeMarginTop
          removeErrorSlotMargin
          options={categoriesQuery.data}
          onChange={opt => handleChangeSelect(onChangeCategory, opt)}
          isClearable
        />

        <Select
          label="Coluna"
          name="periodical"
          placeholder="Todos"
          removeMarginTop
          removeErrorSlotMargin
          options={periodicalsQuery.data}
          onChange={opt => handleChangeSelect(onChangePeriodical, opt)}
          isClearable
        />
      </Container>
    );

  return null;
}
