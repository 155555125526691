import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const Form = styled.form`
  > button {
    margin-top: 2rem;

    & + button {
      margin-left: 1rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    > button {
      width: 100%;

      & + button {
        margin-top: 1rem;
        margin-left: 0;
      }
    }
  }

  ${mediaQuery.tabletLandscapeUp} {
    .tox-editor-header {
      top: 5.5rem !important;
    }
  }
`;

export const EditorHeader = styled.div`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
`;
