import { useEffect, useRef, useState } from 'react';
import { RiAddLine, RiCloseLine } from 'react-icons/ri';

import { Button, Card, Text } from 'components';

import { placeholder } from 'assets';

import { useToggle } from 'hooks';
import { useNewsHighlightTypes } from 'hooks/api';

import AddHighlightModal from '../AddHighlightModal';
import ConfirmDeleteModal, {
  ConfirmRemoveModalRef,
} from '../ConfirmRemoveModal';
import { News } from '../types';
import { Container } from './styles';

type MainNewsHighlightsProps = { data: News[] };

export default function MainNewsHighlights({ data }: MainNewsHighlightsProps) {
  const confirmDeleteModalRef = useRef<ConfirmRemoveModalRef>(null);

  const [highlights, setHighlights] = useState(data);
  const [isAddHighlightModalOpen, toggleAddHighlightModal] = useToggle();

  const { data: type } = useNewsHighlightTypes({
    select: types => types.find(v => v.type === 'mainNews'),
  });

  useEffect(() => setHighlights(data), [data]);

  return (
    <Container>
      <Text as="h1" variant="heading-medium" mb="0.5rem">
        Destaque principal
      </Text>

      <div className="card-grid">
        {highlights.map((news, index) => (
          <div className="card-wrapper" key={news.id}>
            <Card
              key={news.id}
              image={{
                url: news.mainImage.url,
                alt: news.mainImage.description,
              }}
              title={news.title}
              description={news.description}
            />

            <Button
              size="small"
              color="danger"
              icon={RiCloseLine}
              className="card-wrapper__action--delete"
              onClick={() => confirmDeleteModalRef.current?.open(news, index)}
            />
          </div>
        ))}

        {highlights.length === 0 && (
          <>
            <div className="add-new-highlight">
              <Card
                title="Novo destaque"
                image={{ url: placeholder, alt: '' }}
              />
              <button type="button" onClick={toggleAddHighlightModal}>
                <RiAddLine size="4rem" />
              </button>
            </div>

            <AddHighlightModal
              isOpen={isAddHighlightModalOpen}
              onRequestClose={toggleAddHighlightModal}
              highlightId={type?.id as string}
            />
          </>
        )}

        <ConfirmDeleteModal
          ref={confirmDeleteModalRef}
          title="Remover destaque principal"
          onConfirm={() => setHighlights([])}
        />
      </div>
    </Container>
  );
}
