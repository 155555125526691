import styled from 'styled-components';

import { color, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  max-width: 72rem;
  margin: 0 auto 3rem;

  .header {
    margin-bottom: 2rem;
  }

  ${mediaQuery.phoneOnly} {
    .header__title {
      margin-bottom: 1rem;
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        flex-shrink: 0;
      }
    }
  }
`;

export const EditionsList = styled.div`
  .editions {
    margin-top: 3.5rem;
    display: grid;
    gap: 1rem;
  }

  .pagination {
    margin-top: 1.5rem;
    text-align: right;
  }

  img {
    width: 100%;
  }

  .card-wrapper {
    position: relative;

    button {
      position: absolute;
      top: 1px;
      right: 1px;
    }

    .card {
      height: 100%;
    }

    &.active {
      .card {
        border-color: ${color('secondary-default')};
      }

      &:before {
        box-sizing: border-box;
        content: 'Ativa';
        position: absolute;
        bottom: 100%;
        left: 0;
        padding: 0.5rem;
        height: 2rem;
        font: ${font('body-small-heavy')};
        line-height: 1;
        color: ${color('white')};
        background-color: ${color('secondary-default')};
      }
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    .editions {
      grid-template-columns: repeat(auto-fill, minmax(14rem, 1fr));
      gap: 1.5rem;
    }
  }
`;
