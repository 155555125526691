import styled from 'styled-components';

import { color, font } from 'utils/cvar';

export const Container = styled.div`
  text-align: center;

  p {
    display: inline-block;
    font: ${font('body-small')};
    color: ${color('placeholder')};
    margin-bottom: 0.5rem;

    position: relative;
    --offset: calc(100% + 0.5rem);

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 50%;
      height: 1px;
      background-color: currentColor;
      opacity: 0.5;
    }

    &:before {
      right: var(--offset);
    }

    &:after {
      left: var(--offset);
    }
  }
`;
