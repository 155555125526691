import styled from 'styled-components';
import {
  darkBackgroundScrollbar,
  scrollableContentIndicatorBorder,
  scrollbarWidth,
} from 'styles/shared';

import { color, cvar } from 'utils/cvar';

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: ${cvar('z-index-backdrop')};
  background: ${color('overlay-dark-50')};
  cursor: pointer;
`;

export const Container = styled.nav<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  width: min(100%, 18.75rem); //? 300px when 1rem = 16px
  z-index: ${cvar('z-index-sidebar')};
  background: ${color('primary-default')};

  padding-top: 4rem;

  transform: ${p => (p.isOpen ? 'initial' : 'translateX(100%)')};
  transition: transform ${cvar('transition-quick')};

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ul {
    list-style: none;
  }

  .scrollable {
    ${scrollbarWidth}
    ${darkBackgroundScrollbar}
    ${scrollableContentIndicatorBorder}
  }

  .submenu__container {
    flex: 1;
    overflow: hidden;
    /* Allow positioning submenu inside the main navigation group */
    position: relative;
  }

  .mobile-menu {
    &__close-btn {
      position: absolute;
      top: 0.25rem;
      right: 1rem;
    }

    &__list {
      height: 100%;
      overflow: auto;
    }
  }

  .user-nav {
    padding: 1.5rem;

    button + button {
      margin-top: 0.5rem;
    }
  }
`;
