import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  .card-grid {
    max-width: 60rem;
    margin-top: 1.5rem;
  }

  .card__description {
    margin-bottom: 0;
  }

  .card-wrapper {
    position: relative;

    &__action--delete {
      position: absolute;
      top: 1px;
      right: 1px;
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    .card {
      --card-img-aspect-ratio: 2/1;
      display: grid;
      grid-template-columns: calc(50% - (var(--card-padding) / 2)) auto;
    }

    .card-wrapper__action--delete {
      right: initial;
      left: 1px;
    }
  }
`;
