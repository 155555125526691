import { NavLink } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { color, cvar, font } from 'utils/cvar';

export const sidebarBaseLinkStyle = css`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.25rem;
  outline: none;
  transition: padding ${cvar('transition-quick')};
  overflow: hidden;
  span {
    margin-left: 1.25rem;
    font: ${font('body-normal-heavy')};
    color: ${color('background')};
  }
  &:hover,
  &:focus {
    background-color: ${color('primary-dark')};
  }
  &.active {
    span {
      color: ${color('secondary-default')};
    }
  }
`;

export const sidebarLinkWithIconStyle = css`
  ${sidebarBaseLinkStyle}
  svg {
    fill: ${color('placeholder')};
    flex-shrink: 0;
    transition: transform ${cvar('transition-quick')};
  }
  &.active {
    svg {
      fill: ${color('secondary-default')};
    }
  }
`;

export const StyledNavLink = styled(NavLink)`
  ${sidebarLinkWithIconStyle}
`;
