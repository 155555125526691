import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { RiCloseLine } from 'react-icons/ri';

import {
  Button,
  ContentLoading,
  DataTable,
  DatePicker,
  PieChart,
  Tag,
  Text,
} from 'components';

import { formatDate } from 'lib';

import { useToggle } from 'hooks';
import { usePolls } from 'hooks/api';

import { formatAlternativesAsChartData } from 'utils/formatAlternativesAsChartData';

import {
  Container,
  DataTableBody,
  DetailsTrigger,
  DetailsModal,
  Filters,
} from './styles';
import {
  DateOrNull,
  DateRange,
  OrderBy,
  SelectedPoll,
  StatusMap,
} from './types';

//* MAPPER
const statusMap: StatusMap = {
  sketch: ['Rascunho', 'danger'],
  current: ['Ativa', 'secondary'],
  finished: ['Finalizada', 'success'],
};

//* HELPERS
const getFormattedDate = (date: string | null) =>
  date ? formatDate(new Date(date), 'P') : '-';

//* COMPONENT
export default function PollsHistory() {
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(15);
  const [orderBy, setOrderBy] = useState<OrderBy | null>(null);
  const [dateRange, setDateRange] = useState<DateRange | null>(null);

  const [selectedPoll, setSelectedPoll] = useState<SelectedPoll | null>(null);
  const [isDetailsModalOpen, toggleDetailsModal] = useToggle(false);

  const {
    data: polls,
    isLoading,
    isSuccess,
  } = usePolls({
    query: { page, limit, ...orderBy, ...dateRange },
  });

  function selectDateRange(on: 'start' | 'end', date: DateOrNull) {
    const key = `${on}Date`;
    setDateRange(old => ({ ...old, [key]: date || undefined }));
  }

  function handleOpenDetailsModal(index: number) {
    if (!polls) return;

    const { question, votes, alternatives } = polls.data[index];
    const chart = formatAlternativesAsChartData(alternatives);

    setSelectedPoll({ question, votes, chart });
    toggleDetailsModal();
  }

  return (
    <Container>
      <Helmet>
        <title>Histórico de enquetes</title>
      </Helmet>

      <Text as="h1" variant="heading-medium">
        Histórico de enquetes
      </Text>

      <br />

      {isLoading && <ContentLoading label="Carregando enquetes" />}

      {isSuccess && (
        <Filters>
          <div className="date-range">
            <p className="date-range__label">Período</p>

            <div className="date-range__inputs">
              <DatePicker
                removeErrorSlotMargin
                removeMarginTop
                containerClassName="date-range__picker"
                name="startDate"
                placeholderText="Início"
                selectsStart
                selected={dateRange?.startDate}
                startDate={dateRange?.startDate}
                endDate={dateRange?.endDate}
                maxDate={dateRange?.endDate}
                onChange={date => selectDateRange('start', date as DateOrNull)}
              />

              <span className="date-range__dash" />

              <DatePicker
                removeErrorSlotMargin
                removeMarginTop
                containerClassName="date-range__picker"
                name="endDate"
                placeholderText="Fim"
                selectsEnd
                selected={dateRange?.endDate}
                startDate={dateRange?.startDate}
                endDate={dateRange?.endDate}
                minDate={dateRange?.startDate}
                onChange={date => selectDateRange('end', date as DateOrNull)}
              />
            </div>
          </div>
        </Filters>
      )}

      {polls &&
        (polls.total === 0 ? (
          <div className="no-data-container">
            <Text variant="heading-small">
              Nenhuma enquete cadastrada {dateRange && 'com estes filtros'}
            </Text>
          </div>
        ) : (
          <>
            <DataTable
              columns={[
                { name: 'Questão', sortName: 'question' },
                { name: 'Votos', sortName: 'votes' },
                { name: 'Início', sortName: 'start_date' },
                { name: 'Fim', sortName: 'end_date' },
                { name: 'Status' },
              ]}
              page={page}
              pageRange={polls.range}
              total={polls.total}
              totalPages={polls.totalPages}
              limit={polls.limit}
              sort={orderBy?.sort}
              order={orderBy?.order}
              onOrderChange={setOrderBy}
              onLimitChange={selected => {
                setLimit(selected);
                setPage(1);
              }}
              onPageChange={selected => {
                setPage(selected);
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
              tableProps={{ className: 'data-table' }}
            >
              <DataTableBody>
                {polls.data.map((poll, index) => (
                  <tr key={poll.id}>
                    <td title={poll.question}>
                      <DetailsTrigger
                        onClick={() => handleOpenDetailsModal(index)}
                      >
                        {poll.question}
                      </DetailsTrigger>
                    </td>
                    <td>{poll.votes}</td>
                    <td data-header="Início: ">
                      {getFormattedDate(poll.startDate)}
                    </td>
                    <td data-header="Fim: ">
                      {getFormattedDate(poll.endDate)}
                    </td>
                    <td>
                      <Tag variant="outline" color={statusMap[poll.status][1]}>
                        {statusMap[poll.status][0]}
                      </Tag>
                    </td>
                  </tr>
                ))}
              </DataTableBody>
            </DataTable>

            {selectedPoll && (
              <DetailsModal
                overlayClassName="ReactModal__Overlay"
                isOpen={isDetailsModalOpen}
                onRequestClose={toggleDetailsModal}
              >
                <Button
                  icon={RiCloseLine}
                  color="neutral"
                  size="small"
                  variant="ghost"
                  className="close-modal"
                  onClick={toggleDetailsModal}
                />

                <div className="about">
                  <h2>{selectedPoll.question}</h2>
                  <p>Total de votos: {selectedPoll.votes}</p>
                </div>

                <PieChart
                  labels={selectedPoll.chart.labels}
                  data={selectedPoll.chart.data}
                  options={{
                    legend: { width: 400 },
                    responsive: [
                      { breakpoint: 1200, options: { legend: { width: 400 } } },
                      { breakpoint: 900, options: { legend: { width: 400 } } },
                      { breakpoint: 700, options: { legend: { width: 300 } } },
                      {
                        breakpoint: 600,
                        options: {
                          legend: {
                            width: undefined,
                            position: 'bottom',
                            horizontalAlign: 'left',
                          },
                        },
                      },
                    ],
                  }}
                />
              </DetailsModal>
            )}
          </>
        ))}
    </Container>
  );
}
