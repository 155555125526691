import styled from 'styled-components';

import { color } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const FieldsetGrid = styled.fieldset`
  ${mediaQuery.tabletLandscapeUp} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 1.5rem;

    textarea {
      height: 11rem;
    }

    .author-input-container {
      margin-top: 2rem;
    }
  }
`;

export const EditorHeader = styled.div`
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const DynamicFieldset = styled.fieldset`
  .toggle label {
    margin-bottom: 0;
  }

  .gallery {
    &__action-remove {
      text-align: right;
      margin-top: 0.5rem;
    }

    &__action-add {
      text-align: right;
      margin-top: 1.5rem;
    }
  }
`;

export const GalleryItem = styled.div`
  & + & {
    margin-top: 1.5rem;
    padding-top: 0.5rem;
    border-top: 1px solid ${color('primary-light')};
  }
`;

export const PreviewTriggersContainer = styled.div`
  button {
    &:last-of-type {
      display: none;
    }
  }

  ${mediaQuery.phoneOnly} {
    button {
      &:first-of-type {
        display: none;
      }
      &:last-of-type {
        display: inline-flex;
      }
    }
  }
`;
