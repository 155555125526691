import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';

export const Container = styled.div`
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 0.625rem;
    border: 0;
    border-radius: 0.25rem;
    font-size: 0.825rem;
    font-weight: 600;
    height: 28px;
    background-color: ${color('primary-default')};
    color: ${color('white')};
    transition: opacity ${cvar('transition-quick')};

    svg {
      margin-right: 4px;
    }

    &:hover {
      opacity: 0.8;
    }
  }

  .tippy-box {
    padding: 0.5rem 1rem;
    background-color: ${color('body')};
    color: ${color('white')};

    .tippy-arrow {
      width: 1rem;
      height: 1rem;
      bottom: 0;
      color: ${color('body')};

      &:before {
        content: '';
        position: absolute;
        border-color: transparent;
        border-style: solid;
        bottom: -7px;
        left: 0;
        border-width: 8px 8px 0;
        border-top-color: initial;
        transform-origin: center top;
      }
    }
  }
`;
