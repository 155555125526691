import {
  RiArrowLeftSLine,
  RiArrowRightSLine,
  RiCloseLine,
} from 'react-icons/ri';

import { Text, Button } from 'components';

import { StyledModal } from './styles';

type ZoomModalProps = {
  isOpen: boolean;
  onRequestClose: () => void;
  zoomPreviousImage: () => void;
  zoomNextImage: () => void;
  showNextPrevControls: boolean;
  image: {
    id: string;
    url: string;
    description: string;
    author: string;
  };
};

StyledModal.setAppElement('#root');

export function ZoomModal({
  image,
  isOpen,
  showNextPrevControls,
  onRequestClose,
  zoomPreviousImage,
  zoomNextImage,
}: ZoomModalProps) {
  return (
    <StyledModal
      overlayClassName="ReactModal__Overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
    >
      <div className="content">
        <div className="image">
          <Button
            icon={RiCloseLine}
            color="light"
            size="small"
            className="close-modal"
            onClick={onRequestClose}
          />
          <img src={image.url} alt={image.description} />

          {showNextPrevControls && (
            <>
              <button
                type="button"
                onClick={zoomPreviousImage}
                aria-label="Ir para a imagem anterior"
                className="image__navigate image__navigate--left"
              >
                <RiArrowLeftSLine size="2.25rem" />
              </button>
              <button
                type="button"
                onClick={zoomNextImage}
                aria-label="Ir para a próxima imagem"
                className="image__navigate image__navigate--right"
              >
                <RiArrowRightSLine size="2.25rem" />
              </button>
            </>
          )}
        </div>

        {image.description && image.author && (
          <Text variant="body-normal-heavy">
            {image.description} - Foto: {image.author}
          </Text>
        )}
      </div>
    </StyledModal>
  );
}
