import { useState, useCallback } from 'react';
import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';

import {
  ButtonBack,
  ButtonNext,
  Image,
  Slide,
  Slider,
} from 'pure-react-carousel';

import { Text } from 'components';

import { placeholder } from 'assets';

import { useMediaQuery, useToggle } from 'hooks';

import { MQ_PHONE_ONLY } from 'utils/mediaQuery';

import { StyledCarouselProvider, Nav, ErrorImageContainer } from './styles';
import { GalleryCarouselProps } from './types';
import { ZoomModal } from './ZoomModal';

import 'pure-react-carousel/dist/react-carousel.es.css';

export default function GalleryCarousel({ images }: GalleryCarouselProps) {
  const [isZoomModalOpen, toggleZoomModal] = useToggle();
  const [zoomedImage, setZoomedImage] = useState(0);
  const isPhoneOnly = useMediaQuery(MQ_PHONE_ONLY);

  function zoomImage(index: number) {
    setZoomedImage(index);
    toggleZoomModal();
  }

  const zoomNextImage = useCallback(() => {
    setZoomedImage(prevZoomedImageIndex => {
      const lastImagesIndex = images.length - 1;
      if (prevZoomedImageIndex === lastImagesIndex) return 0;
      return prevZoomedImageIndex + 1;
    });
  }, [images]);

  const zoomPreviousImage = useCallback(() => {
    setZoomedImage(prevZoomedImageIndex => {
      const lastImagesIndex = images.length - 1;
      if (prevZoomedImageIndex === 0) return lastImagesIndex;
      return prevZoomedImageIndex - 1;
    });
  }, [images]);

  const showNav =
    (isPhoneOnly && images.length > 2) || (!isPhoneOnly && images.length > 3);

  return (
    <StyledCarouselProvider
      naturalSlideWidth={16}
      naturalSlideHeight={9}
      totalSlides={images.length}
      visibleSlides={isPhoneOnly ? 2 : 3}
    >
      <Slider>
        {images.map((image, index) => (
          <Slide key={image.id} index={index} onClick={() => zoomImage(index)}>
            <Image
              src={image.url}
              alt={image.description}
              renderError={() => (
                <ErrorImageContainer>
                  <img src={placeholder} alt={image.description} />
                  <Text variant="body-small-heavy" color="danger-dark">
                    Erro ao carregar a imagem
                  </Text>
                </ErrorImageContainer>
              )}
              hasMasterSpinner={false}
            />
          </Slide>
        ))}
      </Slider>

      {showNav && (
        <Nav>
          <ButtonBack>
            <RiArrowLeftSLine size="1.25rem" />
          </ButtonBack>

          <ButtonNext>
            <RiArrowRightSLine size="1.25rem" />
          </ButtonNext>
        </Nav>
      )}

      <ZoomModal
        isOpen={isZoomModalOpen}
        onRequestClose={toggleZoomModal}
        zoomPreviousImage={zoomPreviousImage}
        zoomNextImage={zoomNextImage}
        showNextPrevControls={images.length > 1}
        image={images[zoomedImage]}
      />
    </StyledCarouselProvider>
  );
}
