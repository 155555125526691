import { TabsProps as ReactTabsProps } from 'react-tabs';

import clsx from 'clsx';

import { StyledTabs } from './styles';
import { Tab } from './Tab';
import { TabList } from './TabList';
import { TabPanel } from './TabPanel';

type TabsProps = Omit<
  ReactTabsProps,
  | 'disabledTabClassName'
  | 'selectedTabClassName'
  | 'selectedTabPanelClassName'
  | 'forceRenderTabPanel'
  | 'as'
>;

const Tabs = ({ children, className, ...rest }: TabsProps) => {
  return (
    <StyledTabs
      forceRenderTabPanel
      className={clsx('react-tabs', className)}
      {...rest}
    >
      {children}
    </StyledTabs>
  );
};

Tabs.tabRole = 'Tabs';

Tabs.Tab = Tab;
Tabs.TabList = TabList;
Tabs.TabPanel = TabPanel;

export default Tabs;
