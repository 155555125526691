import styled from 'styled-components';

import { color } from 'utils/cvar';

export const PositionsInputs = styled.div`
  display: grid;
  gap: 0.75rem;
  grid-template-columns: repeat(auto-fill, minmax(3.5rem, 1fr));

  > label {
    position: relative;
    height: 3.5rem;
    cursor: pointer;
    display: grid;
    place-items: center;

    input {
      position: absolute;
      appearance: none;
      width: 100%;
      height: 100%;
      background: red;
      background-color: ${color('input-background')};
      border: 1px solid ${color('line')};
      z-index: -1;
    }

    input:checked {
      background-color: ${color('primary-default')};
      border-color: ${color('primary-default')};

      & ~ .position-number {
        color: ${color('white')};
      }
    }
  }
`;
