import {
  RiCheckboxCircleLine,
  RiCloseCircleLine,
  RiCloseLine,
  RiErrorWarningLine,
  RiInformationLine,
} from 'react-icons/ri';

import { Button, Text } from 'components';

import { StyledContentModal } from './styles';
import { ActionButtonColor, ContentColorMap, ContentModalProps } from './types';

StyledContentModal.setAppElement('#root');

const variantIconMap = {
  default: RiInformationLine,
  primary: RiInformationLine,
  danger: RiCloseCircleLine,
  success: RiCheckboxCircleLine,
  warning: RiErrorWarningLine,
};

const colorMap: ContentColorMap = {
  default: 'neutral',
  primary: 'primary',
  danger: 'danger',
  success: 'success',
  warning: 'tertiary',
};

export default function ContentModal({
  isOpen,
  onRequestClose,
  variant = 'default',
  size = 'medium',
  icon: CustomIcon,
  children,
  title,
  cancelButtonText,
  disabledCloseButtons,
  showCancelButton,
  actionButton: { text: actionButtonText, ...restActionButton },
  ...rest
}: ContentModalProps) {
  const Icon = CustomIcon || variantIconMap[variant];
  const colorVariant = colorMap[variant];

  return (
    <StyledContentModal
      overlayClassName="ReactModal__Overlay"
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      variant={colorVariant}
      size={size}
      {...rest}
    >
      <header>
        <div>
          <Icon size="1.5rem" />
          <Text as="h2" variant="heading-x-small">
            {title}
          </Text>
        </div>

        <Button
          icon={RiCloseLine}
          color="neutral"
          size="small"
          variant="ghost"
          className="close-modal"
          disabled={disabledCloseButtons}
          onClick={onRequestClose}
        />
      </header>

      <main>{children}</main>

      <footer>
        {showCancelButton && (
          <Button
            size="small"
            variant="ghost"
            color="neutral"
            disabled={disabledCloseButtons}
            onClick={onRequestClose}
          >
            {cancelButtonText || 'Cancelar'}
          </Button>
        )}
        <Button
          size="small"
          color={colorVariant as ActionButtonColor}
          {...restActionButton}
        >
          {actionButtonText}
        </Button>
      </footer>
    </StyledContentModal>
  );
}
