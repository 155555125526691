import styled from 'styled-components';

export const Container = styled.nav`
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 1rem;

  .main-nav {
    display: flex;
  }
`;
