import { useContext, useState, useCallback, useEffect } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { RiAddLine } from 'react-icons/ri';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { AlertModal, Button, Dropzone, InputText, Text } from 'components';

import { useToggle } from 'hooks';
import { useCreateNewsImage, useDeleteNewsImage } from 'hooks/api';
import { News } from 'hooks/api/news/types';

import { addGalleryItemsValidator } from 'shared/validators/news';

import { UpdateNewsContext } from '..';

import { FieldsetGrid } from '../styles';
import { FlatError } from '../types';
import { OldGalleryUpdateForm } from './OldGalleryUpdateForm';
import { Container } from './styles';
import { NewGalleryFormData } from './types';

export function GalleryFormSection() {
  const { news, registerGetter, unregisterGetter, refetchNews } =
    useContext(UpdateNewsContext);
  const queryClient = useQueryClient();

  const [isConfirmDeleteImageOpen, toggleConfirmDeleteImage] = useToggle();
  const [isConfirmRemoveGalleryOpen, toggleConfirmRemoveGallery] = useToggle();

  const [imageIdToDelete, setImageIdToDelete] = useState('');
  const [hasGallery, setHasGallery] = useState(!!news.gallery.length);
  const [isRemovingGallery, setIsRemovingGallery] = useState(false);

  const deleteNewsImageMutation = useDeleteNewsImage();
  const createNewsImageMutation = useCreateNewsImage();

  const {
    handleSubmit,
    register,
    getValues,
    control,
    formState: { errors, isSubmitting },
  } = useForm<NewGalleryFormData>({
    resolver: addGalleryItemsValidator,
    reValidateMode: 'onBlur',
  });

  const {
    fields: newGalleryFields,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'gallery',
  });

  const onSubmitNewGallery = handleSubmit(async ({ gallery }) => {
    const updatedIndexes: number[] = [];

    const results = await Promise.allSettled(
      gallery.map(async (item, index) => {
        await createNewsImageMutation.mutateAsync({
          newsId: news.id,
          image: item.image.file,
          author: item.author,
          description: item.description,
        });
        updatedIndexes.push(index);
      })
    );

    const atLeastOneSuccess = results.some(r => r.status === 'fulfilled');
    const atLeastOneError = results.some(r => r.status === 'rejected');

    if (atLeastOneError)
      toast.warning('Um erro ocorreu ao adicionar uma ou mais imagens');
    else toast.success('Notícia cadastrada com sucesso');

    if (atLeastOneSuccess) refetchNews();
    if (updatedIndexes.length) remove(updatedIndexes);
  });

  function removeGalleryIfEmpty() {
    if (!news.gallery.length && newGalleryFields.length === 1)
      setHasGallery(false);
  }

  async function deleteGalleryItem() {
    try {
      await deleteNewsImageMutation.mutateAsync(imageIdToDelete);

      queryClient.setQueryData<News>(['news', news.id], {
        ...news,
        gallery: news.gallery.filter(item => item.id !== imageIdToDelete),
      });

      unregisterGetter('gallery_old', imageIdToDelete);
      toast.success('Imagem excluida com sucesso');
      removeGalleryIfEmpty();
      toggleConfirmDeleteImage();
    } catch {
      toast.error(
        'Não foi possível excluir a imagem. Por favor tente novamente'
      );
    }
  }

  async function deleteAllGalleryItems() {
    setIsRemovingGallery(true);

    const requests = news.gallery.map(item => ({
      run: async () => {
        await deleteNewsImageMutation.mutateAsync(item.id);
        unregisterGetter('gallery_old', item.id);
      },
    }));

    const results = await Promise.allSettled(requests.map(req => req.run()));

    //! SUCCESS
    const allUpdatesWellSucceeded = results.every(
      ({ status }) => status === 'fulfilled'
    );
    if (allUpdatesWellSucceeded) {
      queryClient.setQueryData(['news', news.id], { ...news, gallery: [] });

      toast.success('Imagem excluida com sucesso');
      setHasGallery(false);
      remove(); // clear RHF field array
    }

    //! ERROR
    const someUpdateWithError = results.some(
      ({ status }) => status === 'rejected'
    );
    if (someUpdateWithError) {
      toast.error('Não foi possível excluir uma ou mais imagens');
      refetchNews(); // We refetch the news since some images was deleted
    }

    removeGalleryIfEmpty();
    toggleConfirmDeleteImage();
    setIsRemovingGallery(false);
  }

  function handleRemoveGalleryItem(index: number) {
    remove(index);
    removeGalleryIfEmpty();
  }

  function addGalleryItem() {
    append({ image: undefined, description: '', author: '' });
  }

  const onRequestDeleteImage = useCallback(
    (id: string) => {
      setImageIdToDelete(id);
      toggleConfirmDeleteImage();
    },
    [toggleConfirmDeleteImage]
  );

  useEffect(() => {
    registerGetter('gallery_new', getValues);
  }, [getValues, registerGetter]);

  if (!hasGallery)
    return (
      <Container>
        <Button
          size="small"
          color="neutral"
          onClick={() => {
            addGalleryItem();
            setHasGallery(true);
          }}
        >
          Adicionar galeria
        </Button>
      </Container>
    );

  return (
    <Container>
      <Button
        size="small"
        color="danger"
        onClick={() => {
          if (news.gallery.length) toggleConfirmRemoveGallery();
          else {
            remove();
            setHasGallery(false);
          }
        }}
      >
        Remover galeria
      </Button>

      {!!news.gallery.length && (
        <Text variant="heading-small" as="h2" mt="2rem">
          Imagens cadastradas
        </Text>
      )}

      {/* FORMS FOR ALL REGISTERED GALLERY IMAGES  */}
      {news.gallery.map(item => (
        <OldGalleryUpdateForm
          key={item.id}
          data={item}
          onRequestDelete={onRequestDeleteImage}
        />
      ))}

      {/* PRESENT INFO ABOUT NEW GALLERY ITEMS BASED ON HOW MUCH ITEMS WE HAVE */}
      {newGalleryFields.length === 0 ? (
        <div className="gallery__actions gallery__actions--master">
          <Button
            icon={RiAddLine}
            color="secondary"
            size="small"
            disabled={isSubmitting}
            onClick={addGalleryItem}
          >
            Nova imagem
          </Button>
        </div>
      ) : (
        <form onSubmit={onSubmitNewGallery}>
          {!!news.gallery.length && <hr />}
          <Text variant="heading-small" as="h2" mt="2rem">
            Novas imagens
          </Text>

          {newGalleryFields.map((field, index) => (
            <div className="gallery-item" key={field.id}>
              <FieldsetGrid as="div">
                <Controller
                  control={control}
                  name={`gallery.${index}.image`}
                  defaultValue={field.image}
                  render={({ field: { onChange, ...rest } }) => (
                    <Dropzone
                      label="Foto"
                      error={
                        (errors.gallery?.[index]?.image as unknown as FlatError)
                          ?.message
                      }
                      readOnly={isSubmitting}
                      maxSize={1024 * 1024 * 10}
                      onChange={([file]) => onChange(file)}
                      {...rest}
                    />
                  )}
                />
                <div>
                  <InputText
                    label="Descrição da foto"
                    error={errors.gallery?.[index]?.description?.message}
                    defaultValue={field.description}
                    readOnly={isSubmitting}
                    {...register(`gallery.${index}.description`)}
                  />
                  <InputText
                    label="Autor da foto"
                    error={errors.gallery?.[index]?.author?.message}
                    containerClassName="author-input-container"
                    defaultValue={field.description}
                    readOnly={isSubmitting}
                    {...register(`gallery.${index}.author`)}
                  />
                </div>
              </FieldsetGrid>

              <div className="gallery__actions">
                <Button
                  color="danger"
                  size="small"
                  isLoading={isSubmitting}
                  onClick={() => handleRemoveGalleryItem(index)}
                >
                  Excluir
                </Button>
              </div>
            </div>
          ))}

          {/* BUTTON TO ADD NEW IMAGE ITEMS */}
          <div className="gallery__actions gallery__actions--master">
            <Button type="submit" size="small" isLoading={isSubmitting}>
              Salvar
            </Button>

            <Button
              icon={RiAddLine}
              color="secondary"
              size="small"
              isLoading={isSubmitting}
              onClick={addGalleryItem}
            >
              Nova imagem
            </Button>
          </div>
        </form>
      )}

      {/* MODAL TO CONFIRM DELETION OF AN ALREADY REGISTERED GALLERY IMAGE */}
      <AlertModal
        isOpen={isConfirmDeleteImageOpen}
        onRequestClose={toggleConfirmDeleteImage}
        title="Excluir imagem"
        message="Deseja realmente excluir esta imagem?"
        variant="danger"
        showCancelButton
        disabledCloseButtons={deleteNewsImageMutation.isLoading}
        shouldCloseOnOverlayClick={!deleteNewsImageMutation.isLoading}
        actionButton={{
          text: 'Excluir',
          onClick: deleteGalleryItem,
          isLoading: deleteNewsImageMutation.isLoading,
        }}
      />

      {/* MODAL TO CONFIRM DELETION OF AN ALREADY REGISTERED GALLERY IMAGE */}
      <AlertModal
        isOpen={isConfirmRemoveGalleryOpen}
        onRequestClose={toggleConfirmRemoveGallery}
        title="Remover galeria"
        message="Deseja realmente remover a galeria de imagens?"
        variant="danger"
        showCancelButton
        disabledCloseButtons={isRemovingGallery}
        shouldCloseOnOverlayClick={!isRemovingGallery}
        actionButton={{
          text: 'Excluir',
          onClick: deleteAllGalleryItems,
          isLoading: isRemovingGallery,
        }}
      >
        <Text as="small" variant="body-small" color="label" mt="1rem">
          Esta ação não pode ser revertida e excluirá todas as imagens da
          galeria.
        </Text>
      </AlertModal>
    </Container>
  );
}
