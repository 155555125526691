import styled from 'styled-components';

import { color } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const Container = styled.aside`
  > * + * {
    margin-top: var(--items-gap, 2rem);
  }

  ${mediaQuery.max('43.99em')} {
    margin-top: var(--items-gap, 1rem);

    > :first-child {
      border-top: 1px solid ${color('line')};
      padding-top: calc(2 * var(--items-gap, 1rem));
    }
  }

  ${mediaQuery.min('44em')} {
    > * + * {
      margin-top: var(--items-gap, 1.5rem);
    }
  }
`;
