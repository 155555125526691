import { NavLink as RRDNavLink } from 'react-router-dom';

import styled, { css } from 'styled-components';

import { color, cvar, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

const searchBarOpenStyles = css`
  .logo-link,
  .mobile-menu__open-btn {
    margin: 0;
    width: 0;
    padding: 0;
    opacity: 0;
    transition: opacity 50ms, padding 100ms;
  }
`;

export const StyledHeader = styled.header<{ isSearchBarOpen: boolean }>`
  background-color: ${color('primary-default')};
  position: sticky;
  top: 0;
  z-index: ${cvar('z-index-header')};

  ul {
    list-style: none;
  }

  .content {
    max-width: 85.375rem; //? 1366px (1rem = 16px)
    padding: 0 1.5rem;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  /* Logo */
  .logo-link {
    font-size: 0;

    &:focus {
      outline: 2px solid ${color('primary-light')};
      outline-offset: 0.375rem;
    }
  }

  .menu-trigger {
    display: flex;

    > * + * {
      margin-left: 0.75rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    ${p => p.isSearchBarOpen && searchBarOpenStyles}

    .content {
      padding: 0 1rem;
    }
  }

  /* Media between tablet portrait and landscape */
  ${mediaQuery.between('37.5em', '56.24em')} {
    .menu-trigger {
      flex: 1;

      > div {
        margin: 0 auto;
      }
    }
  }
`;

export const NavLink = styled(RRDNavLink)`
  display: flex;
  align-items: center;
  padding: 0.75rem 1.5rem;
  font: ${font('body-normal-heavy')};
  color: ${color('background')};
  background-color: transparent;
  text-align: left;

  &:hover {
    background-color: ${color('primary-dark')};
  }

  &:focus {
    outline: 2px solid ${color('primary-light')};
    outline-offset: -2px;
  }

  &.active {
    color: ${color('secondary-default')};
  }

  ${mediaQuery.tabletLandscapeUp} {
    padding: 0.75rem;
  }
`;
