import styled from 'styled-components';

import { color } from 'utils/cvar';

export const Form = styled.form`
  background-color: ${color('white')};
  border: 1px solid ${color('line')};
  padding: 1.5rem;
  width: min(25rem, 100%);

  display: flex;
  flex-direction: column;

  button[type='submit'] {
    align-self: flex-end;
    margin-top: 1rem;
  }
`;
