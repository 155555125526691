import { RiArrowLeftSLine, RiArrowRightSLine } from 'react-icons/ri';
import ReactPaginate from 'react-paginate';

import { useMediaQuery } from 'hooks';

import { MQ_PHONE_ONLY } from 'utils/mediaQuery';

import { Container } from './styles';
import { PaginationProps } from './types';

export default function Pagination(props: PaginationProps) {
  const isPhoneOnly = useMediaQuery(MQ_PHONE_ONLY);

  return (
    <Container className="pagination">
      <ReactPaginate
        pageRangeDisplayed={isPhoneOnly ? 2 : 3}
        marginPagesDisplayed={isPhoneOnly ? 0 : 1}
        previousLabel={<RiArrowLeftSLine size="1.25rem" />}
        nextLabel={<RiArrowRightSLine size="1.25rem" />}
        breakLabel="..."
        previousLinkClassName="icon-link"
        nextLinkClassName="icon-link"
        {...props}
      />
    </Container>
  );
}
