import { useState, useRef } from 'react';

import { ContentLoading, Pagination, Text } from 'components';

import { formatDate } from 'lib';

import { usePolls } from 'hooks/api';

import { formatAlternativesAsChartData } from 'utils/formatAlternativesAsChartData';

import { PollWithChart } from '../PollWithChart';
import { PollsContainer } from './styles';

export function History() {
  const componentContainerRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);

  const { data: polls, isLoading } = usePolls({
    query: { page, limit: 5, status: 'finished' },
    select: ({ data, ...rest }) => ({
      ...rest,
      data: data.map(poll => {
        const chart = formatAlternativesAsChartData(poll.alternatives);
        const startDate = formatDate(new Date(poll.startDate), 'P');
        const endDate = formatDate(new Date(poll.endDate as string), 'P');
        return { ...poll, startDate, endDate, chart };
      }),
    }),
  });

  function onPageChange({ selected }: { selected: number }) {
    setPage(selected + 1);
    const componentOffsetTop = componentContainerRef.current?.offsetTop;
    const top = componentOffsetTop ? componentOffsetTop - 100 : 0;
    window.scrollTo({ top, behavior: 'smooth' });
  }

  if (isLoading) return <ContentLoading label="Carregando enquetes" />;

  return (
    <div ref={componentContainerRef}>
      <Text as="h2" variant="heading-medium" mb="2rem">
        Enquetes anteriores
      </Text>

      {polls &&
        (polls.total > 0 ? (
          <PollsContainer>
            {polls.data.map(poll => (
              <PollWithChart key={poll.id} poll={poll} />
            ))}

            <Pagination
              pageCount={polls.totalPages}
              onPageChange={onPageChange}
              forcePage={page - 1}
            />
          </PollsContainer>
        ) : (
          <div className="no-data-container">
            <Text variant="heading-x-small">
              Nenhuma há enquetes anteriores
            </Text>
          </div>
        ))}
    </div>
  );
}
