import { useMutation, useQuery } from 'react-query';

import api from 'services/api';

import {
  NewsHighlights,
  NewsHighlightType,
  UseNewsHighlights,
  UseNewsHighlightTypes,
  UseDeleteNewsFromHighlightsProps,
  UseRepositionNewsHighlightProps,
  RepositionData,
  PromoteNewsData,
  UsePromoteNewsToHighlightProps,
} from './types';

const HIGHLIGHT_TYPES_KEY = 'news_highlight_types';
const HIGHLIGHTS_KEY = 'news_highlight';

// *********************************************************
// ***                  HIGHLIGHT TYPES                  ***
// *********************************************************
const getNewsHighlightTypes = async () => {
  const { data } = await api.get<NewsHighlightType[]>('highlights/types');
  return data;
};

export function useNewsHighlightTypes<T = NewsHighlightType[]>(
  props?: UseNewsHighlightTypes<T>
) {
  return useQuery(HIGHLIGHT_TYPES_KEY, getNewsHighlightTypes, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 60, // ? 1 hour,
    ...props,
  });
}

// **********************************************************
// ***                     HIGHLIGHTS                     ***
// **********************************************************
const getNewsHighlights = async () => {
  const { data } = await api.get<NewsHighlights>('highlights');
  return data;
};

export function useNewsHighlights<T = NewsHighlights>(
  props?: UseNewsHighlights<T>
) {
  return useQuery(HIGHLIGHTS_KEY, getNewsHighlights, {
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 10, // ? 10 minutes,
    ...props,
  });
}

// *********************************************************
// ***             PROMOTE NEWS TO HIGHLIGHT             ***
// *********************************************************
const promoteNewsToHighlight = async ({ newsId, ...data }: PromoteNewsData) => {
  await api.patch(`highlights/${newsId}`, data);
};

export function usePromoteNewsToHighlight(
  props?: UsePromoteNewsToHighlightProps
) {
  return useMutation(promoteNewsToHighlight, props);
}

// **********************************************************
// ***               REMOVE FROM HIGHLIGHTS               ***
// **********************************************************
const deleteNewsFromHighlights = async (id: string) => {
  await api.delete(`highlights/${id}`);
};

export function useDeleteNewsFromHighlights(
  props?: UseDeleteNewsFromHighlightsProps
) {
  return useMutation(deleteNewsFromHighlights, { ...props });
}

// **********************************************************
// ***                REPOSITION HIGHLIGHT                ***
// **********************************************************
const repositionNewsHighlight = async (data: RepositionData) => {
  const { highlightId, position } = data;
  await api.patch(`highlights/${highlightId}/reposition`, { position });
};

export function useRepositionNewsHighlight(
  props?: UseRepositionNewsHighlightProps
) {
  return useMutation(repositionNewsHighlight, { ...props });
}
