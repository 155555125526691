import styled from 'styled-components';

import { color, font } from 'utils/cvar';

export const Container = styled.div`
  background-color: ${color('white')};
  border: 1px solid ${color('line')};
  padding: 1rem;

  .about {
    max-width: 38rem;
    text-align: center;
    margin: 0 auto 2rem;
  }

  h2 {
    font: ${font('heading-x-small')};
    margin-bottom: 0.25rem;
  }
`;
