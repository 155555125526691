import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  .card-grid {
    max-width: 60rem;
    margin-top: 1.5rem;
    display: grid;
    gap: 1rem;
  }

  .card-wrapper {
    position: relative;

    &__action--delete {
      position: absolute;
      top: 1px;
      right: 1px;
    }
  }

  .card {
    height: 100%;

    &__description {
      margin-bottom: 0;
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    .card-grid {
      grid-template-columns: repeat(auto-fit, minmax(17rem, 1fr));
      gap: 1.5rem;
    }
  }
`;
