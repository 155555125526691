import { forwardRef } from 'react';
import ReactSelect from 'react-select';

import clsx from 'clsx';

import { Container } from './styles';
import { ReactSelectProps, SelectComponent, SelectProps } from './types';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Select: SelectComponent = forwardRef<any, SelectProps>(
  (
    {
      label,
      error,
      name,
      containerStyle,
      containerClassName,
      removeMarginTop,
      removeErrorSlotMargin,
      className,
      placeholder = 'Selecione uma opção',
      ...rest
    },
    ref
  ) => {
    const props: ReactSelectProps = {
      inputId: name,
      placeholder,
      className: clsx('select-container', className),
      noOptionsMessage: () => 'Sem opções',
      loadingMessage: () => 'Carregando...',
      defaultValue: rest.isMulti ? [] : null,
      ...rest,
      theme: theme => ({
        ...theme,
        borderRadius: 0,
      }),
      classNamePrefix: 'react-select',
    };

    return (
      <Container
        style={containerStyle}
        className={containerClassName}
        removeMarginTop={removeMarginTop}
        removeErrorSlotMargin={removeErrorSlotMargin}
        hasError={!!error}
      >
        {label && <label htmlFor={name}>{label}</label>}

        <ReactSelect ref={ref} name={name} {...props} />

        {error && <span>{error}</span>}
      </Container>
    );
  }
);

export default Select;
