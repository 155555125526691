import styled, { css } from 'styled-components';

import { color, font } from 'utils/cvar';

import { ContainerProps } from './types';

export const Container = styled.div<ContainerProps>`
  ${p =>
    !p.removeMarginTop &&
    css`
      margin-top: 1rem;
    `}

  padding-bottom: ${p => (!p.hasError ? '2.125rem' : '0')};

  ${p =>
    p.hasError &&
    css`
      .tox-tinymce {
        border-color: ${color('danger-default')};
      }

      > span {
        display: inline-block;
        margin-top: 0.75rem;
        font: ${font('body-small')};
        color: ${color('danger-default')};
      }
    `}
`;
