import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  max-width: 72rem;
  margin: 0 auto 3rem;

  section + section {
    margin-top: 4rem;
  }

  .header {
    margin-bottom: 2rem;
  }

  .no-data-container {
    display: grid;
    place-items: center;
    height: 10vh;
  }

  ${mediaQuery.phoneOnly} {
    .header button {
      margin-top: 1rem;
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
`;
