import styled from 'styled-components';

import { color } from 'utils/cvar';

import { MenuContainer } from '../styles';

export const Container = styled.button`
  border: 0;
  background-color: transparent;

  display: flex;
  align-items: center;
  width: 100%;

  ${MenuContainer}.normal & {
    padding: 0.75rem 1rem;
  }

  ${MenuContainer}.condensed & {
    padding: 0.25rem 1rem;
  }

  span {
    flex: 1;
    text-align: left;
  }

  .icon {
    &-left {
      margin-right: 0.5rem;
    }

    &-right {
      margin-left: 1.5rem;
    }
  }

  &:hover {
    background: ${color('primary-lighter')};
  }
`;
