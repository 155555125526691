import styled, { keyframes } from 'styled-components';

import { color } from 'utils/cvar';

import { SpinnerContainerProps } from './types';

const rotate = keyframes`
  to { transform: rotate(1turn); }
`;

export const SpinnerContainer = styled.span<SpinnerContainerProps>`
  display: inline-flex;

  svg {
    color: ${p => color(p.$color)};
    animation: ${rotate} 750ms linear infinite;
  }
`;
