import { useEffect } from 'react';

export default function WeatherWidget() {
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = 'weatherwidget-io-js';
    script.src = 'https://weatherwidget.io/js/widget.min.js';

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <div>
      <a
        className="weatherwidget-io"
        href="https://forecast7.com/pt/n22d25n43d71/valenca/"
        data-label_1="VALENÇA"
        data-font="Noto Sans"
        data-icons="Climacons Animated"
        data-days="5"
        data-theme="weather_one"
      >
        VALENÇA
      </a>
    </div>
  );
}
