import { forwardRef, useImperativeHandle, useState } from 'react';
import { useQueryClient } from 'react-query';
import { toast } from 'react-toastify';

import { AlertModal } from 'components';

import { useToggle } from 'hooks';
import { useDeleteNewsFromHighlights } from 'hooks/api';

type News = { id: string; title: string };
type NewsWithPosition = News & { position: number };
type ConfirmRemoveModalRef = { open: (news: News, position: number) => void };
type ConfirmRemoveModalProps = {
  title: string;
  onConfirm?: (position: number) => void;
};

const ConfirmRemoveModal = forwardRef<
  ConfirmRemoveModalRef,
  ConfirmRemoveModalProps
>(({ title, onConfirm }, ref) => {
  const [news, setNews] = useState<NewsWithPosition>();
  const queryClient = useQueryClient();

  const [isOpen, onToggle] = useToggle();
  useImperativeHandle(ref, () => ({
    open: (data, position) => {
      onToggle();
      setNews({ ...data, position });
    },
  }));

  const { isLoading, mutateAsync } = useDeleteNewsFromHighlights({
    onSuccess: () => {
      toast.success('Destaque removido com sucesso');
      queryClient.invalidateQueries(['news_infinite', { homePage: true }]);
      onToggle();
    },
  });

  return (
    <AlertModal
      isOpen={isOpen}
      onRequestClose={onToggle}
      disabledCloseButtons={isLoading}
      shouldCloseOnOverlayClick={!isLoading}
      shouldCloseOnEsc={!isLoading}
      showCancelButton
      variant="danger"
      title={title}
      actionButton={{
        text: 'Remover',
        isLoading,
        onClick: async () => {
          if (!news) return;
          await mutateAsync(news.id);
          onConfirm?.(news.position);
        },
      }}
    >
      <p>
        Deseja realmente remover a noticia intitulada &quot;
        <strong>{news?.title}</strong>&quot; dos destaques?
      </p>
    </AlertModal>
  );
});

export type { ConfirmRemoveModalRef };
export default ConfirmRemoveModal;
