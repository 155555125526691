import styled from 'styled-components';

import { color } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const ActivePollContainer = styled.div`
  display: grid;
  gap: 1rem;

  .info {
    min-width: 20rem;

    &__question {
      background-color: ${color('white')};
      border: 1px solid ${color('line')};
      padding: 1rem;
      margin-bottom: 1rem;
    }

    &__complementary {
      display: flex;
      gap: 1rem;

      p {
        flex: 1;

        strong {
          font-weight: 600;
        }
      }
    }
  }

  .chart {
    background-color: ${color('white')};
    border: 1px solid ${color('line')};
    padding: 1rem 0;
  }

  ${mediaQuery.tabletPortraitUp} {
    button {
      justify-self: start;
    }
  }

  ${mediaQuery.tabletLandscapeUp} {
    display: grid;
    grid-template-columns: 1fr 1.6fr;
    grid-template-rows: auto 1fr;
    align-items: flex-start;
    gap: 1.5rem;

    .info {
      &__complementary {
        flex-direction: column;
        gap: 0.5rem;
      }
    }

    .chart {
      grid-row-end: span 2;
      height: 100%;
    }
  }
`;
