import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  max-width: 40rem;
  margin: 1.5rem auto 6rem;
  padding: 0 1rem;

  display: grid;
  place-items: center;
  align-content: center;
  gap: 1rem;
  min-height: 40vh;

  ${mediaQuery.min('44em')} {
    padding: 0 1.5rem;
  }
`;
