import * as styled from 'styled-components';

import { cvar, color } from 'utils/cvar';

import variables from './variables';

//* Global Styles
export default styled.createGlobalStyle`
  :root {
    ${variables}
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  body {
    background: ${color('background')};
    color: ${color('body')};
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  body,
  input,
  textarea,
  select,
  button {
    line-height: 1.5;
    font-size: 1rem;
    font-family: ${cvar('font-family')};
    font-weight: 400;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${color('title-active')};
  }

  button {
    cursor: pointer;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  .ReactModal {
    &__Overlay {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: ${color('overlay-dark-50')};
      z-index: ${cvar('z-index-modal')};
    }

    &__Body {
      &--open {
        overflow: hidden;
      }
    }
  }

  // ? Scrollbar
  @media (hover: hover) {
    ::-webkit-scrollbar {
      width: 0.75rem;
      height: 0.75rem;

      &-thumb {
        background: hsla(230, 83%, 16%, 0.5);

        &:hover {
          background: hsla(230, 83%, 16%, 0.7);
        }
      }

      &-track {
        background: hsla(230, 83%, 16%, 0.15);
      }
    }
  }

  .body-overflow-hidden {
    overflow: hidden;
  }
`;
