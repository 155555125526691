import { ComponentType } from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { PublicLayout } from 'pages/layout';

type PublicRouteProps = Omit<RouteProps, 'children' | 'render'> & {
  component: ComponentType;
};

export default function PublicRoute({
  component: Component,
  ...rest
}: PublicRouteProps) {
  return (
    <Route
      {...rest}
      render={props => (
        <PublicLayout>
          <Component {...props} />
        </PublicLayout>
      )}
    />
  );
}
