import { ReactNode } from 'react';

import Sidebar from './Sidebar';
import { Container } from './styles';

export default function AdminLayout({ children }: { children: ReactNode }) {
  return (
    <Container>
      <Sidebar />
      <main>{children}</main>
    </Container>
  );
}
