import {
  TabList as ReactTabList,
  TabListProps as ReactTabListProps,
} from 'react-tabs';

import clsx from 'clsx';

type TabListProps = Omit<ReactTabListProps, 'ref'>;

export const TabList = ({ children, className, ...rest }: TabListProps) => (
  <ReactTabList className={clsx('react-tabs__tab-list', className)} {...rest}>
    {children}
  </ReactTabList>
);

TabList.tabsRole = 'TabList';
