import { useState } from 'react';
import Chart from 'react-apexcharts';

import ptBR from 'apexcharts/dist/locales/pt-br.json';
import { debounce, merge } from 'lodash';

import { useEventListener } from 'hooks';

import { PieChartProps, ResponsiveHeight } from './type';

const COLORS = [
  '#07134d',
  '#00abe3',
  '#fcbc12',
  '#e61a2e',
  '#09a43e',
  '#29324f',
];

function toPixels(value: number) {
  return `${value}px`;
}

function getChartHeight(responsiveHeight?: ResponsiveHeight) {
  const { clientWidth } = document.body;

  if (responsiveHeight) {
    const { largerBreakpointHeight, breakpoints } = responsiveHeight;
    const breakpoint = breakpoints.find(([bp]) => clientWidth < bp);

    if (breakpoint) return toPixels(breakpoint[1]);
    return toPixels(largerBreakpointHeight);
  }

  if (clientWidth < 600) return '500px';
  if (clientWidth < 800) return '350px';
  return '300px';
}

const defaultOptions: NonNullable<PieChartProps['options']> = {
  colors: COLORS,
  stroke: { show: false },
  chart: {
    animations: { speed: 600 },
    background: 'transparent',
    locales: [ptBR],
    defaultLocale: 'pt-br',
    fontFamily: '"Work Sans", sans-serif',
    foreColor: '#29324f',
  },
  legend: {
    position: 'right',
    horizontalAlign: 'center',
    fontSize: '15px',
    markers: { width: 14, height: 14, offsetX: -2, offsetY: 2 },
    itemMargin: { horizontal: 8, vertical: 4 },
  },
  tooltip: {
    style: { fontFamily: '"Work Sans", sans-serif', fontSize: '14px' },
  },
  plotOptions: { pie: { dataLabels: { offset: -15 } } },
};

export default function PieChart({
  data,
  labels,
  options,
  responsiveHeight,
}: PieChartProps) {
  const [chartHeight, setChartHeight] = useState(
    getChartHeight(responsiveHeight)
  );

  useEventListener(
    'resize',
    debounce(() => setChartHeight(getChartHeight(responsiveHeight)), 500)
  );

  const chartOptions = merge(defaultOptions, options);

  return (
    <Chart
      height={chartHeight}
      type="pie"
      series={data}
      options={{ ...chartOptions, labels }}
    />
  );
}
