import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

export const FiltersContainer = styled.div`
  display: grid;
  gap: 1rem;
  margin-bottom: 2rem;

  .select {
    min-width: 15rem;
  }

  ${mediaQuery.between('56.25em', '64.99em')} {
    grid-template-columns: 1fr;
  }

  ${mediaQuery.min('65em')} {
    grid-template-columns: 2fr 1fr;
  }
`;
