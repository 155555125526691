import { Link } from 'react-router-dom';

import { Container } from './styles';
import { PolymorphicDropdownItem, DropdownItemProps } from './types';

export const DropdownItem: PolymorphicDropdownItem = ({
  children,
  iconLeft: IconLeft,
  iconRight: IconRight,
  ...rest
}: DropdownItemProps) => {
  // eslint-disable-next-line no-nested-ternary
  const linkComponent = 'to' in rest ? Link : rest.href ? 'a' : 'button';

  return (
    <Container as={linkComponent} {...rest}>
      {IconLeft && <IconLeft size="1.25rem" className="icon-left" />}
      <span>{children}</span>
      {IconRight && <IconRight size="1.25rem" className="icon-right" />}
    </Container>
  );
};
