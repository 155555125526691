import { useEffect, createContext, useCallback, useState } from 'react';
import { RiCloseLine, RiMenu2Line } from 'react-icons/ri';
import { NavLink, useLocation } from 'react-router-dom';

import clsx from 'clsx';

import { Button, Can } from 'components';

import { LogoLightSVG } from 'assets';

import { useDisclosure, useMediaQuery } from 'hooks';

import { HOME } from 'routes/PATHS';
import { MQ_TABLET_LANDSCAPE_UP } from 'utils/mediaQuery';

import SIDEBAR_NAV_LINKS from './SIDEBAR_NAV_LINKS';
import SidebarDropdown from './SidebarDropdown';
import SidebarLink from './SidebarLink';
import { MobileHeader, Backdrop, Aside, MainNavigation } from './styles';
import { RenderMainNavItemFn, SidebarContextData, VoidFn } from './types';
import UserMenu from './UserMenu';

export const SidebarContext = createContext<SidebarContextData>(
  {} as SidebarContextData
);

export default function Sidebar() {
  const [dropdownCloseFns, setDropdownCloseFns] = useState<VoidFn[]>([]);

  const { pathname } = useLocation();

  const isMaxTabletPortrait = useMediaQuery('(max-width: 56.24em)');
  const isTabletLandscapeUp = useMediaQuery(MQ_TABLET_LANDSCAPE_UP);

  const { isOpen, onOpen, onClose, onToggle } = useDisclosure(
    window.matchMedia(MQ_TABLET_LANDSCAPE_UP).matches
  );

  // ****** FUNCTIONS ******
  const closeSidebar = useCallback(() => {
    dropdownCloseFns.forEach(fn => fn());
    onClose();
  }, [dropdownCloseFns, onClose]);

  const toggleSidebar = useCallback(() => {
    if (isMaxTabletPortrait)
      document.body.classList.toggle('body-overflow-hidden');

    dropdownCloseFns.forEach(fn => fn());
    onToggle();
  }, [dropdownCloseFns, isMaxTabletPortrait, onToggle]);

  const registerDropdownCloseFn = useCallback((fn: VoidFn) => {
    setDropdownCloseFns(state => [...state, fn]);
  }, []);

  // ****** SIDE EFFECTS ******
  useEffect(() => {
    document.body.classList.remove('body-overflow-hidden');
    if (isMaxTabletPortrait) closeSidebar();
  }, [pathname, isMaxTabletPortrait, closeSidebar]);

  // Helper function to render a main navigation item
  const renderMainNavItem: RenderMainNavItemFn = ({ to, ...rest }, useKey) => (
    <li key={useKey ? rest.label : undefined}>
      {rest.dropdown ? (
        <SidebarDropdown {...rest} />
      ) : (
        <SidebarLink to={to as string} {...rest} />
      )}
    </li>
  );

  return (
    <SidebarContext.Provider
      value={{
        isOpen,
        openSidebar: onOpen,
        closeSidebar,
        toggleSidebar,
        registerDropdownCloseFn,
      }}
    >
      {isMaxTabletPortrait && (
        <MobileHeader>
          <Button
            aria-label="Abrir menu"
            color="light"
            variant="ghost"
            icon={RiMenu2Line}
            onClick={toggleSidebar}
          />

          <div className="user">M</div>
        </MobileHeader>
      )}

      {isMaxTabletPortrait && isOpen && <Backdrop onClick={toggleSidebar} />}

      <Aside className={clsx({ 'is-open': isOpen })}>
        <div className="sidebar-header">
          <NavLink
            to={HOME}
            className="sidebar-header__logo"
            aria-label="Inicio"
          >
            <LogoLightSVG />
          </NavLink>

          <Button
            aria-label={`${isOpen ? 'Fechar' : 'Abrir'} menu`}
            icon={isTabletLandscapeUp ? RiMenu2Line : RiCloseLine}
            color="light"
            onClick={toggleSidebar}
            variant="ghost"
          />
        </div>

        <MainNavigation>
          <ul>
            {SIDEBAR_NAV_LINKS.map(navItem =>
              navItem.roles ? (
                <Can key={`can-${navItem.label}`} roles={navItem.roles}>
                  {renderMainNavItem(navItem)}
                </Can>
              ) : (
                renderMainNavItem(navItem, true)
              )
            )}
          </ul>
        </MainNavigation>

        <UserMenu />
      </Aside>
    </SidebarContext.Provider>
  );
}
