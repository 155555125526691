import styled from 'styled-components';
import { sideContentLayout } from 'styles/layouts';

import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  max-width: 85.375rem;
  margin: 1.5rem auto 6rem;

  ${sideContentLayout}
`;

export const NewsSection = styled.section`
  .list .card + .card {
    margin-top: var(--items-gap);
  }

  .pagination {
    margin-top: 1rem;
    text-align: center;
  }

  ${mediaQuery.tabletPortraitUp} {
    .pagination {
      margin-top: 2rem;
    }
  }

  ${mediaQuery.tabletLandscapeUp} {
    .list .card {
      display: grid;
      grid-template-columns: calc(40% - (var(--card-padding) / 2)) auto;
    }

    .pagination {
      margin-top: 3rem;
    }
  }
`;
