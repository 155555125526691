import styled from 'styled-components';
import { sideContentLayout } from 'styles/layouts';

export const Container = styled.div`
  max-width: 85.375rem;
  margin: 1.5rem auto 6rem;

  ${sideContentLayout}

  .content > * + * {
    margin-top: 3rem;
  }

  .no-data-container {
    display: grid;
    place-items: center;
    height: 20vh;
  }
`;
