import styled from 'styled-components';

import { color, font } from 'utils/cvar';

export const Container = styled.div`
  background-color: ${color('white')};
  border: 1px solid ${color('line')};

  header {
    background-color: ${color('line')};
    padding: 0.5rem 0.75rem;

    h2 {
      font: ${font('body-large-heavy')};
      text-align: center;
    }
  }

  .status-container {
    padding: 1.5rem;
    text-align: center;
  }

  main {
    padding: 0.75rem;
  }

  .notification {
    text-align: center;
  }

  a {
    margin-top: 0.5rem;
  }
`;
