import { useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Button, InputText } from 'components';

import { useUpdateNewsImageFile, useUpdateNewsImageInfo } from 'hooks/api';

import { updateGalleryItemValidator } from 'shared/validators/news';

import { UpdateNewsContext } from '../..';
import { DropzoneWithInitialPreview } from '../../DropzoneWithInitialPreview';
import { FieldsetGrid } from '../../styles';
import { FlatError, UpdateRequests } from '../../types';
import { GalleryItemFormData, OldGalleryUpdateFormProps } from './types';

export function OldGalleryUpdateForm({
  data,
  onRequestDelete,
}: OldGalleryUpdateFormProps) {
  const { registerGetter } = useContext(UpdateNewsContext);

  const updateNewsImageFileMutation = useUpdateNewsImageFile();
  const updateNewsImageInfoMutation = useUpdateNewsImageInfo();

  const {
    control,
    handleSubmit,
    register,
    reset,
    getValues,
    setError,
    formState: { errors, isSubmitting, dirtyFields, isDirty },
  } = useForm<GalleryItemFormData>({
    resolver: updateGalleryItemValidator,
    reValidateMode: 'onBlur',
    defaultValues: {
      id: data.id,
      image: { preview: data.url },
      author: data.author,
      description: data.description,
    },
  });

  useEffect(() => {
    registerGetter('gallery_old', getValues, data.id);
  }, [registerGetter, getValues, data.id]);

  const onSubmit = handleSubmit(
    async ({ image: { file: imageFile }, author, description }) => {
      const requests: UpdateRequests = [];

      // --- FILE | MAIN IMAGE ---
      if (dirtyFields.image && imageFile)
        requests.push({
          run: () =>
            updateNewsImageFileMutation.mutateAsync({
              id: data.id,
              image: imageFile,
            }),
          onError: () =>
            toast.error('Um erro ocorreu ao atualizar a imagem destaque'),
        });

      // --- INFO | MAIN IMAGE ---
      if (dirtyFields.author || dirtyFields.description)
        requests.push({
          run: () =>
            updateNewsImageInfoMutation.mutateAsync({
              id: data.id,
              author,
              description,
            }),
        });

      // --- RUN ALL REQUESTS ---
      const results = await Promise.allSettled(requests.map(req => req.run()));

      results.forEach((result, index) => {
        if (result.status === 'rejected') {
          const { reason } = result;

          if (reason.response?.data.field) {
            const { field, message } = reason.response.data;

            setError(field, { message });
            toast.error('Campo(s) inválido(s)');
          }

          requests[index].onError?.(reason);
        }
      });

      const updatesWellSucceeded = results.every(
        result => result.status === 'fulfilled'
      );

      if (updatesWellSucceeded)
        toast.success('Todas as informações foram atualizadas com sucesso');

      reset(
        {},
        { keepDirty: false, keepValues: true, keepDefaultValues: true }
      );
    }
  );

  return (
    <form className="gallery__item" onSubmit={onSubmit}>
      <FieldsetGrid as="div">
        <DropzoneWithInitialPreview
          control={control}
          label="Foto"
          name="image"
          error={(errors.image as unknown as FlatError)?.message}
          readOnly={isSubmitting}
          initialPreview={{
            src: data.url,
            alt: data.description,
          }}
        />
        <div>
          <InputText
            label="Descrição da foto"
            error={errors.description?.message}
            readOnly={isSubmitting}
            {...register('description')}
          />
          <InputText
            label="Autor da foto"
            error={errors.author?.message}
            containerClassName="author-input-container"
            readOnly={isSubmitting}
            {...register('author')}
          />
        </div>
      </FieldsetGrid>

      <div className="gallery__actions">
        <Button
          size="small"
          color="danger"
          isLoading={isSubmitting}
          onClick={() => onRequestDelete(data.id)}
        >
          Excluir
        </Button>

        <Button
          type="submit"
          size="small"
          isLoading={isSubmitting}
          disabled={!isDirty}
        >
          Atualizar
        </Button>
      </div>
    </form>
  );
}
