import ReactModal from 'react-modal';

import styled, { css } from 'styled-components';

import { color } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

import { StyledAlertModalProps } from './types';

// eslint-disable-next-line prettier/prettier
export const StyledAlertModal = styled(ReactModal) <StyledAlertModalProps>`
  position: absolute;
  top: 50%;
  left: 50%;
  width: min(100%, 25rem);
  max-height: calc(100vh - 4rem);
  transform: translate(-50%, -50%);
  overflow: auto;

  background-color: ${color('white')};
  outline: none;

  padding: 2rem 1.5rem 1.5rem;
  text-align: center;

  .close-modal {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
  }

  ${p =>
    p.variant === 'neutral' &&
    css`
      --color-neutral-default: ${color('body')};
      --color-neutral-lightest: ${color('input-background')};
    `}

  .alert-icon {
    ${p => css`
      color: ${`var(--color-${p.variant}-default)`};
      background-color: ${`var(--color-${p.variant}-lightest)`};
      border-radius: 50%;
      padding: 1rem;
    `}
  }

  strong {
    font-weight: 600;
  }

  footer {
    padding-top: 1.5rem;

    button + button {
      margin-left: 0.5rem;
    }
  }

  ${mediaQuery.phoneOnly} {
    padding: 1rem;

    footer {
      display: flex;
      justify-content: center;

      button {
        flex: 1;
      }
    }
  }
`;
