import styled from 'styled-components';

import { font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const Container = styled.div`
  .card-grid {
    margin-top: 1.5rem;
    display: grid;
    gap: 1rem;
  }

  .card-wrapper {
    position: relative;

    &__action {
      &--position {
        position: absolute;
        top: 1px;
        left: 1px;
        padding: 0.5rem;
        font: ${font('heading-x-small')};
        min-width: 2.25rem;
        height: 2.25rem;
      }

      &--delete {
        position: absolute;
        top: 1px;
        right: 1px;
      }
    }
  }

  .card {
    height: 100%;
  }

  ${mediaQuery.phoneOnly} {
    .card-grid {
      --card-img-aspect-ratio: 16/9;
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    .card-grid {
      grid-template-columns: repeat(auto-fill, minmax(11rem, 1fr));
      gap: 1.5rem;
    }
  }
`;
