import styled, { css } from 'styled-components';

import { color, cvar, font } from 'utils/cvar';

import { ContainerProps } from './types';

const getMarginBottom = (props: ContainerProps) =>
  props.hasError || props.removeErrorSlotMargin ? '0.5rem' : '2rem';

export const Container = styled.div<ContainerProps>`
  ${p =>
    !p.removeMarginTop &&
    css`
      margin-top: 1rem;
    `}

  label {
    display: inline-block;
    margin-bottom: 0.5rem;
    color: ${color('label')};
  }

  .react-datepicker-wrapper {
    margin-bottom: ${getMarginBottom};
  }

  .react-datepicker__input-container > input {
    width: 100%;
    outline: 0;
    border: 1px solid ${color('line')};
    background-color: ${color('input-background')};
    height: 3rem;
    color: ${color('body')};
    padding: 0.75rem 1rem;
    transition-duration: ${cvar('transition-quick')};
    transition-property: background, color, border, box-shadow;

    &::placeholder {
      color: ${color('placeholder')};
    }

    &:focus:not(:read-only) {
      box-shadow: 0 0 0 4px ${color('primary-lighter')};
      border-color: ${color('primary-light')};
    }

    &:active:not(:disabled):not(:read-only),
    &:hover:not(:disabled):not(:read-only) {
      border-color: ${color('primary-light')};
    }
  }

  .react-datepicker {
    &__close-icon::after {
      background: ${color('primary-default')};
      font-size: 1rem;
    }
  }

  ${p =>
    p.isDisabled &&
    css`
      label,
      .react-datepicker__input-container > input {
        cursor: not-allowed;
      }
    `}

  ${p =>
    (p.isDisabled || p.isReadOnly) &&
    css`
      .react-datepicker__input-container > input {
        background-color: ${color('line')};
        opacity: 0.8;
      }
    `}

  ${p =>
    p.hasError &&
    css`
      .react-datepicker__input-container > input {
        border-color: ${color('danger-default')};
        background-color: ${color('danger-lightest')};
      }

      > span {
        display: inline-block;
        font: ${font('body-small')};
        color: ${color('danger-default')};
      }
    `}
`;
