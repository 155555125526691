import styled from 'styled-components';

import { color, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

const containerWidth = '85.375rem'; // ? 1366px (1rem = 16px)

export const Container = styled.footer`
  background-color: ${color('primary-dark')};

  hr {
    border: none;
    height: 1px;
    background-color: ${color('overlay-light-15')};
  }

  .developed-by {
    padding: 1.5rem 0;
    text-align: center;
  }
`;

export const Main = styled.main`
  max-width: ${containerWidth};
  padding: 2.5rem 1.5rem;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${mediaQuery.min('41em')} {
    flex-direction: row;
    justify-content: space-between;
  }
`;

export const About = styled.div`
  svg {
    margin-bottom: 1.5rem;
  }

  p {
    font: ${font('body-small')};
    color: ${color('primary-lightest')};

    &.copyright {
      color: ${color('primary-light')};
    }

    & + p {
      margin-top: 1rem;
    }
  }

  ${mediaQuery.min('41em')} {
    flex-shrink: 0;
  }
`;

export const SiteMap = styled.div`
  --max-col-size: 1fr;

  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(10rem, var(--max-col-size)));
  gap: 1.5rem;

  strong {
    font: ${font('body-normal-heavy')};
    color: ${color('white')};
    display: block;
    margin-bottom: 0.75rem;
  }

  a {
    display: block;
    color: ${color('primary-lighter')};
    padding: 0.325rem 0;

    &:hover {
      color: ${color('secondary-default')};
    }
  }

  ${mediaQuery.min('41em')} {
    justify-content: flex-end;
    flex: 1;
    --max-col-size: auto;

    a {
      width: max-content;
    }
  }
`;
