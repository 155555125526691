import { ComponentType } from 'react';
import { Redirect, Route, RouteProps } from 'react-router-dom';

import { PageLoading } from 'components';
import { AdminAuthLayout } from 'pages/layout';

import { useAuth } from 'contexts/AuthContext';

import { ADMIN_DASHBOARD } from '../PATHS';

type AdminAuthRouteProps = Omit<RouteProps, 'children' | 'render'> & {
  component: ComponentType;
};

export default function AdminAuthRoute({
  component: Component,
  ...rest
}: AdminAuthRouteProps) {
  const { isLoading, user } = useAuth();

  if (isLoading) return <PageLoading />;
  if (user?.isAdmin) return <Redirect to={ADMIN_DASHBOARD} />;

  return (
    <Route
      {...rest}
      render={props => (
        <AdminAuthLayout>
          <Component {...props} />
        </AdminAuthLayout>
      )}
    />
  );
}
