import { css } from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

// ! IMPORTANT
// ? If you change any variable name remember to change also in the
// ? `styleVariables.type.ts` file once that is used to map variables in the
// ? utils `cvar`, `color` and `font`.

export default css`
  // ************************
  // ***      COLORS      ***
  // ************************
  --color-title-active: #060c28;
  --color-body: #29324f;
  --color-label: #69708c;
  --color-placeholder: #a2a7be;
  --color-line: #d9dde7;
  --color-input-background: #eeeff5;
  --color-background: #f8f9fb;
  --color-white: #fcfcfc;

  --color-primary-darker: #00051f;
  --color-primary-dark: #00082d;
  --color-primary-default: #07134d;
  --color-primary-light: #a3acd7;
  --color-primary-lighter: #d7ddfa;
  --color-primary-lightest: #eff1fe;

  --color-secondary-darker: #004f69;
  --color-secondary-dark: #00769d;
  --color-secondary-default: #00abe3;
  --color-secondary-light: #9fdef9;
  --color-secondary-lighter: #ccf2ff;
  --color-secondary-lightest: #f0fbff;

  --color-tertiary-darker: #8e6700;
  --color-tertiary-dark: #c18c00;
  --color-tertiary-default: #fcbc12;
  --color-tertiary-light: #ffe399;
  --color-tertiary-lighter: #fff3d1;
  --color-tertiary-lightest: #fffbf0;

  --color-danger-dark: #a70212;
  --color-danger-default: #e61a2e;
  --color-danger-light: #ff9ea8;
  --color-danger-lighter: #ffd6da;
  --color-danger-lightest: #fff0f1;

  --color-success-dark: #007a2a;
  --color-success-default: #09a43e;
  --color-success-light: #97ffba;
  --color-success-lighter: #d6ffe4;
  --color-success-lightest: #f0fff5;

  --color-overlay-light-15: hsla(230, 25%, 95%, 0.15);
  --color-overlay-light-30: hsla(230, 25%, 95%, 0.3);
  --color-overlay-dark-50: hsla(230, 73%, 9%, 0.5);
  --color-overlay-dark-30: hsla(230, 73%, 9%, 0.3);

  // ************************
  // ***       FONT       ***
  // ************************
  --font-family: 'Work Sans', sans-serif;
  //?                        weight size / line-height font-family
  --font-display-large-heavy: 900 4rem / 1.2 var(--font-family);
  --font-display-large-light: 600 4rem / 1.2 var(--font-family);
  --font-display-medium-heavy: 900 3rem / 1.2 var(--font-family);
  --font-display-medium-light: 600 3rem / 1.2 var(--font-family);

  --font-heading-large: 600 2.5rem / 1.4 var(--font-family);
  --font-heading-medium: 600 2rem / 1.4 var(--font-family);
  --font-heading-small: 600 1.5rem / 1.4 var(--font-family);
  --font-heading-x-small: 600 1.25rem / 1.4 var(--font-family);

  --font-body-large: 400 1.125rem / 1.5 var(--font-family);
  --font-body-large-heavy: 600 1.125rem / 1.5 var(--font-family);

  --font-body-normal: 400 1rem / 1.5 var(--font-family);
  --font-body-normal-heavy: 600 1rem / 1.5 var(--font-family);

  --font-body-small: 400 0.875rem / 1.5 var(--font-family);
  --font-body-small-heavy: 600 0.875rem / 1.5 var(--font-family);

  //* MOBILE FONT DEFINITION
  ${mediaQuery.phoneOnly} {
    --font-display-large-heavy: 900 3rem / 1.2 var(--font-family);
    --font-display-large-light: 600 3rem / 1.2 var(--font-family);
    --font-display-medium-heavy: 900 2rem / 1.2 var(--font-family);
    --font-display-medium-light: 600 2rem / 1.2 var(--font-family);

    --font-heading-large: 600 1.75rem / 1.4 var(--font-family);
    --font-heading-medium: 600 1.5rem / 1.4 var(--font-family);
    --font-heading-small: 600 1.25rem / 1.4 var(--font-family);
    --font-heading-x-small: 600 1.125rem / 1.4 var(--font-family);
  }

  // *************************
  // ***     ELEVATION     ***
  // *************************
  --elevation-small: 0 0.2rem 0.4rem rgba(0, 0, 0, 0.1);
  --elevation-medium: 0 0.8rem 1.6rem rgba(0, 0, 0, 0.04);
  --elevation-large: 0 1.6rem 2.4rem rgba(0, 0, 0, 0.06);
  --elevation-x-large: 0 3.2rem 6.4rem rgba(0, 0, 0, 0.08);

  // **************************
  // ***     TRANSITION     ***
  // **************************
  --transition-quick: 150ms;
  --transition-normal: 250ms;

  // *************************
  // ***      Z-INDEX      ***
  // *************************
  --z-index-header: 1100;
  --z-index-backdrop: 1200;
  --z-index-sidebar: 1300;
  --z-index-modal: 1400;
`;
