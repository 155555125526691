import styled from 'styled-components';
import { sideContentLayout } from 'styles/layouts';

import { color, cvar, font } from 'utils/cvar';

export const Container = styled.div`
  max-width: 85.375rem;
  margin: 1.5rem auto 6rem;

  ${sideContentLayout}
`;

export const Content = styled.div`
  > div + div {
    margin-top: 3rem;
  }

  p {
    max-width: 47rem;
  }

  h3 {
    margin-bottom: 0.5rem;
    font: ${font('heading-x-small')};
  }

  > div:not(.notes) p {
    font: ${font('body-large')};
  }

  strong {
    font-weight: 600;
  }

  .notes p {
    font-style: italic;
    color: ${color('label')};

    & + p {
      margin-top: 1rem;
    }
  }

  a {
    color: ${color('secondary-dark')};
    transition: ${cvar('transition-quick')};

    &:hover,
    &:focus {
      color: ${color('secondary-darker')};
      text-decoration: underline;
    }
  }
`;
