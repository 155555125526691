type Alternative = { votes: number; title: string };
type ChartData = { data: number[]; labels: string[] };

export function formatAlternativesAsChartData(alternatives: Alternative[]) {
  const chart = alternatives.reduce(
    (acc, alternative) => {
      acc.data.push(alternative.votes);
      acc.labels.push(alternative.title);
      return acc;
    },
    { data: [], labels: [] } as ChartData
  );
  return chart;
}
