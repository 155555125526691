import { Helmet } from 'react-helmet';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Router as BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import GlobalStyles from 'styles/global';

import { ScrollToTop } from 'components';

import { history } from 'services/history';
import { queryClient } from 'services/queryClient';

import { AuthProvider } from 'contexts/AuthContext';

import { Routes } from 'routes';

import 'react-toastify/dist/ReactToastify.min.css';

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Helmet defaultTitle="Jornal Local" titleTemplate="Jornal Local | %s" />

      <BrowserRouter history={history}>
        <AuthProvider>
          <ScrollToTop />
          <Routes />
        </AuthProvider>
        <ToastContainer position="top-center" />
      </BrowserRouter>

      <GlobalStyles />
      <ReactQueryDevtools position="bottom-right" />
    </QueryClientProvider>
  );
}

export default App;
