import { Helmet } from 'react-helmet';
import {
  RiFacebookBoxLine,
  RiInstagramLine,
  RiTwitterLine,
} from 'react-icons/ri';
import { useParams } from 'react-router-dom';

import { sanitize } from 'dompurify';

import {
  Button,
  ContentLoading,
  FacebookLikeAndShare,
  GalleryCarousel,
  LastNewsList,
  ShareButton,
  SideContentWithWidgets,
  Tag,
  Text,
  TwitterButton,
} from 'components';

import { formatDate, formatDateDistanceToNowStrict } from 'lib';

import { useNewsByIdOrSlug } from 'hooks/api';

import { NEWS } from 'routes/PATHS';

import {
  AboutSection,
  Container,
  Content,
  Gallery,
  MainImage,
  NoDataContainer,
  SocialLinks,
} from './styles';

//* Format date as a presentation format (publish - edited)
function getPresentationDate(publishedAt: string, editedAt?: string | null) {
  const publish = `Publicado em ${formatDate(new Date(publishedAt))}`;
  if (!editedAt) return publish;

  return `${publish} - atualizado ${formatDateDistanceToNowStrict(
    new Date(editedAt),
    { addSuffix: true }
  )}`;
}

export default function NewsDetails() {
  const { slug } = useParams<{ slug: string }>();
  const { data: news, isLoading } = useNewsByIdOrSlug(slug, {
    select: data => ({
      ...data,
      presentationDate: getPresentationDate(
        data.publicationDate as string,
        data.editedAt
      ),
    }),
  });

  if (isLoading)
    return (
      <ContentLoading label="Estamos carregando as informações da noticia" />
    );

  if (!news)
    return (
      <NoDataContainer>
        <Text as="h2" variant="heading-small" align="center">
          Não foi possível encontrar a noticia solicitada
        </Text>

        <Button color="secondary" to={NEWS}>
          Voltar às notícias
        </Button>
      </NoDataContainer>
    );

  return (
    <Container>
      <Helmet>
        <title>{news.title}</title>
      </Helmet>

      <AboutSection className="container__about">
        <div className="categories">
          {news.categories.map(item => (
            <Tag key={item.id}>{item.name}</Tag>
          ))}
        </div>

        <Text as="h1" variant="display-medium-heavy" color="primary-default">
          {news.title}
        </Text>

        <div className="metadata">
          <Text color="label">
            Por{' '}
            <Text as="strong" variant="body-normal-heavy">
              {news.author.name}
            </Text>
          </Text>

          <Text color="label">{news.presentationDate}</Text>
        </div>
      </AboutSection>

      <MainImage className="container__main-image">
        <div>
          <img
            src={news.mainImage.url}
            alt={news.mainImage.description}
            onLoad={event => {
              event.currentTarget.parentElement?.classList.add('ratio-box');
            }}
          />
        </div>
        <Text as="figcaption" variant="body-small" color="label">
          {`${news.mainImage.description} - Foto: ${news.mainImage.author}`}
        </Text>
      </MainImage>

      <section className="container__content">
        <Content
          dangerouslySetInnerHTML={{
            __html: sanitize(news.contentHtml, {
              ADD_TAGS: ['iframe'],
              ADD_ATTR: [
                'allow',
                'allowfullscreen',
                'frameborder',
                'scrolling',
              ],
            }),
          }}
        />

        {news.gallery.length > 0 && (
          <Gallery>
            <Text variant="heading-small" color="title-active" mb="1.5rem">
              Galeria de imagens
            </Text>

            <GalleryCarousel images={news.gallery} />
          </Gallery>
        )}

        <SocialLinks>
          <div className="share">
            <Text as="h2" variant="heading-small" mb="1.5rem">
              Compartilhe
            </Text>

            <div>
              <ShareButton />
              <TwitterButton text={`${news.title} - @JornalLocal`} />
              <FacebookLikeAndShare />
            </div>
          </div>

          <div className="social-media">
            <Text as="h2" variant="heading-small" mb="1.5rem">
              Siga nossas redes sociais
            </Text>

            <div>
              <Button
                className="twitter"
                icon={RiTwitterLine}
                href="https://twitter.com/JornalLocal"
                target="_blank"
                rel="noreferrer noopener"
              />
              <Button
                className="facebook"
                icon={RiFacebookBoxLine}
                href="https://facebook.com/jornal.local123"
                target="_blank"
                rel="noreferrer noopener"
              />
              <Button
                className="instagram"
                icon={RiInstagramLine}
                href="https://www.instagram.com/jornallocalvalencarj"
                target="_blank"
                rel="noreferrer noopener"
              />
            </div>
          </div>
        </SocialLinks>
      </section>

      <SideContentWithWidgets className="container__side-content">
        <LastNewsList />
      </SideContentWithWidgets>
    </Container>
  );
}
