import { Helmet } from 'react-helmet';

import { ContentLoading, Text } from 'components';

import { useNewsHighlights, useNewsHighlightTypes } from 'hooks/api';

import CarouselHighlights from './CarouselHighlights';
import MainNewsHighlights from './MainNewsHighlights';
import OnlyNewsHighlights from './OnlyNewsHighlights';
import { Container } from './styles';

export default function NewsHighlights() {
  const { data, isLoading, isError } = useNewsHighlights();
  const {
    data: types,
    isLoading: isLoadingTypes,
    isError: isErrorTypes,
  } = useNewsHighlightTypes();

  if (isLoading || isLoadingTypes)
    return <ContentLoading label="Estamos carregando as informações" />;

  return (
    <Container>
      <Helmet>
        <title>Destaques</title>
      </Helmet>

      {!data && !types && isError && isErrorTypes && (
        <Text>Não foi possível carregar as informações necessárias</Text>
      )}

      {data && types && (
        <>
          <CarouselHighlights data={data.carousel} />
          <MainNewsHighlights data={data.mainNews} />
          <OnlyNewsHighlights data={data.news} />
        </>
      )}
    </Container>
  );
}
