import { RiCloseLine } from 'react-icons/ri';

import { Button } from 'components';

import { useToggle } from 'hooks';
import { useLastEdition } from 'hooks/api';

import { AspectRatio, Container, ZoomModal } from './styles';

ZoomModal.setAppElement('#root');

export function LastEdition() {
  const [isZoomModalOpen, toggleZoomModal] = useToggle();

  const { data: lastEdition, isSuccess } = useLastEdition({
    select: data => ({
      ...data,
      imageAlt: `Edição de número ${data.editionNumber}`,
    }),
  });

  if (!isSuccess) return null;

  return (
    <Container>
      <header>
        <h2>Edição da semana</h2>
      </header>
      <main>
        <AspectRatio>
          <button type="button" onClick={toggleZoomModal}>
            <img src={lastEdition?.url} alt={lastEdition?.imageAlt} />
          </button>
        </AspectRatio>
        <div className="info">
          <p>
            Garanta já sua edição impressa com notícias exclusivas nas bancas.
          </p>
        </div>
      </main>

      <ZoomModal
        overlayClassName="ReactModal__Overlay"
        isOpen={isZoomModalOpen}
        onRequestClose={toggleZoomModal}
      >
        <Button
          icon={RiCloseLine}
          color="light"
          size="small"
          className="close-modal"
          onClick={toggleZoomModal}
        />
        <AspectRatio>
          <img src={lastEdition?.url} alt={lastEdition?.imageAlt} />
        </AspectRatio>
      </ZoomModal>
    </Container>
  );
}
