import { Link } from 'react-router-dom';

import { Text } from 'components';

import { LogoLightSVG } from 'assets';

import { ABOUT, NEWS, POLLS } from 'routes/PATHS';

import { Container, Main, About, SiteMap } from './styles';

export default function Footer() {
  return (
    <Container>
      <Main>
        <About aria-label="Informações gerais sobre o Jornal">
          <LogoLightSVG />

          <p aria-label="Endereço">
            Rua Cel. Benjamin Guimarães, 95-B, Centro <br />
            Valença, RJ - CEP 27600-000
          </p>

          <p aria-label="Contato">
            Tel: (24) 99227-6230 <br />
            E-mail: editor@local.jor.br
          </p>

          <p className="copyright">
            Editora Jornal Local © 2021 - Todos os direitos reservados
          </p>
        </About>

        <SiteMap aria-label="Links úteis">
          <div>
            <strong>Site</strong>
            <Link to={NEWS}>Notícias</Link>
            <Link to={ABOUT}>Sobre nós</Link>
            <Link to={POLLS}>Enquetes</Link>
          </div>
          <div>
            <strong>Redes Sociais</strong>
            <a
              href="https://facebook.com/jornal.local123"
              target="_blank"
              rel="noreferrer noopener"
            >
              Facebook
            </a>
            <a
              href="https://twitter.com/JornalLocal"
              target="_blank"
              rel="noreferrer noopener"
            >
              Twitter
            </a>
            <a
              href="https://www.instagram.com/jornallocalvalencarj"
              target="_blank"
              rel="noreferrer noopener"
            >
              Instagram
            </a>
          </div>
        </SiteMap>
      </Main>

      <hr />

      <div className="developed-by">
        <Text variant="body-small" color="primary-light">
          Desenvolvido por ConSupport
        </Text>
      </div>
    </Container>
  );
}
