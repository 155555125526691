import styled from 'styled-components';

import { color } from 'utils/cvar';

export const Container = styled.div`
  .gallery {
    &__item + .gallery__item {
      margin-top: 1.5rem;
      padding-top: 0.5rem;
      border-top: 1px solid ${color('primary-light')};
    }

    &__actions {
      margin-top: 0.5rem;

      button + button {
        margin-left: 0.5rem;
      }

      &--master {
        margin-top: 1.5rem;
      }
    }
  }

  hr {
    margin-top: 2rem;
    border: 0;
    height: 1px;
    background-color: ${color('primary-light')};
  }
`;
