import { yupResolver } from '@hookform/resolvers/yup';

import { yup } from 'lib';

// ****** CUSTOM MESSAGES ******
const positionRequiredMsg = 'Posição é obrigatório para marcar como destaque';
const periodicalReqMsg =
  'Coluna é obrigatório quando destaque é do tipo "Coluna"';

// ****** SHARED SCHEMAS ******
// ? News general info schema
const newsSchema = yup.object({
  title: yup.string().required().label('Título'),
  description: yup.string().max(128).required().label('Descrição'),
  categoriesId: yup
    .array()
    .min(1, 'Categoria é obrigatório')
    .max(3, 'No máximo 3 categorias'),
  periodicalId: yup
    .string()
    .when('highlightId', (value: { type: string }, schema) => {
      return value && value.type === 'periodical'
        ? yup.string().typeError(periodicalReqMsg).required(periodicalReqMsg)
        : schema.nullable();
    }),
  highlightId: yup.mixed(),
  position: yup
    .mixed()
    .when('highlightId', (value, schema) =>
      value ? yup.mixed().required(positionRequiredMsg) : schema
    ),
});

// ? Single gallery item schema
const galleryItemSchema = yup.object().shape({
  image: yup.mixed().required().label('Imagem'),
  author: yup.string().required().label('Autor da foto'),
  description: yup.string().max(100).required().label('Descrição da foto'),
});

// ****** RESOLVER / VALIDATORS ******
// ? Create
export const createNewsValidator = yupResolver(
  newsSchema.shape({
    mainImage: yup.mixed().required().label('Imagem destaque'),
    mainImageAuthor: yup.string().required().label('Autor da foto'),
    mainImageDescription: yup
      .string()
      .max(100)
      .required()
      .label('Descrição da foto'),
    gallery: yup.array().notRequired().of(galleryItemSchema),
    status: yup.string().oneOf(['sketch', 'publish', 'schedule']),
    publicationDate: yup.date().when('status', {
      is: 'schedule',
      then: yup.date().required().label('Data de publicação'),
    }),
  })
);

// ? Update news general Info with main image
// ! STILL NOT USING THE SHARED SCHEMA BECAUSE IT'LL CHANGE SOON
export const updateNewsValidator = yupResolver(
  newsSchema.shape({
    contentHtml: yup.string().required().label('Conteúdo da matéria'),
    mainImage: yup.object().required().label('Imagem destaque'),
    mainImageAuthor: yup.string().required().label('Autor'),
    mainImageDescription: yup.string().required().label('Descrição da foto'),
  })
);

// ? Update each gallery image item
export const updateGalleryItemValidator = yupResolver(galleryItemSchema);

export const addGalleryItemsValidator = yupResolver(
  yup.object().shape({
    gallery: yup.array().of(galleryItemSchema).min(1).required(),
  })
);
