import { useContext, useEffect } from 'react';
import { IconBaseProps } from 'react-icons';
import { RiAddLine, RiArrowDownSLine } from 'react-icons/ri';
import { NavLink, NavLinkProps, useRouteMatch } from 'react-router-dom';

import clsx from 'clsx';

import { useDisclosure } from 'hooks';

import { SidebarContext } from '..';

import { Container } from './styles';

// * TYPES
type SidebarDropdownLinkProps = {
  icon: React.ComponentType<IconBaseProps>;
  label: string;
  dropdown: Array<
    Omit<NavLinkProps, 'children' | 'to'> & {
      to: string;
      label: string;
      secondaryLink?: {
        to: string;
        title: string;
        icon?: React.ComponentType<IconBaseProps>;
      };
    }
  >;
};

// * COMPONENT
export default function SidebarDropdown({
  icon: Icon,
  label,
  dropdown,
}: SidebarDropdownLinkProps) {
  const { openSidebar, registerDropdownCloseFn } = useContext(SidebarContext);
  const { isOpen, onToggle, onClose } = useDisclosure();

  const paths = dropdown.map(item =>
    item.secondaryLink ? [item.to, item.secondaryLink.to] : item.to
  );
  const hasActiveLink = !!useRouteMatch(paths.flat());

  // ? Toggle dropdown and open menu if closed when dropdown is opened
  function toggleDropdown() {
    onToggle();
    openSidebar();
  }

  useEffect(() => {
    registerDropdownCloseFn(onClose);
  }, [onClose, registerDropdownCloseFn]);

  return (
    <Container isOpen={isOpen}>
      <button
        type="button"
        className={clsx(hasActiveLink && 'active')}
        onClick={toggleDropdown}
        title={label}
      >
        <Icon size="1.5rem" />
        <span>{label}</span>
        <RiArrowDownSLine size="1.5rem" />
      </button>

      <ul>
        {dropdown.map(
          ({ to: linkTo, label: linkLabel, secondaryLink, ...rest }) => {
            const SecondaryIcon = secondaryLink?.icon
              ? secondaryLink?.icon
              : RiAddLine;

            return (
              <li key={linkTo}>
                <NavLink to={linkTo} title={linkLabel} {...rest}>
                  <span>{linkLabel}</span>
                </NavLink>

                {secondaryLink && (
                  <NavLink
                    to={secondaryLink.to}
                    title={secondaryLink.title}
                    className="secondary-link"
                    exact
                  >
                    <SecondaryIcon size="1.5rem" />
                  </NavLink>
                )}
              </li>
            );
          }
        )}
      </ul>
    </Container>
  );
}
