import styled, { css } from 'styled-components';

import { placeholder } from 'assets';

import { FontVariants } from 'shared/types/styleVariables';
import { color, cvar, font } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

import { CardVariant, ContainerProps, ContentProps, ImageProps } from './types';

// ***************************************
// ******         CONTAINER         ******
// ***************************************
export const Container = styled.div<ContainerProps>`
  --card-padding: ${p => (p.variant === 'x-small' ? '0.75rem' : '1rem')};

  background-color: ${color('white')};
  border: 1px solid ${color('line')};

  ${p =>
    p.variant === 'normal' &&
    css`
      ${mediaQuery.min('44em')} {
        --card-padding: 1.5rem;
      }
    `}
`;

// ***************************************
// ******           IMAGE           ******
// ***************************************
const getAspectRatio = ({ aspectRatio }: ImageProps) =>
  `calc(100% / (var(--card-img-aspect-ratio, ${aspectRatio})))`;

export const Image = styled.div<ImageProps>`
  position: relative;
  overflow: hidden;

  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: ${getAspectRatio};
  }

  > * {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  a {
    transition: transform ${cvar('transition-quick')};

    &:hover,
    &:focus {
      transform: scale(1.1);
    }
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.image-load-error,
  .image-load-error {
    background: url(${placeholder}) no-repeat center / cover;

    &::after {
      position: absolute;
      content: 'Erro ao carregar a imagem';
      top: 50%;
      left: 50%;
      width: 90%;
      text-align: center;
      transform: translate(-50%, -50%);
      z-index: 1;
      font: ${font('body-small-heavy')};
      color: ${color('danger-dark')};
    }

    img {
      opacity: 0;
    }
  }
`;

// ***************************************
// ******          CONTENT          ******
// ***************************************
export const titleFontMap: { [key in CardVariant]: FontVariants } = {
  'x-small': 'body-small-heavy',
  small: 'body-large-heavy',
  normal: 'heading-x-small',
};

export const Content = styled.div<ContentProps>`
  padding: var(--card-padding);
  display: flex;
  flex-direction: column;
  ${p => !p.hasImage && 'height: 100%;'};

  .card {
    &__tags {
      display: flex;
      flex-wrap: wrap;
      gap: 0.5rem;
      margin-bottom: ${p => (p.variant === 'x-small' ? '0.5rem' : '1rem')};
    }

    &__title {
      font: ${p => font(titleFontMap[p.variant])};

      a {
        display: block;
        outline: 0;

        &:hover,
        &:focus {
          text-decoration: underline;
          color: ${color('secondary-dark')};
        }
      }
    }

    &__description {
      margin: 0.75rem 0 1rem;
    }

    &__bottom-text {
      margin-top: auto;
    }
  }
`;
