import React, { forwardRef } from 'react';

import { StyledText } from './styles';
import { TextComponent, TextProps } from './types';

const Text: TextComponent = forwardRef(function Text(
  { align, color, variant, children, ...rest }: TextProps,
  ref: React.Ref<Element>
) {
  return (
    <StyledText
      ref={ref}
      $align={align}
      $color={color}
      $variant={variant}
      {...rest}
    >
      {children}
    </StyledText>
  );
});

export default Text;
