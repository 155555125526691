import { Redirect, Switch } from 'react-router-dom';

import {
  ListNews,
  CreateNews,
  ListCategories,
  ListPeriodicals,
  UpdateNews,
  SignIn,
  UnderDevelopment,
  NewsHighlights,
  ManageEditions,
  PollsHistory,
  PollsInProgress,
} from 'pages/admin';
import {
  About,
  Home,
  ListNews as ListNewsPublic,
  NewsDetails,
  Polls,
  SearchNews,
} from 'pages/public';

import { AdminAuthRoute, AdminRoute, PublicRoute } from './_route';
import * as PATHS from './PATHS';

export function Routes() {
  return (
    <Switch>
      {/**
       * ADMIN AUTH ROUTES
       * Authentication routes used to control admin panel access
       */}
      <AdminAuthRoute path={PATHS.ADMIN_AUTH_SIGN_IN} component={SignIn} />

      {/**
       * ADMIN ROUTES
       * Administrative routes which requires being authenticated and
       * having admin roles
       */}
      <AdminRoute exact path={PATHS.ADMIN_NEWS} component={ListNews} />
      <AdminRoute path={PATHS.ADMIN_NEWS_CREATE} component={CreateNews} />
      <AdminRoute
        path={`${PATHS.ADMIN_NEWS_UPDATE}/:id`}
        component={UpdateNews}
      />
      <AdminRoute
        path={PATHS.ADMIN_HIGHLIGHTS}
        roles={['root', 'editor']}
        component={NewsHighlights}
      />
      <AdminRoute path={PATHS.ADMIN_CATEGORIES} component={ListCategories} />
      <AdminRoute path={PATHS.ADMIN_PERIODICALS} component={ListPeriodicals} />
      <AdminRoute path={PATHS.ADMIN_EDITIONS} component={ManageEditions} />
      <AdminRoute exact path={PATHS.ADMIN_POLLS} component={PollsInProgress} />
      <AdminRoute path={PATHS.ADMIN_POLLS_HISTORY} component={PollsHistory} />
      <AdminRoute
        exact
        path={PATHS.ADMIN_DASHBOARD}
        component={UnderDevelopment}
      />

      {/**
       * PUBLIC ROUTES
       * No authentication required
       */}
      <PublicRoute exact path={PATHS.HOME} component={Home} />
      <PublicRoute path={PATHS.ABOUT} component={About} />
      <PublicRoute path={PATHS.POLLS} component={Polls} />
      <PublicRoute
        exact
        path={[
          `${PATHS.NEWS_BY_CATEGORIES}/:slug`,
          `${PATHS.NEWS_BY_PERIODICALS}/:slug`,
          PATHS.NEWS,
        ]}
        component={ListNewsPublic}
      />
      <PublicRoute path={`${PATHS.NEWS}/:slug`} component={NewsDetails} />
      <PublicRoute
        path={`${PATHS.SEARCH_NEWS}/:search`}
        component={SearchNews}
      />

      {/**
       * REDIRECTS
       * Redirect from nonexistent paths an existent one based on path prefix
       * TODO: Implement Not Found pages
       */}
      <Redirect from="/admin/:path" to={PATHS.ADMIN_NEWS} />
      <Redirect from="/:path" to={PATHS.HOME} />
    </Switch>
  );
}
