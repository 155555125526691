type FacebookLikeAndShareProps = {
  likeUrl?: string;
  shareUrl?: string;
};

export default function FacebookLikeAndShare({
  likeUrl = 'https://www.facebook.com/jornal.local123',
  shareUrl = window.location.href,
}: FacebookLikeAndShareProps) {
  return (
    <>
      <iframe
        title="facebook-plugin-like"
        src={`https://www.facebook.com/plugins/like.php?href=${likeUrl}&width=114&layout=button_count&action=like&size=large&share=false&height=21&appId`}
        width="114"
        height="28"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      />

      <iframe
        title="facebook-plugin-share"
        src={`https://www.facebook.com/plugins/share_button.php?href=${shareUrl}&layout=button_count&size=large&width=165&height=28&appId`}
        width="165"
        height="28"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameBorder="0"
        allowFullScreen
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      />
    </>
  );
}
