import { Common, Colors, FontVariants } from 'shared/types/styleVariables';

/**
 * @param variable CSS Variable key name
 * @returns string representing the respective CSS Variable
 */
export function cvar(variable: Common) {
  return `var(--${variable})`;
}

/**
 * @param variable CSS Color variable key name
 * @returns string representing the respective CSS Variable
 */
export function color(variable: Colors) {
  return `var(--color-${variable})`;
}

/**
 * @param variable CSS Font variant variable key name
 * @returns string representing the respective CSS Variable
 */
export function font(variable: FontVariants) {
  return `var(--font-${variable})`;
}
