import { CarouselProvider } from 'pure-react-carousel';
import styled from 'styled-components';

import { color, cvar } from 'utils/cvar';
import mediaQuery from 'utils/mediaQuery';

export const StyledCarouselProvider = styled(CarouselProvider)`
  position: relative;

  .carousel {
    &__slide::before {
      position: absolute;
      content: '';
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: linear-gradient(transparent 50%, var(--color-primary-darker));
      z-index: 2;
      pointer-events: none;
    }

    &__link {
      &:hover,
      &:focus {
        .carousel__image {
          transform: scale(1.1);
        }
      }
    }

    &__image {
      object-fit: cover;
      transition: transform ${cvar('transition-normal')};
    }

    &__info {
      --position-distance: 1rem;
      position: absolute;
      left: var(--position-distance);
      bottom: var(--position-distance);
      z-index: 3;

      p {
        display: none;
      }
    }

    &__back-button,
    &__next-button {
      display: none;
    }
  }

  ${mediaQuery.phoneOnly} {
    .carousel__info {
      right: var(--position-distance);
    }
  }

  ${mediaQuery.tabletPortraitUp} {
    .carousel__info {
      max-width: 32rem; //? 512px when 1rem = 16px
    }
  }

  ${mediaQuery.min('44em')} {
    .carousel {
      &__info {
        --position-distance: 1.5rem;

        p {
          display: block;
        }
      }

      &__back-button,
      &__next-button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        display: grid;
        place-items: center;
        border: 2px solid transparent;
        outline: 0;
        padding: 0.25rem;
        color: ${color('white')};
        background-color: ${color('overlay-dark-50')};

        transition-property: background-color, color;
        transition-duration: ${cvar('transition-quick')};

        &:not(:disabled) {
          &:hover {
            background-color: ${color('primary-dark')};
          }

          &:focus {
            border-color: ${color('primary-light')};
          }
        }

        &:disabled {
          opacity: 0.2;
        }
      }

      &__next-button {
        right: 0;
      }
    }
  }

  ${mediaQuery.tabletLandscapeUp} {
    .carousel__info {
      --position-distance: 2.5rem;
    }
  }
`;

export const ErrorImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  img {
    object-fit: cover;
    opacity: 0.5;
    width: 100%;
    height: 100%;
  }

  p {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    transform: translate(-50%, -50%);
    text-align: center;
  }
`;
