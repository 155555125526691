import styled from 'styled-components';

export const Form = styled.form`
  h2 {
    margin-top: 0.25rem;
  }

  .alternative {
    width: 100%;
    display: flex;
    align-items: flex-start;

    &__input {
      width: 100%;
    }

    button {
      margin-top: 1rem;
      margin-left: 0.5rem;
    }

    &.hide {
      display: none;
    }
  }

  .secondary-actions {
    text-align: right;

    &__add-alternative {
      margin-top: 1rem;
    }
  }
`;
