import styled from 'styled-components';

import mediaQuery from 'utils/mediaQuery';

// * STYLES
export const Container = styled.div`
  > main {
    padding: 2rem 1rem;
  }

  ${mediaQuery.tabletPortraitUp} {
    > main {
      padding: 1.75rem 1rem;
    }
  }

  ${mediaQuery.tabletLandscapeUp} {
    display: flex;

    > aside {
      flex-shrink: 0;
    }

    > main {
      flex-grow: 1;
      padding: 1.75rem 1.5rem;
    }
  }
`;
