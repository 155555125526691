import {
  RiArticleLine,
  RiBook2Line,
  RiChatPollLine,
  RiDashboardLine,
  RiStarLine,
} from 'react-icons/ri';

import * as PATHS from 'routes/PATHS';

export default [
  {
    to: PATHS.ADMIN_DASHBOARD,
    icon: RiDashboardLine,
    label: 'Dashboard',
    exact: true,
    notImplemented: true,
  },
  {
    to: PATHS.ADMIN_HIGHLIGHTS,
    icon: RiStarLine,
    label: 'Destaques',
    roles: ['root', 'editor'],
  },
  {
    icon: RiArticleLine,
    label: 'Conteúdo',
    dropdown: [
      {
        to: PATHS.ADMIN_NEWS,
        label: 'Notícias',
        secondaryLink: {
          to: PATHS.ADMIN_NEWS_CREATE,
          title: 'Cadastrar notícia',
        },
      },
      {
        to: PATHS.ADMIN_PERIODICALS,
        label: 'Colunas',
      },
      {
        to: PATHS.ADMIN_CATEGORIES,
        label: 'Categorias',
      },
    ],
  },
  {
    to: PATHS.ADMIN_EDITIONS,
    icon: RiBook2Line,
    label: 'Edições',
  },
  {
    icon: RiChatPollLine,
    label: 'Enquete',
    dropdown: [
      { to: PATHS.ADMIN_POLLS, label: 'Em progresso', exact: true },
      { to: PATHS.ADMIN_POLLS_HISTORY, label: 'Histórico' },
    ],
  },
];
