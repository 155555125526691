import { useMutation, useQuery } from 'react-query';

import api from 'services/api';
import { queryClient } from 'services/queryClient';

import { buildQueryString } from 'utils/buildQueryString';
import { formatPaginationMetadata } from 'utils/formatPaginationMetadata';

import {
  CreateData,
  UseCreateEditionProps,
  CreateEditionResponseData,
  GetEditionsResponseData,
  UseEditionsAdmProps,
  GetEditionsAdmQuery,
  PaginatedEditionsData,
  UseUpdateEditionFileProps,
  UpdateFileData,
  Edition,
  UseLastEditionProps,
  UpdateEditionFileResponseData,
} from './types';

const ADM_QUERY_KEY = 'editions_adm';
const LAST_QUERY_KEY = 'editions_last';

// *******************************************************
// ***            LIST ALL EDITIONS - ADMIN            ***
// *******************************************************
const getEditionsAdm = async (filters: GetEditionsAdmQuery) => {
  const uri = buildQueryString(filters, 'editions/adm');

  const { data } = await api.get<GetEditionsResponseData>(uri);
  return { ...data, ...formatPaginationMetadata(data) };
};

export function useEditionsAdm<T = PaginatedEditionsData>({
  query: queryParams,
  ...options
}: UseEditionsAdmProps<T> = {}) {
  const query = { page: 1, ...queryParams };

  return useQuery([ADM_QUERY_KEY, query], () => getEditionsAdm(query), {
    keepPreviousData: true,
    staleTime: 1000 * 60 * 30, // ? 30 minutes,
    ...options,
  });
}

// ******************************************************
// ***                GET LAST EDITION                ***
// ******************************************************
const getLastEdition = async () => {
  const { data } = await api.get<Edition>('editions/last');
  return data;
};

export function useLastEdition<T = Edition>(props?: UseLastEditionProps<T>) {
  return useQuery(LAST_QUERY_KEY, getLastEdition, {
    staleTime: 1000 * 60 * 30, // ? 30 minutes,
    ...props,
  });
}

// ********************************************************
// ***                      CREATE                      ***
// ********************************************************
const createEdition = async ({ file }: CreateData) => {
  const url = 'editions';
  const formData = new FormData();
  formData.append('file', file);

  const { data } = await api.post<CreateEditionResponseData>(url, formData);
  return data;
};

export function useCreateEdition({
  onSuccess,
  ...options
}: UseCreateEditionProps = {}) {
  return useMutation(createEdition, {
    onSuccess: (data, ...rest) => {
      queryClient.invalidateQueries(ADM_QUERY_KEY);
      onSuccess?.(data, ...rest);
    },
    ...options,
  });
}

// ********************************************************
// ***                      UPDATE                      ***
// ********************************************************
const updateEditionFile = async ({ id, file }: UpdateFileData) => {
  const uri = `editions/${id}/file`;
  const formData = new FormData();
  formData.append('file', file);

  const res = await api.patch<UpdateEditionFileResponseData>(uri, formData);
  return res.data;
};

export function useUpdateEditionFile({
  onSuccess,
  ...options
}: UseUpdateEditionFileProps = {}) {
  return useMutation(updateEditionFile, {
    onSuccess: (...params) => {
      queryClient.invalidateQueries({
        queryKey: ADM_QUERY_KEY,
        active: true,
      });
      onSuccess?.(...params);
    },
    ...options,
  });
}
