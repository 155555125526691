import { forwardRef, useCallback, useImperativeHandle } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { RiAddLine, RiSubtractLine } from 'react-icons/ri';
import { toast } from 'react-toastify';

import { Button, ContentModal, InputText, Text } from 'components';

import { useDisclosure } from 'hooks';
import { useUpdatePoll } from 'hooks/api';

import { updatePollValidator } from 'shared/validators/poll';

import { Form } from './styles';
import {
  UpdatePollModalRef,
  OpenFn,
  PollFormData,
  UpdatePollData,
} from './types';

//* COMPONENT
export type { UpdatePollModalRef };
export const UpdatePollModal = forwardRef<UpdatePollModalRef>((_, ref) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  // ------ Mutations setup ------
  const updatePoll = useUpdatePoll({
    onSuccess: () => {
      toast.success('Enquete atualizada com sucesso');
      onClose();
    },
  });

  // ------ Form configuration ------
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { isSubmitting, errors, dirtyFields },
  } = useForm<PollFormData>({
    mode: 'onBlur',
    resolver: updatePollValidator,
  });

  const { fields: updatedFields, remove: removeFromUpdated } = useFieldArray({
    control,
    name: 'alternatives.updated',
    keyName: 'key',
  });

  const {
    fields: addedFields,
    append: addField,
    remove: removeFromAdded,
  } = useFieldArray({
    control,
    name: 'alternatives.added',
    keyName: 'key',
  });

  const { append: addToDeleted } = useFieldArray({
    control,
    name: 'alternatives.deleted',
  });

  // ------ Component Ref configuration ------
  const open = useCallback<OpenFn>(
    props => {
      const { alternatives, ...rest } = props;
      reset({ ...rest, alternatives: { updated: alternatives } });
      onOpen();
    },
    [onOpen, reset]
  );
  useImperativeHandle(ref, () => ({ open }));

  // ------ Form submit action ------
  const onSubmit = handleSubmit(async ({ id, question, alternatives }) => {
    let hasUpdate = false;
    const data: UpdatePollData = { id, alternatives: {} };

    /**
     * Check if the question was modified or if the user added any new alternative
     */
    if (dirtyFields.question) {
      data.question = question;
      hasUpdate = true;
    }
    if (alternatives.added?.length) {
      data.alternatives.added = alternatives.added;
      hasUpdate = true;
    }

    /**
     * When deleting an alternative from the already registered ones we also modify
     * the updated alternatives array, which make we lost the ability to check the
     * dirty updated alternatives array correctly.
     *
     * So, when there's some alternative to delete, we also send all resting alternatives
     * to update in order to prevent losing any update
     */
    if (alternatives.deleted?.length) {
      data.alternatives.deleted = alternatives.deleted;
      hasUpdate = true;

      /**
       * In order to avoid sending an empty array of updated alternatives we check
       * if there's at least one alternative.
       *
       * We check this since we can get an empty array if the user deletes all
       * already registered alternatives
       */
      if (alternatives.updated.length) {
        data.alternatives.updated = alternatives.updated;
      }
    } else if (dirtyFields.alternatives?.updated) {
      /**
       * Since there is no deletes we can check if some alternative was updated.
       * If true, we send all alternatives to update.
       */
      data.alternatives.updated = alternatives.updated;
      hasUpdate = true;
    }

    if (hasUpdate) updatePoll.mutate(data);
    else onClose();
  });

  const totalOfAlternatives = updatedFields.length + addedFields.length;

  return (
    <ContentModal
      isOpen={isOpen}
      onRequestClose={onClose}
      showCancelButton
      variant="primary"
      size="medium"
      title="Atualizar enquete"
      disabledCloseButtons={isSubmitting}
      shouldCloseOnOverlayClick={!isSubmitting}
      actionButton={{
        text: 'Salvar',
        onClick: onSubmit,
        isLoading: isSubmitting,
      }}
    >
      <Form onSubmit={onSubmit}>
        <input type="hidden" {...register('id')} />

        <InputText
          label="Questão"
          error={errors.question?.message}
          {...register('question')}
        />

        <Text as="h2" variant="body-normal" color="label">
          Alternativas
        </Text>

        {/**
         * Alternatives already existent in the database
         */}
        {updatedFields.map((field, index) => (
          <div className="alternative" key={field.key}>
            <input
              type="hidden"
              defaultValue={field.id}
              {...register(`alternatives.updated.${index}.id`)}
            />

            <InputText
              containerClassName="alternative__input"
              removeErrorSlotMargin
              placeholder={`Alternativa ${index + 1}`}
              defaultValue={field.title}
              error={errors.alternatives?.updated?.[index]?.title?.message}
              {...register(`alternatives.updated.${index}.title`)}
            />

            {totalOfAlternatives > 2 && (
              <Button
                color="danger"
                icon={RiSubtractLine}
                onClick={() => {
                  removeFromUpdated(index);
                  addToDeleted({ id: field.id });
                }}
              />
            )}
          </div>
        ))}

        {/**
         * New alternatives to add
         */}
        {addedFields.map((field, index) => (
          <div className="alternative" key={field.key}>
            <InputText
              containerClassName="alternative__input"
              removeErrorSlotMargin
              placeholder={`Alternativa ${index + 1}`}
              defaultValue={field.title}
              error={errors.alternatives?.added?.[index]?.title?.message}
              {...register(`alternatives.added.${index}.title`)}
            />
            {totalOfAlternatives > 2 && (
              <Button
                color="danger"
                icon={RiSubtractLine}
                onClick={() => removeFromAdded(index)}
              />
            )}
          </div>
        ))}

        <div className="secondary-actions">
          <Button
            className="secondary-actions__add-alternative"
            size="small"
            icon={RiAddLine}
            onClick={() => addField({ title: '' }, { shouldFocus: false })}
            color="secondary"
          >
            Adicionar
          </Button>
        </div>
      </Form>
    </ContentModal>
  );
});
